import { persistReducer } from "redux-persist";
import storageSession from "redux-persist/lib/storage/session";
import { createReducer, makeActionCreator } from "util/redux";
import globalTypes from "reducers/types/global";

export const INITIAL_STATE = {
    isFetching: false,
    isShowBalancePurchaseModal: false,
    isShowPlans: false,
    availablePlans: null,
    productId: "",
};

export const types = {
    OPEN_MODAL_REQUEST: "BALANCE_PURCHASE/OPEN_MODAL_REQUEST",
    CLOSE_MODAL_REQUEST: "BALANCE_PURCHASE/CLOSE_MODAL_REQUEST",
    GET_AVAILABLE_PLANS_REQUEST: "BALANCE_PURCHASE/GET_AVAILABLE_PLANS_REQUEST",
    GET_AVAILABLE_PLANS_FAILURE: "BALANCE_PURCHASE/GET_AVAILABLE_PLANS_FAILURE",
    GET_AVAILABLE_PLANS_SUCCESS: "BALANCE_PURCHASE/GET_AVAILABLE_PLANS_SUCCESS",
    SET_SELECTED_PLAN: "BALANCE_PURCHASE/SET_SELECTED_PLAN",
};

const reducer = createReducer(INITIAL_STATE, {
    [globalTypes.CLEAN_UP]: () => ({
        ...INITIAL_STATE,
    }),
    [types.OPEN_MODAL_REQUEST]: (state, action) => ({
        ...state,
        isShowBalancePurchaseModal: true,
        productId: action.productId,
    }),
    [types.CLOSE_MODAL_REQUEST]: () => ({
        ...INITIAL_STATE,
        isShowBalancePurchaseModal: false,
    }),
    [types.GET_AVAILABLE_PLANS_REQUEST]: (state) => ({
        ...state,
        isFetching: true,
    }),
    [types.GET_AVAILABLE_PLANS_FAILURE]: (state) => ({
        ...state,
        isFetching: false,
        availablePlans: null,
    }),
    [types.GET_AVAILABLE_PLANS_SUCCESS]: (state, action) => ({
        ...state,
        isFetching: false,
        availablePlans: action.planList,
    }),
    [types.SET_SELECTED_PLAN]: (state, action) => ({
        ...state,
        selectedPlan: action.selectedPlan,
    }),
});

export default persistReducer(
    {
        key: "balancePurchase",
        storage: storageSession,
        blacklist: ["isFetching", "isShowBalancePurchaseModal"],
    },
    reducer,
);

export const actions = {
    openBalancePurchaseModalRequest: makeActionCreator(types.OPEN_MODAL_REQUEST, "productId"),
    closeBalancePurchaseModalRequest: makeActionCreator(types.CLOSE_MODAL_REQUEST),
    getAvailablePlansRequest: makeActionCreator(types.GET_AVAILABLE_PLANS_REQUEST, "productId", "amount", "formikBag"),
    getAvailablePlansFailure: makeActionCreator(types.GET_AVAILABLE_PLANS_FAILURE),
    getAvailablePlansSuccess: makeActionCreator(types.GET_AVAILABLE_PLANS_SUCCESS, "planList"),
    setSelectedPlan: makeActionCreator(types.SET_SELECTED_PLAN, "selectedPlan"),
};

export const selectors = {
    isFetching: ({ balancePurchase }) => balancePurchase.isFetching,
    isShowBalancePurchaseModal: ({ balancePurchase }) => balancePurchase.isShowBalancePurchaseModal,
    getAvailablePlanList: ({ balancePurchase }) => balancePurchase.availablePlans,
    getProductId: ({ balancePurchase }) => balancePurchase.productId,
    getSelectedPlan: ({ balancePurchase }) => balancePurchase.selectedPlan,
};
