import classNames from "classnames";
import moment from "moment";
import SelectSimpleField from "pages/forms/customForms/_customFields/SelectSimpleField";
import TextSimpleField from "pages/forms/customForms/_customFields/TextSimpleField";
import * as FormFieldsComponents from "pages/forms/_components/_fields/Index";
import RadioButtonGroup from "pages/forms/_components/_fields/_commons/RadioButtonGroup";
import {
    COUNTRY_BENEFICIARY_MX,
    FOREIGN_TRANSFER,
} from "pages/frequentDestination/validation/BeneficiaryFormValidation";
import { connect } from "react-redux";
import Accordion from "pages/_components/Accordion";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import DatePicker from "pages/_components/fields/datepicker";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import Tooltip from "pages/_components/Tooltip";
import { arrayOf, bool, func, shape, string } from "prop-types";
import { selectors as sessionSelectors } from "reducers/session";
import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/lib/Col";
import * as i18n from "util/i18n";
import * as configUtils from "util/config";
import SwitfBankSelector from "../../foreignTransfer/SwitfBankSelector";
import { ID_FORM as ID_FORM_FOREIGN } from "../../TransferForeignForm";
import "./BeneficiaryField.scss";
import { ACCOUNT_NUMBER, BENEFICIARY_NAME_DATE, BENEFICIARY_THREE_NAME, IBAN_NUMBER } from "./Constants";

const BeneficiaryForeign = ({
    isDesktop,
    field,
    form,
    fieldHasError,
    renderError,
    productTypeList,
    countryList,
    relationshipTypeList,
    codeBankList,
    mode,
    genericProps,
    handleChangeText,
    handleChangeCheckBox,
    handleChangeSelector,
    handleChangeRadioButton,
    handleChangeDate,
    onSearchBank,
    showHeaderComponent,
    showSaveBeneficiary,
    fromNewBeneficiary,
    directoryStyle,
    onClickSelectBeneficiary,
    loadBeneficiary,
    clearBeneficiary,
    beneficiaryManagePermission,
    handleChangeSwitch,
    value,
    clearCountrySelected,
}) => {
    /**
     * State
     */
    const [beneficiaryNameType, setBeneficiaryNameType] = useState(BENEFICIARY_THREE_NAME);
    const [startDate, setStartDate] = useState(moment());
    const [showInte, setShowInte] = useState(false);

    /**
     * Data
     */
    const beneficiaryNameRadioButtonsOptions = [
        {
            id: BENEFICIARY_THREE_NAME,
            label: i18n.get(
                `forms.${ID_FORM_FOREIGN}.beneficiaryOption.option.${BENEFICIARY_THREE_NAME.toLowerCase()}`,
            ),
        },
        {
            id: BENEFICIARY_NAME_DATE,
            label: i18n.get(`forms.${ID_FORM_FOREIGN}.beneficiaryOption.option.${BENEFICIARY_NAME_DATE.toLowerCase()}`),
        },
    ];

    const acountNumberOrIbanOptions = [
        {
            id: ACCOUNT_NUMBER,
            label: i18n.get(`forms.${ID_FORM_FOREIGN}.accountType.option.${ACCOUNT_NUMBER.toLowerCase()}`),
        },
        {
            id: IBAN_NUMBER,
            label: i18n.get(`forms.${ID_FORM_FOREIGN}.accountType.option.${IBAN_NUMBER.toLowerCase()}`),
        },
    ];

    useEffect(() => {
        if (mode === "edit") {
            setShowInte(field?.value?.addIntermediaryBank);
            setBeneficiaryNameType(field?.value?.beneficiaryOption || BENEFICIARY_THREE_NAME);
        }
        setStartDate(field?.value?.birthDateFrom ? moment(field?.value?.birthDateFrom, "YYYY-MM-DD") : moment());
    }, [form.values]);

    const renderSwiftSelector = (showHeader) => (
        <>
            <SwitfBankSelector
                form={form}
                idForm={ID_FORM_FOREIGN}
                field={field}
                value={value}
                mode={mode}
                genericProps={genericProps}
                handleChangeSwitch={handleChangeSwitch}
                directoryStyle={directoryStyle}
                clearCountrySelected={clearCountrySelected}
                {...(showHeader && { titleHeader: `forms.${ID_FORM_FOREIGN}.beneficiaryBank.title` })}
            />
            {(showInte || field?.value?.addIntermediaryBank || value?.addIntermediaryBank) && (
                <SwitfBankSelector
                    idForm={ID_FORM_FOREIGN}
                    form={form}
                    field={field}
                    value={value}
                    idComponent="intermediary"
                    mode={mode}
                    genericProps={genericProps}
                    handleChangeSwitch={handleChangeSwitch}
                    directoryStyle={directoryStyle}
                    clearCountrySelected={clearCountrySelected}
                    {...(showHeader && {
                        titleHeader: `forms.${ID_FORM_FOREIGN}.beneficiaryBankIntermediary.title`,
                    })}
                />
            )}
        </>
    );

    const renderLabelName = () => {
        const renderDate =
            (field?.value?.beneficiaryOption && field?.value?.beneficiaryOption === BENEFICIARY_NAME_DATE) ||
            (value?.beneficiaryOption && value?.beneficiaryOption === BENEFICIARY_NAME_DATE)
                ? startDate.format("DD/MM/YYYY") || ""
                : "";
        return `${field?.value?.name || value?.name || ""} ${renderDate}`;
    };

    const renderBeneficiaryDetailView = () => {
        const productTypeId = field?.value?.productType?.id || value?.productType.id || "";
        const filteredProductType = productTypeList.find((item) => item.id === productTypeId);
        const productTypeLabel = filteredProductType ? filteredProductType.label : "";

        return (
            <Box display="flex" fullWidth column {...(!isDesktop && { className: "mb-5" })}>
                <FormFieldsComponents.ReadTextCustom
                    {...genericProps}
                    value={i18n.get("forms.transfers.foreign.formName.pill")}
                    label="beneficiary.view.beneficiaryType.label"
                    {...(directoryStyle && isDesktop && { mode: "preview" })}
                />
                <FormFieldsComponents.ReadTextCustom
                    {...genericProps}
                    value={field?.value?.productType?.label || productTypeLabel}
                    label="forms.transfers.foreign.productType.label_preview"
                    {...(directoryStyle && isDesktop && { mode: "preview" })}
                />
                <FormFieldsComponents.ReadTextCustom
                    {...genericProps}
                    value={field?.value?.relationshipType?.label || value?.relationshipType.label || ""}
                    label="forms.transfers.foreign.beneficiaryRelationshipType.label_preview"
                    {...(directoryStyle && isDesktop && { mode: "preview" })}
                />
                <FormFieldsComponents.ReadTextCustom
                    {...genericProps}
                    value={renderLabelName()}
                    label="forms.transfers.foreign.beneficiaryName.label_preview"
                    {...(directoryStyle && isDesktop && { mode: "preview" })}
                />
                <FormFieldsComponents.ReadTextCustom
                    {...genericProps}
                    value={field?.value?.account || value?.account || ""}
                    label="forms.transfers.foreign.beneficiaryAccount.label_preview"
                    {...(directoryStyle && isDesktop && { mode: "preview" })}
                />
                <FormFieldsComponents.ReadTextCustom
                    {...genericProps}
                    value={field?.value?.serviceDescription || value?.serviceDescription || ""}
                    label="forms.transfers.foreign.beneficiaryServiceDescription.label_preview"
                    {...(directoryStyle && isDesktop && { mode: "preview" })}
                />
                <FormFieldsComponents.ReadTextCustom
                    {...genericProps}
                    value={field?.value?.address || value?.address || ""}
                    label="forms.transfers.foreign.beneficiaryAddress.label_preview"
                    {...(directoryStyle && isDesktop && { mode: "preview" })}
                />
                <FormFieldsComponents.ReadTextCustom
                    {...genericProps}
                    value={field?.value?.city || value?.city || ""}
                    label="forms.transfers.foreign.beneficiaryCity.label_preview"
                    {...(directoryStyle && isDesktop && { mode: "preview" })}
                />
                <FormFieldsComponents.ReadTextCustom
                    {...genericProps}
                    value={field?.value?.country?.label || value?.country?.label || ""}
                    label="forms.transfers.foreign.beneficiaryCountry.label_preview"
                    {...(directoryStyle && isDesktop && { mode: "preview" })}
                />
            </Box>
        );
    };

    const renderPreviewMode = () => (
        <>
            <Box className="ticket-info-wrapper">
                {isDesktop ? (
                    <Accordion grayBox>
                        <Accordion.Item
                            title={
                                directoryStyle
                                    ? `forms.${ID_FORM_FOREIGN}.createBeneficiary.title`
                                    : `forms.${ID_FORM_FOREIGN}.beneficiary.title`
                            }>
                            {renderBeneficiaryDetailView()}
                        </Accordion.Item>
                    </Accordion>
                ) : (
                    <>
                        {!directoryStyle && (
                            <Text
                                component="h4"
                                color="primary"
                                size="5"
                                bold
                                labelKey={`forms.${ID_FORM_FOREIGN}.beneficiary.title`}
                                className="mt-0 mb-4"
                            />
                        )}
                        {renderBeneficiaryDetailView()}
                    </>
                )}
            </Box>
            {renderSwiftSelector(true)}
        </>
    );

    const renderViewMode = () => (
        <>
            <Box className="ticket-info-wrapper">
                {renderBeneficiaryDetailView()}
                {renderSwiftSelector()}
            </Box>
        </>
    );

    const getMaxLengthAccount = () => {
        let accountMaxLength = configUtils.getInteger("forms.transfers.foreign.field.account.length.generic.max") || 34;

        if (form?.values?.beneficiary?.country?.id === COUNTRY_BENEFICIARY_MX) {
            accountMaxLength = configUtils.getInteger("forms.transfers.foreign.field.account.length.mx.max") || 18;
        }
        return accountMaxLength;
    };

    const isDestinataryPerson = field?.value?.productType?.id === "01";

    const renderEditMode = () => {
        const content = () => (
            <>
                {showHeaderComponent && (
                    <Box display="flex" alignX="between" alignY="center" fullWidth className="mb-0 mb-md-5">
                        <Box display="flex" alignY="baseline">
                            <Text
                                size="4"
                                color="primary"
                                bold
                                className="mr-2"
                                labelKey="forms.transfers.foreign.beneficiary.title"
                            />
                            {isDesktop && (
                                <>
                                    <Text
                                        color="primary"
                                        labelKey={`forms.${ID_FORM_FOREIGN}.beneficiaryInformation`}
                                    />
                                </>
                            )}
                        </Box>
                        {beneficiaryManagePermission && (
                            <Button
                                label="frequentDestination.searchFrequentDestination"
                                onClick={onClickSelectBeneficiary}
                                image="images/icons/directory.svg"
                                bsStyle="link"
                            />
                        )}
                    </Box>
                )}
                {(isDesktop || fromNewBeneficiary) && (
                    <Row>
                        {/* PRODUCT TYPE */}
                        <Col xs={12} md={3} className="mt-auto">
                            <SelectSimpleField
                                form={form}
                                field={field}
                                labelKey={`forms.${ID_FORM_FOREIGN}.productType.label`}
                                name="productType"
                                placeholder={i18n.get("forms.placeholder.select")}
                                valueKey="id"
                                value={field?.value?.productType?.id}
                                onChange={handleChangeSelector}
                                options={productTypeList}
                                disabled={loadBeneficiary}
                            />
                        </Col>
                        {/* PRODUCT TYPE */}

                        {/* RELATIONSHIP TYPE */}
                        <Col xs={12} md={3} className="mt-auto">
                            <SelectSimpleField
                                form={form}
                                field={field}
                                labelKey={`forms.${ID_FORM_FOREIGN}.beneficiaryRelationshipType.label`}
                                tooltipText={`forms.${ID_FORM_FOREIGN}.beneficiaryRelationshipType.tooltip`}
                                tooltipPosition={isDesktop ? "bottom-centered" : "bottom-left"}
                                name="relationshipType"
                                placeholder={i18n.get("forms.placeholder.select")}
                                valueKey="id"
                                value={field?.value?.relationshipType?.id || ""}
                                onChange={handleChangeSelector}
                                disabled={loadBeneficiary}
                                options={relationshipTypeList}
                            />
                        </Col>
                        {/* RELATIONSHIP TYPE */}
                        <Col xs={12} md={6}>
                            {field?.value?.productType?.id && (
                                <>
                                    <Box>
                                        <Box
                                            display="flex"
                                            alignY="center"
                                            fullWidth
                                            className={classNames({
                                                "mb-4": isDestinataryPerson,
                                                "data-label-special-mb-2": !isDestinataryPerson,
                                            })}>
                                            <Text
                                                color="text-boton"
                                                labelKey={
                                                    isDestinataryPerson
                                                        ? "forms.beneficiary.name.label"
                                                        : "forms.beneficiary.name.company.label"
                                                }
                                            />
                                            <Tooltip
                                                text={
                                                    isDestinataryPerson
                                                        ? `forms.${ID_FORM_FOREIGN}.beneficiaryOption.tooltip`
                                                        : `forms.${ID_FORM_FOREIGN}.beneficiaryOption.company.tooltip`
                                                }
                                            />
                                        </Box>
                                        {isDestinataryPerson && (
                                            <RadioButtonGroup
                                                className="d-flex data-label-special-mb"
                                                inLineControl={false}
                                                name="beneficiaryOption"
                                                selectorId="beneficiaryOption"
                                                onChange={(e) => {
                                                    handleChangeRadioButton(e, "beneficiaryOption");
                                                    setBeneficiaryNameType(e);
                                                }}
                                                options={beneficiaryNameRadioButtonsOptions}
                                                value={field?.value?.beneficiaryOption || BENEFICIARY_THREE_NAME}
                                                radioButtonGroupFlex
                                                radioLabelClassName="color-text-boton"
                                                disabled={loadBeneficiary}
                                            />
                                        )}
                                    </Box>
                                    <Row>
                                        {beneficiaryNameType === BENEFICIARY_THREE_NAME || !isDestinataryPerson ? (
                                            <Col xs={12} md={12}>
                                                {/* NAME */}

                                                <TextSimpleField
                                                    form={form}
                                                    field={field}
                                                    name="name"
                                                    placeholder=""
                                                    maxLength={
                                                        configUtils.getInteger(
                                                            "forms.transfers.foreign.field.beneficiaryThreeName.length.max",
                                                        ) || 35
                                                    }
                                                    value={field?.value?.name || ""}
                                                    onChange={handleChangeText}
                                                    pattern="^[a-zA-Z0-9 ]*$"
                                                    disabled={loadBeneficiary}
                                                />
                                                {/* NAME */}
                                            </Col>
                                        ) : (
                                            <>
                                                <Col xs={12} md={6} className="mt-auto">
                                                    <TextSimpleField
                                                        form={form}
                                                        field={field}
                                                        name="name"
                                                        placeholder=""
                                                        maxLength={
                                                            configUtils.getInteger(
                                                                "forms.transfers.foreign.field.beneficiaryNameDate.length.max",
                                                            ) || 24
                                                        }
                                                        value={field?.value?.name || ""}
                                                        onChange={handleChangeText}
                                                        pattern="^[a-zA-Z0-9 ]*$"
                                                        disabled={loadBeneficiary}
                                                    />
                                                    {/* NAME */}
                                                </Col>
                                                <Col xs={12} md={6} className="mt-auto">
                                                    {/* BIRTHDAY */}
                                                    <Box
                                                        className={classNames("form-group form-group--datepicker", {
                                                            "has-error": fieldHasError("dateType"),
                                                        })}>
                                                        <Box className="input-group">
                                                            <Box className="form-control">
                                                                <DatePicker
                                                                    className="form-control"
                                                                    name="birthDateFrom"
                                                                    selected={startDate}
                                                                    onChange={(date) => {
                                                                        setStartDate(date);
                                                                        handleChangeDate(date, "birthDateFrom");
                                                                    }}
                                                                    maxDate={moment(new Date())}
                                                                    disabled={loadBeneficiary}
                                                                />
                                                            </Box>
                                                        </Box>
                                                        {renderError("dateType")}
                                                    </Box>
                                                    {/* BIRTHDAY */}
                                                </Col>
                                            </>
                                        )}
                                    </Row>
                                </>
                            )}
                        </Col>
                        <Col xs={12} md={6}>
                            <Row>
                                <Col xs={12} md={6}>
                                    {/* CITY */}

                                    <TextSimpleField
                                        form={form}
                                        field={field}
                                        labelKey={`forms.${ID_FORM_FOREIGN}.beneficiaryCity.label`}
                                        name="city"
                                        placeholder=""
                                        value={field?.value?.city || ""}
                                        onChange={handleChangeText}
                                        pattern="^[a-zA-Z ]*$"
                                        disabled={loadBeneficiary}
                                        maxLength={
                                            configUtils.getInteger("forms.transfers.foreign.field.city.length.max") ||
                                            32
                                        }
                                    />
                                    {/* CITY */}
                                </Col>
                                <Col xs={12} md={6}>
                                    {/* COUNTRY */}
                                    <SelectSimpleField
                                        form={form}
                                        field={field}
                                        labelKey={`forms.${ID_FORM_FOREIGN}.beneficiaryCountry.label`}
                                        tooltipText={`forms.${ID_FORM_FOREIGN}.beneficiaryCountry.tooltip`}
                                        tooltipPosition={isDesktop ? "bottom-centered" : "bottom-left"}
                                        name="country"
                                        placeholder={i18n.get("forms.placeholder.select")}
                                        valueKey="id"
                                        value={field?.value?.country?.id || ""}
                                        onChange={(target, name) => {
                                            if (handleChangeSelector) {
                                                handleChangeSelector(target, name);
                                            }
                                        }}
                                        options={countryList}
                                        menuOuter100
                                        disabled={loadBeneficiary}
                                        searchable
                                    />
                                    {/* COUNTRY */}
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={12} md={6}>
                            {/* ACCOUNT NUMBER OR IBAN */}
                            <Box>
                                <RadioButtonGroup
                                    className="data-label-special-mb-2"
                                    radioLabelClassName="color-text-boton"
                                    inLineControl={false}
                                    name="accountType"
                                    onChange={(e) => {
                                        handleChangeRadioButton(e, "accountType");
                                    }}
                                    selectorId="accountType"
                                    options={acountNumberOrIbanOptions}
                                    value={field?.value?.accountType || IBAN_NUMBER}
                                    radioButtonGroupFlex
                                    disabled={loadBeneficiary}
                                    tooltipText={`forms.${ID_FORM_FOREIGN}.accountType.tooltip`}
                                />

                                <TextSimpleField
                                    className="mt-1"
                                    form={form}
                                    field={field}
                                    name="account"
                                    placeholder=""
                                    value={field?.value?.account || ""}
                                    onChange={handleChangeText}
                                    pattern="^[a-zA-Z0-9]*$"
                                    disabled={loadBeneficiary}
                                    maxLength={getMaxLengthAccount()}
                                />
                            </Box>
                            {/* ACCOUNT NUMBER OR IBAN */}
                        </Col>
                        <Col xs={12} md={6}>
                            {/* ADDRESS */}

                            <TextSimpleField
                                form={form}
                                field={field}
                                labelKey={`forms.${ID_FORM_FOREIGN}.beneficiaryAddress.label`}
                                name="address"
                                placeholder=""
                                value={field?.value?.address || ""}
                                onChange={handleChangeText}
                                pattern="^[a-zA-Z0-9 ]*$"
                                disabled={loadBeneficiary}
                                maxLength={
                                    configUtils.getInteger("forms.transfers.foreign.field.address.length.max") || 35
                                }
                            />
                            {/* ADDRESS */}
                        </Col>
                        <Col xs={12} md={6} className="mt-auto">
                            {/* SERVICE DESCRIPTION */}

                            <TextSimpleField
                                form={form}
                                field={field}
                                labelKey={`forms.${ID_FORM_FOREIGN}.beneficiaryServiceDescription.label`}
                                tooltipText={`forms.${ID_FORM_FOREIGN}.beneficiaryServiceDescription.tooltip`}
                                name="serviceDescription"
                                placeholder=""
                                value={field?.value?.serviceDescription || ""}
                                onChange={handleChangeText}
                                pattern="^[a-zA-Z0-9 ]*$"
                                disabled={loadBeneficiary}
                            />
                            {/* SERVICE DESCRIPTION */}
                        </Col>
                    </Row>
                )}
                {!isDesktop && !fromNewBeneficiary && loadBeneficiary && (
                    <Box
                        background="heading-color"
                        borderRadius="default"
                        display="flex"
                        gap="3"
                        fullWidth
                        alignY="center"
                        className="mt-4 p-4 beneficiary-info-box">
                        <Box display="flex" column flex1 className="beneficiary-info-box-left">
                            <Text color="inverse" size="5" bold ellipsis>
                                {field?.value?.name || ""}
                            </Text>
                            <Text color="inverse" size="5" ellipsis>
                                {i18n.get(`beneficiary.type.${FOREIGN_TRANSFER.toLowerCase()}.label`)}
                            </Text>
                        </Box>
                        <Box
                            display="flex"
                            column
                            alignY="flex-end"
                            flex1
                            fullWidth
                            className="beneficiary-info-box-right">
                            <Text color="inverse" size="5" align="right" ellipsis>
                                Persona
                            </Text>
                            <Text color="inverse" size="5" align="right" ellipsis>
                                {field?.value?.account || ""}
                            </Text>
                            <Text color="inverse" size="5" align="right" ellipsis>
                                {field?.value?.bankName || ""}
                            </Text>
                        </Box>
                    </Box>
                )}
            </>
        );
        return (
            <>
                <Box
                    background="white"
                    className={classNames("mx-n-5 mb-3 mb-md-7", {
                        "pt-7 pb-7 pl-5 pl-lg-10 pr-5 pr-lg-10": !directoryStyle,
                        "pt-9 pb-9 px-5 px-md-0": directoryStyle,
                    })}
                    borderRadius="default">
                    {directoryStyle ? (
                        <Row>
                            <Col md={10} mdOffset={1}>
                                {content()}
                            </Col>
                        </Row>
                    ) : (
                        content()
                    )}
                </Box>

                {(directoryStyle || isDesktop) && (
                    <>
                        <SwitfBankSelector
                            form={form}
                            field={field}
                            handleChangeSelector={handleChangeSelector}
                            recipientAccountCodeMapOptions={codeBankList}
                            handleChangeText={handleChangeText}
                            showIntermediaryBank
                            showSave={showSaveBeneficiary && !showInte}
                            onSearchBank={onSearchBank}
                            idComponent=""
                            mode={mode}
                            genericProps={genericProps}
                            handleChangeCheckBox={handleChangeCheckBox}
                            idForm={ID_FORM_FOREIGN}
                            countryList={countryList}
                            directoryStyle={directoryStyle}
                            loadBeneficiary={loadBeneficiary}
                            handleChangeSwitch={handleChangeSwitch}
                            clearBeneficiary={clearBeneficiary}
                            clearCountrySelected={clearCountrySelected}
                        />
                        {showInte && (
                            <SwitfBankSelector
                                form={form}
                                field={field}
                                handleChangeSelector={handleChangeSelector}
                                recipientAccountCodeMapOptions={codeBankList}
                                handleChangeText={handleChangeText}
                                showIntermediaryBank={false}
                                showSave={showSaveBeneficiary}
                                idComponent="intermediary"
                                onSearchBank={onSearchBank}
                                mode={mode}
                                genericProps={genericProps}
                                handleChangeCheckBox={handleChangeCheckBox}
                                idForm={ID_FORM_FOREIGN}
                                countryList={countryList}
                                directoryStyle={directoryStyle}
                                loadBeneficiary={loadBeneficiary}
                                clearBeneficiary={clearBeneficiary}
                                handleChangeSwitch={handleChangeSwitch}
                                clearCountrySelected={clearCountrySelected}
                            />
                        )}
                    </>
                )}
            </>
        );
    };

    return (
        <>
            {mode === "edit" && renderEditMode()}
            {mode === "preview" && renderPreviewMode()}
            {mode === "view" && renderViewMode()}
        </>
    );
};

BeneficiaryForeign.propTypes = {
    isDesktop: bool.isRequired,
    productTypeList: arrayOf(shape({})).isRequired,
    form: shape({}).isRequired,
    field: shape({}).isRequired,
    genericProps: shape({}).isRequired,
    fieldHasError: func.isRequired,
    renderError: func.isRequired,
    countryList: arrayOf(shape({})).isRequired,
    relationshipTypeList: arrayOf(shape({})).isRequired,
    codeBankList: arrayOf(shape({})).isRequired,
    handleChangeText: func.isRequired,
    handleChangeCheckBox: func.isRequired,
    handleChangeSelector: func.isRequired,
    handleChangeRadioButton: func.isRequired,
    handleChangeDate: func.isRequired,
    beneficiaryManagePermission: bool,
    onSearchBank: func.isRequired,
    mode: string.isRequired,
    showHeaderComponent: bool,
    showSaveBeneficiary: bool,
    fromNewBeneficiary: bool,
    directoryStyle: bool,
    onClickSelectBeneficiary: func.isRequired,
    loadBeneficiary: bool,
    clearBeneficiary: func,
    handleChangeSwitch: func.isRequired,
    value: shape({}),
    clearCountrySelected: func,
};
BeneficiaryForeign.defaultProps = {
    showHeaderComponent: true,
    showSaveBeneficiary: true,
    fromNewBeneficiary: false,
    directoryStyle: false,
    loadBeneficiary: false,
    beneficiaryManagePermission: false,
    clearBeneficiary: () => {},
    value: {},
    clearCountrySelected: () => {},
};

const mapStateToProps = (state) => ({
    beneficiaryManagePermission: sessionSelectors.hasPermissions(state, ["beneficiaryManage"]),
});

export default connect(mapStateToProps)(BeneficiaryForeign);
