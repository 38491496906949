import * as I18n from "util/i18n";

import { push, replace, routerActions } from "react-router-redux";
import { selectors as configSelectors, types as configTypes } from "reducers/config";
import { selectors as i18nSelectors, types as i18nTypes } from "reducers/i18n";
import { all, call, fork, put, select, spawn } from "redux-saga/effects";

import { MAX_FAILED_TIMES } from "constants.js";
import { actions as notificationActions } from "reducers/notification";
import globalTypes from "reducers/types/global";
import { delay } from "redux-saga";
import accounts from "sagas/accounts";
import administrationAdvanced from "sagas/administration/advanced";
import administrationTicket from "sagas/administration/common/administrationTicket";
import administrationGroups from "sagas/administration/groups";
import administrationMedium from "sagas/administration/medium";
import restrictions from "sagas/administration/restrictions";
import administrationSimple from "sagas/administration/simple";
import administrationUsers from "sagas/administration/users";
import administrationInvitations from "sagas/administration/invitations";
import administrationUsersInvite from "sagas/administration/usersInvite";
import authenticateHandler from "sagas/authenticateHandler";
import bankSelector from "sagas/bankSelector";
import campaigns from "sagas/campaigns";
import changeStatusProductSagas from "sagas/changeStatusProductSagas";
import chatbot from "sagas/chatbot";
import checks from "sagas/checks";
import communication from "sagas/communication";
import communicationTrays from "sagas/communicationTrays";
import communications from "sagas/communications";
import config from "sagas/config";
import creditCard from "sagas/creditCard";
import creditCardMovementDetails from "sagas/creditCardMovementDetails";
import creditCardRequest from "sagas/creditCardRequest";
import creditCards from "sagas/creditCards";
import creditLines from "sagas/creditLines";
import debitCards from "sagas/debitCards";
import deposits from "sagas/deposits";
import desktop from "sagas/desktop";
import enrollment from "sagas/enrollment";
import files from "sagas/files";
import finances from "sagas/finances";
import fingerprint from "sagas/fingerprint";
import fixedTermDeposit from "sagas/fixedTermDeposit/fixedTermDeposit.saga";
import form from "sagas/form";
import formFields from "sagas/formFields";
import frequentDestination from "sagas/frequentDestination";
import environment from "sagas/generalConditions";
import i18n from "sagas/i18n";
import loans from "sagas/loans";
import loansPayment from "sagas/loansPayment";
import login from "sagas/login";
import massPayments from "sagas/massPayments";
import onboarding from "sagas/onboarding";
import payService from "sagas/payService";
import pointsOfInterest from "sagas/pointsOfInterest";
import products from "sagas/products";
import pushNotifications from "sagas/pushNotifications";
import recoveryPassword from "sagas/recoveryPassword";
import recoveryUserSagas from "sagas/recoveryUserSagas";
import servicePayments from "sagas/servicePayments";
import session from "sagas/session";
import settings from "sagas/settings";
import softToken from "sagas/softToken/softToken";
import softTokenDisclaimer from "sagas/softTokenDisclaimer/softTokenDisclaimer.saga";
import status from "sagas/status";
import template from "sagas/template";
import requestAndComplaints from "sagas/requestAndComplaints";
import tour from "sagas/tour";
// import transactionLines from "sagas/transactionLines";
import transactions from "sagas/transactions";
import wally from "sagas/wally";
import updateUserData from "sagas/updateUserData/updateUserData.saga";
import updateCompanyData from "sagas/updateCompanyData/updateCompanyData.saga";
import widgets from "sagas/widgets";
import { isMobileNativeFunc } from "util/device";
import { actions as changeStatusProductActions } from "reducers/changeStatusProduct";
import { actions as updateCompanyDataActions } from "reducers/updateCompanyData/updateCompanyData.reducer";
import { actions as updateUserDataActions } from "reducers/updateUserData/updateUserData.reducer";
import { actions as desktopAction } from "reducers/desktop";
import { actions as balancePurchaseActions } from "reducers/balancePurchase";
import { crashLogData } from "util/crashReport/crashReport.util";
import wallet from "sagas/wallet/wallet.saga";
import { applePayRemovePassButton } from "util/wallet/wallet.util";
import productRequest from "sagas/productRequest";
import preApprovedProduct from "sagas/preApprovedProduct";
import transferSupport from "sagas/transferSupport/transferSupport.saga";
import kuara from "sagas/kuara";
import transactionalProfile from "sagas/transactionalProfile";
import axios from "axios";
import { actions as transactionalProfileActions } from "reducers/transactionalProfile";
import balancePurchase from "sagas/balancePurchase";
import preApprovedCreditPos from "sagas/preApprovedCreditPos";
import supportTransferReceived from "sagas/supportTransferReceived";

const SCOPE_USER_BLOCKED = "desktop";
const REDIRECT_TOKEN_LOCKED_ENTRUST = "/settings/authenticatorHandler";
const SCOPE_TOKEN_BLOCKED = "settings/authenticatorHandler";

const MODAL_TEXT_MIGRATE = "migrate";
const MODAL_TEXT_ACTIVATE = "activate";
const MODAL_TEXT_UNLOCK = "unlock";

const sagas = [
    ...accounts,
    ...deposits,
    ...campaigns,
    ...chatbot,
    ...checks,
    ...creditCards,
    ...debitCards,
    ...creditCard,
    ...creditCardMovementDetails,
    ...communications,
    ...communication,
    ...communicationTrays,
    ...config,
    ...desktop,
    ...enrollment,
    ...fingerprint,
    ...form,
    ...i18n,
    ...loans,
    ...loansPayment,
    ...onboarding,
    ...products,
    ...pushNotifications,
    ...recoveryPassword,
    ...recoveryUserSagas,
    ...changeStatusProductSagas,
    ...status,
    ...session,
    ...settings,
    ...template,
    ...widgets,
    ...transactions,
    ...bankSelector,
    ...login,
    ...administrationAdvanced,
    ...administrationGroups,
    ...administrationMedium,
    ...administrationSimple,
    ...administrationTicket,
    ...administrationUsers,
    ...administrationUsersInvite,
    ...administrationInvitations,
    ...files,
    ...formFields,
    ...pointsOfInterest,
    ...creditCardRequest,
    ...massPayments,
    // ...transactionLines,
    ...restrictions,
    ...requestAndComplaints,
    ...frequentDestination,
    ...servicePayments,
    ...environment,
    ...wally,
    ...softToken,
    ...authenticateHandler,
    ...payService,
    ...softTokenDisclaimer,
    ...tour,
    ...fixedTermDeposit,
    ...finances,
    ...wallet,
    ...updateUserData,
    ...productRequest,
    ...creditLines,
    ...preApprovedProduct,
    ...transferSupport,
    ...kuara,
    ...transactionalProfile,
    ...updateCompanyData,
    ...balancePurchase,
    ...preApprovedCreditPos,
    ...supportTransferReceived,
];

export default function* rootSaga() {
    yield all(
        sagas.map((saga) =>
            spawn(function* listenErrors() {
                let isSyncError = false;
                const resetSyncError = () => {
                    isSyncError = false;
                };
                let httpError = false;
                while (true) {
                    httpError = false;
                    isSyncError = true;
                    try {
                        setTimeout(resetSyncError);

                        yield call(function* execSaga() {
                            yield saga;
                        });
                        // eslint-disable-next-line no-console
                        console.error(
                            "Unexpected root saga termination. " +
                                "The root sagas are supposed to be sagas that live during the whole app lifetime!",
                            saga,
                        );
                    } catch (error) {
                        httpError = typeof error.httpError !== "undefined";
                        if (!httpError && isSyncError) {
                            throw new Error(`${saga.name} was terminated because it threw an exception on startup.`);
                        }
                        yield call(crashLogData, error);
                        yield call(handleError, error);
                    }

                    if (!httpError) {
                        // Para evitar que fallas infinitas bloqueen el browser...
                        // eslint-disable-next-line no-console
                        console.error(saga.name, " will be restarted after 1 second");
                        yield call(delay, 1000);
                    }
                }
            }),
        ),
    );
}

export function* hideModalNoControlled() {
    yield put(balancePurchaseActions.closeBalancePurchaseModalRequest());
}

export function* hideModalRedirectError() {
    yield put(changeStatusProductActions.modalHide());
    yield put(updateCompanyDataActions.updateCompanyFailure());
    yield put(transactionalProfileActions.modalHide());
    yield put(transactionalProfileActions.transactionalCountriesUpdateFailed());
    yield put(updateUserDataActions.hideUpdateUserDataStepZero());
    yield put(balancePurchaseActions.closeBalancePurchaseModalRequest());

    /**
     * TODO: add more modals if is necessary
     */
}

function* showAlertToken(message, scope = SCOPE_USER_BLOCKED) {
    yield call(hideModalRedirectError);
    yield put(
        notificationActions.showNotification(
            I18n.get(message || `transaction.invalid.otp.required.${isMobileNativeFunc() ? "mobile" : "desktop"}`),
            "error",
            [scope],
            true,
            1000,
        ),
    );

    yield put(routerActions.replace("/desktop"));
}

function* showDrawerStatusToken(type) {
    if (isMobileNativeFunc()) {
        const unlockMessage = type === MODAL_TEXT_UNLOCK && "returnCode.API583E";
        yield call(showAlertToken, unlockMessage);
        yield;
        return;
    }
    yield call(hideModalRedirectError);
    yield put(desktopAction.showDrawerStatusToken(type));
    yield put(routerActions.replace("/desktop"));
}

function* showAlertRemoteTimeout(message) {
    yield call(hideModalRedirectError);
    yield put(
        notificationActions.showNotification(
            I18n.get(message || "transaction.invalid.timeout.remote.error.message"),
            "error",
            ["desktop"],
            true,
            1000,
        ),
    );

    yield put(routerActions.replace("/desktop"));
}

export function* handleError(error) {
    /**
     * hide apple pay button if a error uncontrolled was throwing
     */

    yield fork(applePayRemovePassButton);
    let errorControlled = false;

    if (error.data) {
        switch (error.data.code) {
            // Add known error codes as new cases for avoid general error message

            // User is blocked
            case "COR019E":
                errorControlled = true;
                yield put(routerActions.push(REDIRECT_TOKEN_LOCKED_ENTRUST));
                yield put(
                    notificationActions.showNotification(I18n.get("entrust.token.blocked.message"), "error", [
                        "settings/authenticatorHandler",
                    ]),
                );
                yield call(hideModalRedirectError);

                break;
            case "API040E":
                errorControlled = true;
                yield put(replace("/desktop"));
                yield put(
                    notificationActions.showNotification(
                        I18n.get("products.operation.NoDispose"),
                        "error",
                        ["desktop"],
                        true,
                        10000,
                        undefined,
                    ),
                );
                break;
            // Attempt to sign an expired transaction
            case "COR108E":
                errorControlled = true;
                yield put(
                    notificationActions.showNotification(I18n.get("transaction.expired.signAttempt"), "error", [
                        "transactions",
                    ]),
                );
                yield put(replace("/transactions/list"));
                break;
            case "API575E": {
                errorControlled = true;
                yield call(
                    showAlertToken,
                    `transaction.invalid.otp.required.${isMobileNativeFunc() ? "mobile" : "desktop"}`,
                );
                break;
            }
            case "API583E": {
                errorControlled = true;
                yield call(showAlertToken, "returnCode.API583E", SCOPE_TOKEN_BLOCKED);
                yield put(routerActions.push(REDIRECT_TOKEN_LOCKED_ENTRUST));
                break;
            }
            case "API582E": {
                errorControlled = true;
                yield call(showAlertToken, "returnCode.API582E");
                break;
            }
            case "API041E": {
                errorControlled = true;
                if (isMobileNativeFunc()) {
                    yield put(
                        push({
                            pathname: "/invalidMobileVersion",
                            message: error?.data?.message,
                        }),
                    );
                }

                break;
            }
            case "API587E": {
                errorControlled = true;
                yield put(
                    push({
                        pathname: "/websiteunderconstruction",
                        message: "forms.servicePayment.outOf.service.label",
                    }),
                );
                break;
            }
            case "BAK589E": {
                errorControlled = true;
                yield put(
                    push({
                        pathname: "/error",
                        code: error.data.code,
                        idTransaction: error.data.idTransaction,
                    }),
                );

                break;
            }
            case "BAK590E": {
                errorControlled = true;
                yield call(showAlertRemoteTimeout, "returnCode.BAK590E");

                break;
            }
            case "COR005E": {
                errorControlled = true;
                yield call(showAlertRemoteTimeout, "returnCode.COR005E");

                break;
            }
            case "API602E":
            case "API805E":
                errorControlled = true;
                yield put(desktopAction.showMonitorFraudModal());
                yield put(replace("/desktop"));
                break;
            case "API1001E": {
                errorControlled = true;
                yield call(showDrawerStatusToken, MODAL_TEXT_ACTIVATE);
                break;
            }
            case "API1000E": {
                errorControlled = true;
                yield call(showDrawerStatusToken, MODAL_TEXT_UNLOCK);
                break;
            }
            case "API1002E": {
                errorControlled = true;
                yield call(showDrawerStatusToken, MODAL_TEXT_MIGRATE);
                break;
            }
            case "API2003E": {
                errorControlled = true;
                yield call(hideModalRedirectError);
                yield put(
                    notificationActions.showNotification(I18n.get(error.data.message), "error", [
                        "creditCardDetails",
                        "desktop",
                    ]),
                );
                break;
            }
            default:
                break;
        }

        if (!errorControlled) {
            yield call(hideModalNoControlled);
            // eslint-disable-next-line no-console
            console.error("[API Error Handler]:1", error.data, error.status);
            yield put(
                push({
                    pathname: "/error",
                    code: error.data.code,
                    message: error.data.message,
                    idTransaction: error.data.idTransaction,
                }),
            );
        }
    } else if (error.response && error.response.status === 401) {
        // eslint-disable-next-line no-console
        console.error("[API Error Handler]:2", error.response);
        // The request was made and the server responded, but with a status code outside of 2xx
        yield put({ type: globalTypes.CLEAN_UP });

        switch (error.response.data.code) {
            case "API004W":
                yield put(
                    notificationActions.showNotification(I18n.get("session.expired"), "error", ["externalLayout"]),
                );
                break;

            case "API010W":
                yield put(
                    notificationActions.showNotification(I18n.get("session.loggedinOtherDevice"), "error", [
                        "externalLayout",
                    ]),
                );
                break;
            case "API006E":
            case "API007E":
            case undefined: // The 401 sent by the Apigateway / Authorization server doesn't send a data code
                yield put(
                    notificationActions.showNotification(I18n.get("session.expired"), "warning", ["externalLayout"]),
                );
                yield put(replace("/"));
                break;

            default:
                break;
        }
    } else if (error.request) {
        // eslint-disable-next-line no-console
        console.error("[API Error Handler]:3", error.request);
        // The request was made but no response was received

        const timesConfigFailed = yield select(configSelectors.getTimesFailed);
        const timesI18nFailed = yield select(i18nSelectors.getTimesFailed);

        if (timesConfigFailed >= MAX_FAILED_TIMES || timesI18nFailed >= MAX_FAILED_TIMES) {
            yield put(push({ pathname: "/serverError" }));
        } else {
            const lang = yield select(i18nSelectors.getLang);
            yield put({ type: configTypes.RESET_SAGAS_UPDATE, lang });
            yield put({ type: i18nTypes.RESET_SAGAS_UPDATE, lang });
            yield put(
                push({
                    pathname: "/error",
                    code: "CLI999E",
                    idTransaction: "",
                }),
            );
        }
    } else if (axios.isCancel(error)) {
        // eslint-disable-next-line no-console
        console.error(error?.message);
    } else {
        // eslint-disable-next-line no-console
        console.error("[API Error Handler]:4", error.message);
        // Something happened in setting up the request that triggered an Error
        yield put(
            push({
                pathname: "/error",
                code: "CLI999E",
                idTransaction: "",
            }),
        );
    }
}
