import { createReducer, makeActionCreator } from "util/redux";

export const types = {
    LIST_REQUEST: "debitCards/LIST_REQUEST",
    LIST_REQUEST_DESKTOP: "debitCards/LIST_REQUEST_DESKTOP",
    LIST_FAILURE: "debitCards/LIST_FAILURE",
    LIST_SUCCESS: "debitCards/LIST_SUCCESS",
    REQUEST_PIN_REQUEST: "debitCards/REQUEST_PIN_REQUEST",
    ACTIVATE_NEW_DEBIT_CARD: "debitCards/ACTIVATE_NEW_DEBIT_CARD",
};

export const INITIAL_STATE = {
    fetching: false,
    list: [],
    hasPendingRenewal: false,
};

export default createReducer(INITIAL_STATE, {
    [types.LIST_REQUEST]: (state) => ({
        ...state,
        fetching: true,
    }),
    [types.LIST_REQUEST_DESKTOP]: (state) => ({
        ...state,
        fetching: true,
    }),
    [types.LIST_FAILURE]: (state) => ({
        ...state,
        fetching: false,
    }),
    [types.LIST_SUCCESS]: (_, action) => ({
        fetching: false,
        list: action.list,
        hasPendingRenewal: action.hasPendingRenewal,
    }),
});

export const actions = {
    listRequest: makeActionCreator(types.LIST_REQUEST, "callBackResult"),
    listRequestDesktop: makeActionCreator(types.LIST_REQUEST_DESKTOP, "callBackResult"),
    listFailure: makeActionCreator(types.LIST_FAILURE),
    listSuccess: makeActionCreator(types.LIST_SUCCESS, "list", "hasPendingRenewal"),
    requestPinRequest: makeActionCreator(types.REQUEST_PIN_REQUEST, "data"),
    activateNewDebitCard: makeActionCreator(types.ACTIVATE_NEW_DEBIT_CARD, "data"),
};

export const selectors = {
    isFetching: ({ debitCards }) => debitCards.fetching,
    getList: ({ debitCards }) => debitCards.list,
    hasPendingRenewal: ({ debitCards }) => debitCards.hasPendingRenewal,
};
