import AttachedFile from "pages/_components/AttachedFile";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Image from "pages/_components/Image";
import Row from "pages/_components/Row";
import { bool, func, shape, string } from "prop-types";
import React, { useEffect, useRef } from "react";
import Col from "react-bootstrap/lib/Col";
import { actions as notificationActions } from "reducers/notification";
import Text from "pages/_components/Text";
import useFileProcessor from "pages/settings/companyDataUpdate/_components/ProcesingFileHooks";
import { resizableRoute } from "../Resizable";

const AttachmentField = (props) => {
    const { form, field, dispatch } = props;
    const { setFieldValue } = form;
    const { name } = field;
    const fileInputRef = useRef(null);
    const { date, dataFile, loadingFile, errorFile, processFile } = useFileProcessor();

    const loadedDocuments = field?.value || [];
    const fileUpload = loadedDocuments[0];

    const removeSelectedFile = () => {
        setFieldValue(name, []);
        fileInputRef.current.value = "";
    };

    useEffect(() => {
        if (!loadingFile && dataFile) {
            const { file, dataBase64 } = dataFile;
            const newFileLoaded = {
                key: name,
                name: file.name,
                size: file.size,
                content: dataBase64.split(",")[1],
            };
            setFieldValue(name, [newFileLoaded]);
        }
        if (errorFile) {
            dispatch(notificationActions.showNotification(errorFile, "error", [props?.notificationScope]));
        }
    }, [date, dataFile, loadingFile, errorFile]);

    const onChangeFile = async (event) => {
        event.stopPropagation();
        event.preventDefault();
        const fileAux = event.target.files[0];
        processFile(fileAux);
    };

    return (
        <Box
            display="flex"
            column
            fullWidth
            position="relative"
            background="component-background"
            borderRadius="default">
            <Box display="flex" fullWidth alignX="between" alignY="center">
                <Row alignY="center">
                    <Col xs={6}>
                        <Box display="flex">
                            <Box display="flex" className="mr-3">
                                <Image
                                    src={fileUpload ? "images/icons/checkedRing.svg" : "images/icons/ring.svg"}
                                    wrapperWidth="5"
                                    color="primary-color"
                                />
                            </Box>
                            <Box display="flex" alignY="center" flex1 gap="3">
                                <Box display="flex" column alignX="center" fullWidth position="relative">
                                    {props?.labelKey && (
                                        <Text
                                            labelKey={props?.labelKey}
                                            color="heading"
                                            size={props?.labelSize}
                                            noWrap={!props.isTablet}
                                        />
                                    )}
                                </Box>
                            </Box>
                        </Box>
                    </Col>
                    <Col xs={6}>
                        <Box display="flex" alignX="between">
                            <Box display="flex" alignX="center">
                                {!fileUpload && (
                                    <Button
                                        bsStyle="link"
                                        image="images/icons/upload.svg"
                                        onClick={() => {
                                            fileInputRef.current.click();
                                        }}
                                        label="global.attach"
                                    />
                                )}

                                <input
                                    id="myInput"
                                    type="file"
                                    ref={fileInputRef}
                                    style={{ display: "none" }}
                                    onChange={onChangeFile}
                                />
                            </Box>
                            <Box display="flex" alignX="flex-end" flex1>
                                {fileUpload && (
                                    <AttachedFile
                                        file={fileUpload}
                                        withEllipsis={false}
                                        wrapperWidth="175"
                                        wrapperHeight=""
                                        ellipsis
                                        breakWord
                                    />
                                )}
                                <Button
                                    image="images/icons/deleteTrash.svg"
                                    onClick={removeSelectedFile}
                                    className="px-0"
                                    imageMd
                                    {...(fileUpload ? { imageColor: "secondary-hover-color" } : { disabled: true })}
                                />
                            </Box>
                        </Box>
                    </Col>
                </Row>
            </Box>
        </Box>
    );
};

AttachmentField.propTypes = {
    form: shape({}).isRequired,
    field: shape({}).isRequired,
    dispatch: func.isRequired,
    notificationScope: string,
    labelKey: string,
    isTablet: bool.isRequired,
    labelSize: string,
};

AttachmentField.defaultProps = {
    notificationScope: "",
    labelKey: "",
    labelSize: "6",
};

export default resizableRoute(AttachmentField);
