import Text from "pages/_components/Text";
import { arrayOf, func, shape, string } from "prop-types";
import React, { useEffect, useState } from "react";
import DebitCardItem, { DebitCardStatusCardIssue, DebitCardStatusHitech } from "./DebitCardItem";

const DebitCardList = (props) => {
    const { debitCards, setCard, showEnableWalletRequest, user } = props;
    const [debitCardsToList, setDebitCardsToList] = useState([]);

    useEffect(() => {
        if (debitCards.length > 0) {
            const list = debitCards
                .map((card) => {
                    const newCard = card;
                    newCard.isActived =
                        card.cardStatusCode === DebitCardStatusHitech.Actived ||
                        card.cardStatusCode === DebitCardStatusCardIssue.Actived;
                    newCard.isBlocked =
                        card.cardStatusCode === DebitCardStatusHitech.Blocked ||
                        card.cardStatusCode === DebitCardStatusCardIssue.Blocked;
                    newCard.isInactived = card.cardStatusCode === DebitCardStatusCardIssue.Inactived;

                    if (!newCard.isActived && !newCard.isBlocked && !newCard.isInactived) {
                        return null;
                    }
                    return newCard;
                })
                .filter((card) => card !== null);

            if (list.length > 0) {
                setCard(list[0]);
            }
            setDebitCardsToList(list);
        }
    }, [debitCards]);

    return (
        <>
            {!debitCardsToList.length ? (
                <Text labelKey="debitCards.list.empty" />
            ) : (
                <>
                    {debitCardsToList.map((debitCard, index) => (
                        <DebitCardItem
                            debitCard={debitCard}
                            defaultChecked={index === 0}
                            setCard={setCard}
                            showEnableWalletRequest={showEnableWalletRequest}
                            user={user}
                        />
                    ))}
                </>
            )}
        </>
    );
};

DebitCardList.propTypes = {
    debitCards: arrayOf(
        shape({
            franchise: string,
        }),
    ),
    setCard: func.isRequired,
    showEnableWalletRequest: func,
    user: shape({}).isRequired,
};
DebitCardList.defaultProps = {
    debitCards: [],
    showEnableWalletRequest: undefined,
};

export default DebitCardList;
