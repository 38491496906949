import SideBarModal from "pages/_components/modal/SideBarModal";
import { bool, func } from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { actions as balancePurchaseActions, selectors as balancePurchaseSelectors } from "reducers/balancePurchase";
import BalancePurchase from "./BalancePurchase";

const PAGE_ID = "balance.purchase";

const BalancePurchaseModal = ({ dispatch, isShowBalancePurchaseModal }) => {
    const hideBalancePurchaseModal = () => {
        dispatch(balancePurchaseActions.closeBalancePurchaseModalRequest());
    };

    return (
        <SideBarModal
            show={isShowBalancePurchaseModal}
            onClose={hideBalancePurchaseModal}
            modalId={PAGE_ID}
            title="creditCard.financing.plans.balance.purchase">
            <BalancePurchase />
        </SideBarModal>
    );
};

BalancePurchaseModal.propTypes = {
    dispatch: func,
    isShowBalancePurchaseModal: bool,
};

BalancePurchaseModal.defaultProps = {
    dispatch: () => {},
    isShowBalancePurchaseModal: false,
};

const mapStateToProps = (state) => ({
    isShowBalancePurchaseModal: balancePurchaseSelectors.isShowBalancePurchaseModal(state),
});

export default connect(mapStateToProps)(BalancePurchaseModal);
