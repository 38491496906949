import classNames from "classnames";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button/Button";
import Row from "pages/_components/Row";
import Image from "pages/_components/Image";
import SwitchToggle from "pages/_components/SwitchToggle";
import Text from "pages/_components/Text";
import { bool, func, shape, string } from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import { push } from "react-router-redux/actions";
import { maskEmail } from "util/format";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { actions as changeStatusProductActions } from "reducers/changeStatusProduct";
import { actions as debitCardsActions, selectors as debitCardsSelectors } from "reducers/debitCards";
import { selectors as sessionSelectors } from "reducers/session";
import * as configUtils from "util/config";
import { isIOSPlatform, isMobileNativeFunc, isSupportVersion } from "util/device";
import { MINIMUM_VERSION_IOS_SUPPORTED, applePayCardWasDigitized } from "util/wallet/wallet.util";

export const ENABLED_RENEW_CARD = "renew.debit.card.enabled.functionality";

export const DebitCardStatusHitech = {
    Actived: "01",
    Blocked: "9",
};

export const DebitCardStatusCardIssue = {
    Actived: "90",
    Unlock: "90",
    Blocked: "9",
    Inactived: "5",
};

export const DebitCardStatusExpiration = {
    EXPIRED: "EXPIRED",
    TO_EXPIRE: "TO_EXPIRE",
    NOT_EXPIRED: "NOT_EXPIRED",
};

export const actionChangeStatusProduct = {
    Block: {
        actionName: "block",
        actionImage: "images/BlockProduct.svg",
        statusExpected: DebitCardStatusCardIssue.Blocked,
    },
    Unlock: {
        actionName: "unlock",
        actionImage: "images/UnlockProduct.svg",
        statusExpected: DebitCardStatusCardIssue.Unlock,
    },
    Active: {
        actionName: "active",
        actionImage: "images/UnlockProduct.svg",
        statusExpected: DebitCardStatusCardIssue.Actived,
    },
    NewActive: {
        actionName: "newActive",
        actionImage: "images/UnlockProduct.svg",
        statusExpected: DebitCardStatusCardIssue.Actived,
    },
};

const DebitCardItem = (props) => {
    const {
        debitCard,
        dispatch,
        setCard,
        defaultChecked,
        showEnableWalletRequest,
        loggedUser,
        hasPendingRenewal,
        disabledBool,
        user,
    } = props;
    const cardNumberMask = debitCard?.numberMask || "";

    const [showWallet, setShowWallet] = useState(false);
    const [isCardWasDigitized, setCardWasDigitized] = useState(false);

    const validateCardWasDigitized = async () => {
        const cardWasDigitizedResp = await applePayCardWasDigitized(debitCard.panSuffix);
        const cardWasDigitized =
            cardWasDigitizedResp !== undefined && (cardWasDigitizedResp === true || cardWasDigitizedResp === "true");
        setCardWasDigitized(cardWasDigitized);
    };

    const validateEnrolledWallet = async () => {
        if (!debitCard?.panSuffix) {
            setShowWallet(false);
            return;
        }
        setShowWallet(true);
    };

    const isDebitCardActive = useMemo(
        () => debitCard?.isActived !== undefined && (debitCard.isActived === true || debitCard.isActived === "true"),
        [debitCard],
    );

    const isDebitCardExpired = useMemo(
        () =>
            debitCard?.expirationStatus !== undefined &&
            (debitCard.expirationStatus === "EXPIRED" || debitCard.expirationStatus === "TO_EXPIRE"),
        [debitCard],
    );

    const isDebitCardBlocked = useMemo(
        () => debitCard?.cardStatusCode !== undefined && debitCard.cardStatusCode === DebitCardStatusCardIssue.Blocked,
        [debitCard],
    );

    const isDebitCardPendingActivation = useMemo(
        () => debitCard?.isPendingActivation !== undefined && debitCard.isPendingActivation === true,
        [debitCard],
    );

    const isDebitCardPendingRenewal = useMemo(
        () => debitCard?.isPendingRenewal !== undefined && debitCard.isPendingRenewal === true,
        [debitCard],
    );

    const validateCreditCardWallet = () => {
        if (!isMobileNativeFunc() || !isIOSPlatform()) {
            return;
        }

        const versionResult = isSupportVersion(window.device.version, MINIMUM_VERSION_IOS_SUPPORTED);
        if (versionResult === undefined || versionResult === false || versionResult === "false") {
            return;
        }

        /**
         * if config key not exists, get default value true for show only certain users
         */
        const testEnabled = configUtils.getBoolean("applePay.test.enabled", true);
        if (testEnabled === false) {
            validateEnrolledWallet();
            return;
        }

        const userTestEnabled = configUtils.getArray("applePay.test.users");
        if (userTestEnabled.some((item) => item && loggedUser?.userId && item === loggedUser.userId)) {
            validateEnrolledWallet();
        }
    };

    useEffect(() => {
        validateCardWasDigitized();
        validateCreditCardWallet();
    }, [debitCard]);

    const handleClickRequestPin = () => {
        const cardBrand = `${debitCard?.franchise?.charAt(0).toUpperCase()}${debitCard?.franchise
            ?.slice(1)
            .toLowerCase()}`;

        const values = {
            productType: "debitCards",
            idActivity: "debitCards.requestPin",
            productData: {
                idProduct: debitCard.cardNumber,
                numberMask: debitCard?.numberMask || "",
                shortLabel: debitCard.shortLabel,
                franchise: debitCard.franchise,
                cardBrand,
                cardNumber: debitCard?.cardNumber || "",
                parametersSubmit: { cardNumber: debitCard.cardNumber },
            },
            email: maskEmail(user.email),
            title: `debitCards.drawer.requestPin.title`,
            isPinAssigment: true,
            paramsNotification: {
                success: {
                    CARD_NUMBER: debitCard.shortLabel,
                    CARD_BRAND: cardBrand,
                },
                error: { CARD_NUMBER: debitCard.shortLabel },
            },
            submitAction: debitCardsActions.requestPinRequest,
        };

        dispatch(changeStatusProductActions.modalShow(values));
    };

    const handleToggle = () => {
        let action;
        action = debitCard.isInactived ? actionChangeStatusProduct.Active : action;
        action = debitCard.isActived ? actionChangeStatusProduct.Block : action;
        action = debitCard.isBlocked ? actionChangeStatusProduct.Unlock : action;

        const cardFranchise = debitCard.franchise.charAt(0).toUpperCase() + debitCard.franchise.toLowerCase().slice(1);
        if (action) {
            const values = {
                productType: "debitCards",
                productData: {
                    shortLabel: `${cardFranchise} ${cardNumberMask}`,
                    parametersSubmit: { debitCard },
                },
                title: `debitCards.drawer.${action.actionName}.title`,
                actionName: action.actionName,
                statusExpected: action.statusExpected,
                productionStatusExpected: null,
                actionImage: action.actionImage,
                paramsNotification: {
                    success: {
                        CARD_NUMBER: cardNumberMask,
                        CARD_BRAND: cardFranchise,
                    },
                    error: {
                        CARD_NUMBER: cardNumberMask,
                        CARD_BRAND: cardFranchise,
                    },
                },
            };

            if (isDebitCardPendingActivation) {
                values.submitAction = debitCardsActions.activateNewDebitCard;
            }

            dispatch(changeStatusProductActions.modalShow(values));
        }
    };

    const renderDisclaimerWalletDigitized = () => (
        <Box
            alignX="between"
            alignY="center"
            borderRadius="xl"
            background="white"
            className="p-5 py-md-3 py-xl-4 pr-xl-3"
            display="flex"
            flex1
            wrap>
            <Box display="flex" alignY="center" gap={3}>
                <Box display="flex">
                    <Image src="images/applePay.svg" ariaLabel="applePayLogo" wrapperWidth="auto" />
                </Box>
                <Text size="5" color="heading" regular className="mr-2" labelKey="enabled.wallet.disclaimer.applePay" />
            </Box>
        </Box>
    );

    const renderButtonWallet = () => (
        <Box
            alignX="between"
            alignY="center"
            borderRadius="bottom-xl"
            background="white"
            className="p-3 py-md-3 py-xl-4 pr-xl-3"
            display="flex"
            flex1
            wrap>
            <Box display="flex" alignY="center" gap={3}>
                <Image src="images/applePay.svg" ariaLabel="applePayLogo" />
                <Text size="5" color="heading" regular className="mr-2" labelKey="enabled.wallet.button.applePay" />
            </Box>
            <Button
                className="toolbar-btn view-back"
                onClick={() => {
                    if (showEnableWalletRequest) {
                        showEnableWalletRequest(debitCard?.cardNumber);
                    }
                }}
                image="images/arrowRightLight.svg"
                bsStyle="link"
            />
        </Box>
    );

    const showWalletCard = () =>
        isMobileNativeFunc() &&
        isDebitCardActive &&
        showWallet !== undefined &&
        showWallet === true &&
        isCardWasDigitized !== undefined;

    const renderRequestPinButton = () => (
        <Col xs={12} md={4} mdOffset={4}>
            <Box display="flex" alignX="flex-end" column fullHeight>
                <Button
                    bsStyle="outline"
                    type="button"
                    onClick={handleClickRequestPin}
                    label="debitCard.request.pin"
                    block
                    disabled={isDebitCardPendingActivation}
                />
            </Box>
        </Col>
    );

    const renderRenewButton = () => {
        const showRenewDebitCard = configUtils.get(ENABLED_RENEW_CARD, "0");
        if (showRenewDebitCard !== "1") {
            return <div />;
        }
        if (isDebitCardExpired && !isDebitCardBlocked && !isDebitCardPendingRenewal) {
            return (
                <Button
                    bsStyle="outline"
                    type="button"
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        dispatch(
                            push(
                                `/formCustom/renewDebitCard?debitCard=${debitCard.cardNumber}&mask=${cardNumberMask}&franchise=${debitCard.franchise}`,
                            ),
                        );
                    }}
                    label="debitCard.renewal"
                    block
                    disabled={hasPendingRenewal}
                />
            );
        }

        return null;
    };

    return (
        <Col xs={12} md={6}>
            <Box
                className="px-7 py-7"
                {...(showWallet !== undefined && showWallet === true && { borderRadius: "top-xl" })}
                {...(!showWallet && { borderRadius: "default" })}
                display="flex"
                background="white"
                alignY="center"
                alignX="between"
                fullWidth
                wrap>
                <Row>
                    <Col xs={6}>
                        <Box display="flex" gap="4">
                            <Box
                                display="flex"
                                alignY="center"
                                className={classNames("radio-btn-wrapper m-0 p-0", {
                                    "disabled-option": disabledBool,
                                })}
                                position="relative">
                                <input
                                    defaultChecked={defaultChecked}
                                    id={`debitcard-${cardNumberMask}`}
                                    type="radio"
                                    onClick={() => setCard(debitCard)}
                                    name="debitcard"
                                    label=""
                                    className="radio-btn mr-3"
                                />
                                <label className="c-control-label" htmlFor="totalAmount">
                                    <div className="c-control-icons">
                                        <div className="c-control-mark">
                                            <Image src="images/radioButtonMark.svg" className="svg-icon svg-caret" />
                                        </div>
                                    </div>
                                </label>
                            </Box>
                            <Box display="flex" gap="3">
                                <Box display="flex">
                                    <Image src={`images/${debitCard.franchise?.toLowerCase()}-debitCard.svg`} />
                                </Box>
                                <Box display="flex">
                                    <Text color="heading">{cardNumberMask}</Text>
                                </Box>
                            </Box>
                        </Box>
                    </Col>

                    <Col xs={6}>
                        <Box display="flex" alignX="flex-end">
                            <SwitchToggle
                                switchIsOn={debitCard.isActived}
                                handleToggle={handleToggle}
                                labelOnKey="debitCard.unblocked"
                                labelOffKey={
                                    (debitCard.isBlocked ? "debitCard.blocked" : "") ||
                                    (debitCard.isInactived ? "debitCard.inactive" : "")
                                }
                                textClassName="credit-card-label ml-3"
                                id={debitCard?.idProduct || ""}
                            />
                        </Box>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <Box
                            display="flex"
                            gap="5"
                            className={classNames(" p-6", {
                                "disabled-option": disabledBool,
                            })}>
                            {renderRequestPinButton()}
                            {renderRenewButton()}
                        </Box>
                    </Col>
                </Row>
            </Box>
            {showWalletCard() && isCardWasDigitized === false && renderButtonWallet()}

            {showWalletCard() && isCardWasDigitized === true && renderDisclaimerWalletDigitized()}
        </Col>
    );
};
DebitCardItem.propTypes = {
    dispatch: func.isRequired,
    debitCard: shape({
        acct: shape({}),
        cardStatusCode: string,
        codCardType: string,
        eftDate: string,
        expDate: string,
        franchise: string,
        issueDate: string,
        statusReason: string,
    }),
    setCard: func.isRequired,
    defaultChecked: bool,
    showEnableWalletRequest: func,
    loggedUser: shape({}),
    disabledBool: bool,
    hasPendingRenewal: false,
    user: shape({}).isRequired,
};
DebitCardItem.defaultProps = {
    debitCard: {},
    defaultChecked: false,
    showEnableWalletRequest: undefined,
    loggedUser: undefined,
    disabledBool: false,
    hasPendingRenewal: false,
};

const mapStateToProps = (state) => ({
    loggedUser: sessionSelectors.getUser(state),
    hasPendingRenewal: debitCardsSelectors.hasPendingRenewal(state),
});

export default connect(mapStateToProps)(DebitCardItem);
