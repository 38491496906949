import { call, put, takeLatest } from "redux-saga/effects";

import { types, actions } from "reducers/preApprovedCreditPos";
import { actions as actionsPreApprovedProducts } from "reducers/preApprovedProduct";
import * as preApprovedCredit from "middleware/preApprovedCreditPos";

import { actions as notificationActions } from "reducers/notification";
import * as i18n from "util/i18n";

const READ_STATUS = "SEEN";

const sagas = [
    takeLatest(types.PREAPPROVED_CREDIT_SEND_REQUEST, preApprovedProductSendRequest),
    takeLatest(types.GET_PREAPPROVED_CREDIT_POS_REQUEST, getApprovedCreditPos),
];

export default sagas;

function* preApprovedProductSendRequest({ preApprovedCreditUsersId, preApprovedCreditUserStatus }) {
    const response = yield call(
        preApprovedCredit.preApprovedProductsSendRequest,
        preApprovedCreditUsersId,
        preApprovedCreditUserStatus,
    );
    if (response.type === "W") {
        yield put({
            type: types.PREAPPROVED_CREDIT_SEND_REQUEST_FAILED,
        });
        yield put(
            notificationActions.showNotification(i18n.get("preApprovedCreditPos.drawer.error"), "error", ["desktop"]),
        );
    } else {
        yield put({
            type: types.PREAPPROVED_CREDIT_SEND_REQUEST_SUCCESS,
        });
        yield put(actionsPreApprovedProducts.getPreApprovedProductsList());
        if (preApprovedCreditUserStatus === "REJECTED") {
            yield put(
                notificationActions.showNotification(i18n.get("preApprovedCreditPos.drawer.rejected"), "success", [
                    "desktop",
                ]),
            );
            return;
        }
        yield put(
            notificationActions.showNotification(i18n.get("preApprovedCreditPos.drawer.accepted"), "success", [
                "desktop",
            ]),
        );
    }
}

function* getApprovedCreditPos({ preApprovedCreditUserId }) {
    const preApprovedCreditUserStatus = READ_STATUS;
    const response = yield call(
        preApprovedCredit.getPreApprovedCreditPOS,
        preApprovedCreditUserId,
        preApprovedCreditUserStatus,
    );

    if (response.type === "W") {
        yield put(actions.getApprovedCreditPosRequestFailed());
    } else {
        const { data } = response.data;
        const { preApprovedCreditBanner, preApprovedCreditUser } = data;
        yield put(actions.getApprovedCreditPosRequestSuccess(preApprovedCreditBanner, preApprovedCreditUser));
    }
}
