import { Field, Form, Formik } from "formik";
import Box from "pages/_components/Box/Box";
import Button from "pages/_components/Button/Button";
import * as FormFieldsComponents from "pages/forms/_components/_fields/Index";
import { resizableRoute } from "pages/_components/Resizable";
import Text from "pages/_components/Text";
import { arrayOf, bool, func, shape, string } from "prop-types";
import React from "react";
import FormattedAmount from "pages/_components/FormattedAmount";
import PageLoading from "pages/_components/PageLoading";
import { connect } from "react-redux";
import withRouter from "react-router-dom/withRouter";
import { push } from "react-router-redux";
import { actions as balancePurchaseActions, selectors as balancePurchaseSelectors } from "reducers/balancePurchase";
import { selectors as creditCardSelectors } from "reducers/creditCard";
import { compose } from "redux";
import * as i18n from "util/i18n";
import * as config from "util/config";
import * as Yup from "yup";
import { USD_CURRENCY } from "constants.js";
import { Row, Col } from "react-bootstrap";
import { numberFormat } from "util/number";
import FieldLabel from "pages/_components/fields/FieldLabel";

const FORM_ID = "creditCard.financing.plans.balance.purchase";
const BalancePurchase = ({
    dispatch,
    availablePlanList,
    isDesktop,
    productId,
    isSubmitting,
    isFetching,
    creditCardList,
}) => {
    const { decimalSeparator, thousandSeparator } = numberFormat();
    const selectedCreditCard = creditCardList?.find((card) => card?.idProduct === productId);
    const minAmount = config.getInteger("balance.purchase.amount.min");
    const maxAmountPercentage = config.getInteger("balance.purchase.amount.max.percentage") / 100;
    const minAmountLabel = `${USD_CURRENCY} ${minAmount.toFixed(2)}`;

    const maximunAmountLabel = `${i18n.get(`creditCard.financing.plans.balance.purchase.max.amount`)} ${
        selectedCreditCard?.availableBalanceCurrency
    } ${(selectedCreditCard?.availableBalance * maxAmountPercentage)
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} + ${i18n
        .get("forms.transfers.foreign.commission.label_preview")
        .toLowerCase()}`;

    const getAvailablePlans = ({ amount }, formikBag) => {
        dispatch(balancePurchaseActions.getAvailablePlansRequest(productId, amount, formikBag));
    };

    const validationSchema = () =>
        Yup.lazy(({ amount }) =>
            Yup.object().shape({
                amount: Yup.string()
                    .required(i18n.get("global.validation.error.required"))
                    .test("is-valid-number", i18n.get("global.validation.error.required"), () => amount.quantity !== "")
                    .test(
                        "min",
                        i18n.get("creditCard.financing.plans.balance.purchase.amount.min", null, {
                            MIN_AMOUNT: minAmountLabel,
                        }),
                        () => {
                            const number = parseFloat(amount.quantity);
                            return amount.quantity === "" || number >= minAmount;
                        },
                    )
                    .test(
                        "max",
                        i18n.get("creditCard.financing.plans.balance.purchase.amount.max", null, {
                            PERCENTAGE: maxAmountPercentage * 100,
                        }),
                        () => {
                            const number = parseFloat(amount.quantity);
                            return (
                                amount.quantity === "" ||
                                number <= selectedCreditCard?.availableBalance * maxAmountPercentage
                            );
                        },
                    ),
            }),
        );

    const dataAmount = {
        decimalSeparator,
        precision: 2,
        thousandsSeparator: thousandSeparator,
        options: [{ id: 1, label: USD_CURRENCY }],
    };

    const renderPlans = () => {
        if (isFetching || isSubmitting) {
            return <PageLoading />;
        }

        if (availablePlanList !== null && availablePlanList !== undefined && availablePlanList.length > 0) {
            return (
                <>
                    <Box component="ul" display="flex" column gap="5">
                        {availablePlanList.map((plan) => (
                            <Box
                                component="li"
                                key={plan.id}
                                background="background-secondary"
                                borderRadius="default"
                                className="py-5 pl-5 pr-3 cursor-pointer balance-purchase-card"
                                role="button"
                                onClick={() => {
                                    dispatch(balancePurchaseActions.closeBalancePurchaseModalRequest());
                                    dispatch(balancePurchaseActions.setSelectedPlan(plan));
                                    dispatch(push(`/formCustom/balancePurchase?creditCard=${productId}`));
                                }}
                                noList>
                                <Row>
                                    <Col xs={2} style={{ alignSelf: "center" }}>
                                        <Box
                                            display="flex"
                                            gap="2"
                                            alignY="center"
                                            fullWidth
                                            className="justify-content-center">
                                            <Text component="h3" color="primary" className="m-0  " size="big" bold>
                                                {plan.id}
                                            </Text>
                                        </Box>

                                        <Box
                                            display="flex"
                                            gap="2"
                                            alignY="center"
                                            fullWidth
                                            className="justify-content-center">
                                            <Text
                                                component="h3"
                                                color="primary"
                                                className="m-0  "
                                                size={isDesktop ? "7" : "5"}
                                                bold>
                                                {plan.title}
                                            </Text>
                                        </Box>
                                    </Col>
                                    <Col xs={10}>
                                        <Box display="flex" gap="2" alignY="center" fullWidth className="mb-1">
                                            <Text component="span" color="primary" size={isDesktop ? "7" : "6"}>
                                                {plan.description}
                                            </Text>
                                        </Box>

                                        <Box display="flex" gap="3" position="relative">
                                            <Box display="flex" column gap="1">
                                                <Box display="flex">
                                                    <Text
                                                        component="label"
                                                        className="m-0"
                                                        size="7"
                                                        addColon
                                                        labelKey={`${FORM_ID}.plan.amount`}
                                                    />
                                                    <FormattedAmount
                                                        currency={plan.amount.currency}
                                                        quantity={plan.amount.quantity}
                                                        size="7"
                                                    />
                                                </Box>

                                                <Box display="flex">
                                                    <Text
                                                        component="label"
                                                        className="m-0"
                                                        size="7"
                                                        addColon
                                                        labelKey={`${FORM_ID}.plan.term`}
                                                    />
                                                    <Text size="7">{`${plan.term} ${i18n.get(
                                                        "creditCard.financing.plans.balance.purchase.option.label",
                                                    )}`}</Text>
                                                </Box>

                                                <Box display="flex">
                                                    <Text
                                                        component="label"
                                                        className="m-0"
                                                        size="7"
                                                        addColon
                                                        labelKey={`${FORM_ID}.plan.rate`}
                                                    />
                                                    <Text size="7">{plan.rate}</Text>
                                                </Box>

                                                <Box display="flex">
                                                    <Text
                                                        component="label"
                                                        className="m-0"
                                                        size="7"
                                                        addColon
                                                        labelKey={`${FORM_ID}.plan.montly.payment`}
                                                    />
                                                    <FormattedAmount
                                                        currency={plan.montlyPayment.currency}
                                                        quantity={plan.montlyPayment.amount}
                                                        size="7"
                                                    />
                                                </Box>
                                            </Box>

                                            {/* <Box position="absolute" right="0" top="0">
                                        <Button
                                            block
                                            label={`${FORM_ID}.plan.button.select`}
                                            onClick={() => {
                                                dispatch(balancePurchaseActions.closeBalancePurchaseModalRequest());
                                                dispatch(balancePurchaseActions.setSelectedPlan(plan));
                                                dispatch(push(`/formCustom/balancePurchase?creditCard=${productId}`));
                                            }}
                                            bsStyle="link"
                                            disabled={isSubmitting || isFetching}
                                        />
                                    </Box> */}
                                        </Box>
                                    </Col>
                                </Row>
                            </Box>
                        ))}
                    </Box>
                    <Text
                        size="7"
                        key="text"
                        component="p"
                        className="m-0 pt-5"
                        color="heading-color"
                        align="center"
                        labelKey={`${FORM_ID}.plans.disclaimer`}
                    />
                </>
            );
        }
        return null;
    };

    return (
        <Formik
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={validationSchema}
            initialValues={{ amount: { currency: USD_CURRENCY, quantity: 0.0 } }}
            onSubmit={getAvailablePlans}>
            <Form className="full-height">
                <Box>
                    <Text
                        key="text"
                        component="p"
                        className="m-0 mb-10"
                        color="heading"
                        align="center"
                        labelKey={`${FORM_ID}.modal.subtitle`}
                    />
                </Box>
                <div className="mb-6">
                    <Box display="flex" gap="7" alignY="flex-end" fullWidth>
                        <div className="flex-column ">
                            <FieldLabel idField="amount" labelKey={`${FORM_ID}.amount.label`} mode="edit" />
                            <Field
                                mode="edit"
                                component={FormFieldsComponents.Amount}
                                data={dataAmount}
                                key="amount"
                                name="amount"
                                idField="amount"
                                customPlaceholderCurrency=""
                                customPlaceholderQuantity="0.00"
                                quantityTextAlign="text-left"
                                disabledCurrency
                                hideCurrency
                                absoluteStyleError
                            />
                        </div>
                        <Box display="flex">
                            <Button
                                type="submit"
                                bsStyle="primary"
                                disabled={isSubmitting || isFetching}
                                className="px-7">
                                <Text defaultValue={i18n.get(`${FORM_ID}.plans`)} noWrap="nowrap" />
                            </Button>
                        </Box>
                    </Box>
                </div>
                <Text size="7" color="secondary" defaultValue={maximunAmountLabel} />

                <Box className="mt-8">{renderPlans()}</Box>
            </Form>
        </Formik>
    );
};

const mapStateToProps = (state) => ({
    isFetching: balancePurchaseSelectors.isFetching(state),
    availablePlanList: balancePurchaseSelectors.getAvailablePlanList(state),
    productId: balancePurchaseSelectors.getProductId(state),
    creditCardList: creditCardSelectors.getList(state),
});

BalancePurchase.propTypes = {
    isFetching: bool,
    dispatch: func,
    availablePlanList: arrayOf(shape()),
    isDesktop: bool.isRequired,
    productId: string.isRequired,
    isSubmitting: bool,
    creditCardList: arrayOf(shape({})),
};

BalancePurchase.defaultProps = {
    isFetching: false,
    dispatch: () => {},
    availablePlanList: null,
    creditCardList: null,
    isSubmitting: false,
};

export default compose(connect(mapStateToProps), withRouter)(resizableRoute(BalancePurchase));
