import Box from "pages/_components/Box";
import Checkbox from "pages/_components/Checkbox";
import GridTable from "pages/_components/GridTable/GridTable";
import Image from "pages/_components/Image";
import Text from "pages/_components/Text";
import { bool, func, shape } from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
    actions as transactionalProfileActions,
    selectors as transactionalProfileSelector,
} from "reducers/transactionalProfile";

const Country = React.memo(
    (props) => {
        const { columnsTemplate, disabledOutColumn, isDesktop, country, dispatch, isAuthorized } = props;

        const [localCountry, setLocalCountry] = useState(country);

        useEffect(() => {
            if (JSON.stringify(localCountry) !== JSON.stringify(country)) {
                setLocalCountry(country);
            }
        }, [country]);

        const handleChangeCountry = (value, _type) => {
            setLocalCountry((prev) => ({ ...prev, [_type]: value }));
            dispatch(transactionalProfileActions.setModifiedCountry(value, localCountry.id, _type, isAuthorized));
        };

        if (!country) {
            return null;
        }

        return (
            <GridTable.Container
                columnsTemplate={columnsTemplate}
                className={isDesktop ? "gapX-9" : "gapX-3 min-height-9-i"}
                padding="py-2 px-5 px-md-7"
                removeBottomSpacing>
                <GridTable.Data columnStart={1} alignX="flex-start">
                    <Box display="flex" alignY="center" fullWidth gap="3">
                        <Box display="flex">
                            <Image src={`images/Flags/${localCountry.id.toLowerCase()}.svg`} width="5" height="5" />
                        </Box>
                        <Text size="6" ellipsis>
                            {localCountry.label}
                        </Text>
                    </Box>
                </GridTable.Data>
                <GridTable.Data columnStart={2} alignX="flex-start">
                    <Checkbox
                        name={`${localCountry.id}In`}
                        className="d-flex"
                        noLabel
                        checked={localCountry.in}
                        onChange={(e) => handleChangeCountry(e, "in")}
                    />
                </GridTable.Data>
                <GridTable.Data columnStart={3} alignX="flex-start">
                    <Checkbox
                        name={`${localCountry.id}Out`}
                        className="d-flex"
                        noLabel
                        checked={localCountry.out}
                        onChange={(e) => handleChangeCountry(e, "out")}
                        disabled={disabledOutColumn}
                    />
                </GridTable.Data>
            </GridTable.Container>
        );
    },
    (prevProps, nextProps) => prevProps.country === nextProps.country,
);

const mapStateToProps = (state, ownProps) => ({
    country: transactionalProfileSelector.getModifiedCountries(state)[ownProps.data.id],
});

Country.propTypes = {
    country: shape({}).isRequired,
    columnsTemplate: func.isRequired,
    isDesktop: bool.isRequired,
    disabledOutColumn: bool,
    dispatch: func.isRequired,
    isAuthorized: bool,
};

Country.defaultProps = {
    disabledOutColumn: false,
    isAuthorized: false,
};

export default connect(mapStateToProps)(Country);
