import Head from "pages/_components/Head";
import { resizableRoute } from "pages/_components/Resizable";
import classNames from "classnames";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import Row from "pages/_components/Row";
import { arrayOf, bool, func, shape } from "prop-types";
import React, { useEffect, useState } from "react";
import { get as getConfig } from "util/config";
import { connect } from "react-redux";
import { routerActions } from "react-router-redux";
import { actions as changeStatusProductActions } from "reducers/changeStatusProduct";
import { actions as debitCardsActions, selectors as debitCardsSelectors } from "reducers/debitCards";
import { selectors as sessionSelectors } from "reducers/session";
import { isFromNotificationRedirection } from "util/general";
import { maskEmail } from "util/format";
import {
    DEBIT_CARD_WALLET_TYPE,
    PROVISION_SUCCESS_CODE,
    START_EVENTS_CODE,
    applePayRemovePassButton,
} from "util/wallet/wallet.util";
import EnabledWalletCardModal from "pages/wallet/EnabledWalletCardModal";
import * as i18n from "util/i18n";
import { actions as notificationActions } from "reducers/notification";
import {
    DebitCardStatusCardIssue,
    DebitCardStatusHitech,
    DebitCardStatusExpiration as expirationStatus,
} from "./DebitCardItem";
import DebitCardList from "./DebitCardList";

export const ENABLED_RENEW_CARD = "renew.debit.card.enabled.functionality";

const DebitCards = (props) => {
    const { fetching, debitCards, dispatch, user, location, isDesktop } = props;

    const [card, setCard] = useState("");

    const getRenewalCards = (debitCardsRef) =>
        debitCardsRef.filter((debitCard) => {
            if (!debitCard.cardStatusCode || !debitCard?.expirationStatus) {
                return false;
            }

            const isActived =
                debitCard.cardStatusCode === DebitCardStatusHitech.Actived ||
                debitCard.cardStatusCode === DebitCardStatusCardIssue.Actived;

            const isBlocked =
                debitCard.cardStatusCode === DebitCardStatusHitech.Blocked ||
                debitCard.cardStatusCode === DebitCardStatusCardIssue.Blocked;

            const isInactived = debitCard.cardStatusCode === DebitCardStatusCardIssue.Inactived;
            const isExpired = debitCard.expirationStatus !== expirationStatus.NOT_EXPIRED;
            return (isActived || isBlocked || isInactived) && isExpired;
        });

    const callbackDebitCardResult = (result) => {
        try {
            const showRenewDebitCard = getConfig(ENABLED_RENEW_CARD, "0");
            if (!showRenewDebitCard || showRenewDebitCard !== "1") {
                return;
            }

            const { hasPendingRenewal, responseCards } = result;
            if (hasPendingRenewal) {
                dispatch(
                    notificationActions.showNotification(i18n.get("debitCards.drawer.renew.disclaimer3"), "warning", [
                        "debitcards",
                    ]),
                );
                return;
            }

            const renewalCards = getRenewalCards(responseCards);

            if (!renewalCards?.length || renewalCards.length === 0) {
                return;
            }

            if (renewalCards.length === 1) {
                dispatch(
                    notificationActions.showNotification(
                        i18n.get("debitCards.drawer.renew.disclaimer1", null, {
                            CARD_BRAND: renewalCards[0].franchise,
                            CARD_NUMBER: renewalCards[0].numberMask,
                        }),
                        "warning",
                        ["debitcards"],
                    ),
                );
                return;
            }

            dispatch(
                notificationActions.showNotification(i18n.get("debitCards.drawer.renew.disclaimer2"), "warning", [
                    "debitcards",
                ]),
            );
        } catch (e) {
            // eslint-disable-next-line no-console
            console.log(e);
        }
    };

    useEffect(() => {
        dispatch(debitCardsActions.listRequest(callbackDebitCardResult));
    }, []);

    const handleBack = () => {
        if (isFromNotificationRedirection(location)) {
            dispatch(routerActions.push("/desktop"));
        } else {
            dispatch(routerActions.goBack());
        }
    };

    const renderHeader = () => <Head onBack={handleBack} title="menu.debitcards" />;

    const handleClick = () => {
        const cardBrand = `${card?.franchise?.charAt(0).toUpperCase()}${card?.franchise?.slice(1).toLowerCase()}`;

        const values = {
            productType: "debitCards",
            idActivity: "debitCards.requestPin",
            productData: {
                idProduct: card.cardNumber,
                numberMask: card?.numberMask || "",
                shortLabel: card.shortLabel,
                franchise: card.franchise,
                cardBrand,
                cardNumber: card?.cardNumber || "",
                parametersSubmit: { cardNumber: card.cardNumber },
            },
            email: maskEmail(user.email),
            title: `debitCards.drawer.requestPin.title`,
            isPinAssigment: true,
            paramsNotification: {
                success: {
                    CARD_NUMBER: card.shortLabel,
                    CARD_BRAND: cardBrand,
                },
                error: { CARD_NUMBER: card.shortLabel },
            },
            submitAction: debitCardsActions.requestPinRequest,
        };

        dispatch(changeStatusProductActions.modalShow(values));
    };

    const [showEnabledWallet, setshowEnabledWallet] = useState(false);
    const [cardId, setCardId] = useState();
    const showEnableWalletRequest = (cardIdRef) => {
        if (!cardIdRef) {
            return;
        }

        setCardId(cardIdRef);
        setshowEnabledWallet(true);
    };

    const callbackProvision = (resultCode) => {
        if (!resultCode || resultCode === START_EVENTS_CODE) {
            return;
        }
        setshowEnabledWallet(false);
        applePayRemovePassButton();

        if (resultCode === PROVISION_SUCCESS_CODE) {
            dispatch(debitCardsActions.listRequest());
        }
    };

    return (
        <>
            <Notification scopeToShow="debitcards" />
            {!fetching && renderHeader()}
            <MainContainer showLoader={fetching}>
                <Row className={classNames("pt-7", { "min-height-10rem": isDesktop })}>
                    <DebitCardList
                        debitCards={debitCards}
                        setCard={setCard}
                        handleClick={handleClick}
                        showEnableWalletRequest={(cardIdRef) => {
                            showEnableWalletRequest(cardIdRef);
                        }}
                        user={user}
                    />
                </Row>
            </MainContainer>
            {showEnabledWallet && cardId && (
                <EnabledWalletCardModal
                    show={showEnabledWallet}
                    cardId={cardId}
                    cardType={DEBIT_CARD_WALLET_TYPE}
                    callbackError={() => {
                        setshowEnabledWallet(false);
                        applePayRemovePassButton();
                    }}
                    scopeError="debitcards"
                    activityId="enabled.wallet.creditCard.send"
                    callbackProvision={callbackProvision}
                />
            )}
        </>
    );
};

DebitCards.propTypes = {
    dispatch: func.isRequired,
    fetching: bool.isRequired,
    debitCards: arrayOf(shape({})).isRequired,
    user: shape({}).isRequired,
    location: shape({}),
    isDesktop: bool.isRequired,
};

DebitCards.defaultProps = {
    location: {},
};

const mapStateToProps = (state) => ({
    fetching: debitCardsSelectors.isFetching(state),
    debitCards: debitCardsSelectors.getList(state),
    user: sessionSelectors.getUser(state),
});

export default connect(mapStateToProps)(resizableRoute(DebitCards));
