import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";

import * as creditCardmw from "middleware/creditCard";
import * as creditCardsmw from "middleware/creditCards";
import * as products from "middleware/products";
import { actions, types, selectors } from "reducers/creditCard";
import { actions as changeStatusProductActions } from "reducers/changeStatusProduct";
import {
    downloadPdf,
    downloadXls,
    downloadMobileFile,
    CONTENT_TYPE_PDF_EXTENSION,
    CONTENT_TYPE_XLS_EXTENSION,
} from "util/download";
import { actions as notificationActions } from "reducers/notification";
import { adjustIdFieldErrors } from "util/form.js";
import * as i18n from "util/i18n";
import { push, replace } from "react-router-redux";
import { isMobileNativeFunc } from "util/device";
import b64toBlob from "b64-to-blob";
import * as creditCardsCache from "util/creditCardsCache";
import { actions as balancePurchaseActions } from "reducers/balancePurchase";

const sagas = [
    takeLatest(types.DETAILS_REQUEST, detailsRequest),
    takeLatest(types.DETAILS_NO_MOVEMENTS_REQUEST, detailsNoMovementsRequest),
    takeLatest(types.MOVEMENTS_REQUEST, movementsRequest),
    takeLatest(types.FETCH_MORE_MOVEMENTS_REQUEST, fetchMoreMovementsRequest),
    takeLatest(types.DOWNLOAD_MOVEMENTS_REQUEST, downloadMovements),
    takeEvery(types.DETAILS_EVERY_REQUEST, detailsEveryRequest),
    takeEvery(types.MOVEMENTS_TRANSIT_REQUEST, getMovementsTransit),
    takeEvery(types.DOWNLOAD_MOVEMENTS_TRANSIT_REQUEST, downloadMovementTransitList),
    takeEvery(types.ASSIGN_PIN_CREDIT_CARD, assignPinCode),
    takeEvery(types.FINANCING_PLANS_REQUEST, financingPlansRequest),
];

export default sagas;

function* detailsEveryRequest({ id }) {
    if (creditCardsCache.isCreditCardDataCacheStatusOk(id)) {
        const creditCardData = yield select(selectors.getDetail, id);
        const totalFavorites = yield select(selectors.getTotalFavorites);

        const creditCardSuccess = {
            ...creditCardData,
            hasErrorLoadingPoints: false,
        };

        yield put(actions.detailEverySuccess(id, creditCardSuccess, totalFavorites));
    } else {
        const detailResponse = yield call(creditCardmw.detailsRequest, id);

        if (detailResponse.type === "W") {
            const creditCardFailed = {
                idProduct: id,
                hasErrorLoadingPoints: true,
            };
            yield put(actions.detailEveryFailure(id, creditCardFailed));
            yield put(
                notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", [
                    "creditCardDetails",
                ]),
            );
        } else {
            const { creditCard, totalFavorites } = detailResponse.data.data;

            const creditCardSuccess = {
                ...creditCard,
                hasErrorLoadingPoints: false,
            };

            yield put(actions.detailEverySuccess(id, creditCardSuccess, totalFavorites));
        }
    }
}

function* detailsNoMovementsRequest({ id, relationType, hasOwnLimit, idForm }) {
    if (creditCardsCache.isCreditCardDataCacheStatusOk(id)) {
        const creditCard = yield select(selectors.getDetail, id);
        const totalFavorites = yield select(selectors.getTotalFavorites);

        const updateCreditCard = { ...creditCard, hasOwnLimit, relationType };

        yield put(actions.detailSuccess(updateCreditCard, idForm, totalFavorites));
    } else {
        const detailResponse = yield call(creditCardmw.detailsRequest, id);

        if (detailResponse?.data?.code === "BAK001W") {
            yield put(
                push({
                    pathname: "/error",
                    code: detailResponse?.data?.code,
                    message: detailResponse?.data?.message,
                    idTransaction: detailResponse?.data?.idTransaction,
                }),
            );
        } else if (detailResponse.type === "W") {
            yield put(actions.detailFailure());
            yield put(
                notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", [
                    "creditCardDetails",
                ]),
            );
        } else {
            const { creditCard, totalFavorites } = detailResponse.data.data;
            const updateCreditCard = creditCard ? { ...creditCard, hasOwnLimit, relationType } : creditCard;

            yield put(actions.detailSuccess(updateCreditCard, idForm, totalFavorites));
        }
    }
}

function* detailsRequest({ id, filters, relationType, hasOwnLimit, updatingByPoints, idForm }) {
    let invalidateCache = false;
    const responseCache = yield call(creditCardsmw.creditCardCacheIsInvalidatedRequest);
    if (responseCache.type !== "W") {
        invalidateCache = responseCache.data.data.invalidateCreditCardCache;
        if (invalidateCache) {
            yield put(actions.invalidateCache());
        }
    }

    if (!invalidateCache && creditCardsCache.isCreditCardDataCacheStatusOk(id)) {
        const creditCard = yield select(selectors.getDetail, id);
        const detailInList = yield select(selectors.getDetailInList, id);
        const totalFavorites = yield select(selectors.getTotalFavorites);

        const updateCreditCard = {
            ...creditCard,
            hasOwnLimit,
            relationType,
            productionStatus: detailInList?.productionStatus,
        };

        yield put(actions.detailSuccess(updateCreditCard, idForm, totalFavorites));
        yield call(getMovements, id, filters, updatingByPoints);
    } else {
        const detailResponse = yield call(creditCardmw.detailsRequest, id);

        if (detailResponse?.data?.code === "BAK001W") {
            yield put(
                push({
                    pathname: "/error",
                    code: detailResponse?.data?.code,
                    message: detailResponse?.data?.message,
                    idTransaction: detailResponse?.data?.idTransaction,
                }),
            );
        } else if (detailResponse.type === "W") {
            yield put(actions.detailFailure());
            yield put(
                notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", [
                    "creditCardDetails",
                ]),
            );
        } else {
            const { creditCard, totalFavorites } = detailResponse.data.data;
            const updateCreditCard = creditCard ? { ...creditCard, hasOwnLimit, relationType } : creditCard;
            yield put(actions.detailSuccess(updateCreditCard, idForm, totalFavorites));

            yield call(getMovements, id, filters, updatingByPoints);
        }
    }
}

function* getMovements(id, filters, updatingByPoints) {
    const movementsResponse = yield call(creditCardmw.movementsRequest, id, { ...filters });

    if (movementsResponse.type === "W") {
        yield put(actions.detailFailure());
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["creditCardDetails"]),
        );
    } else {
        const {
            statements,
            pageNumber,
            moreStatements,
            totalCount,
            totalCurrentPeriod,
            offset,
        } = movementsResponse.data.data;

        yield put(
            actions.detailSuccessMovement(
                statements,
                pageNumber,
                moreStatements,
                totalCount,
                totalCurrentPeriod,
                offset,
            ),
        );

        if (updatingByPoints) {
            yield put(
                notificationActions.showNotification(i18n.get("forms.product.exchange.points.sent.succes"), "success", [
                    "creditCardDetails",
                ]),
            );
        }
    }
}

function* movementsRequest({ id, filters, formikBag }) {
    const response = yield call(creditCardmw.movementsRequest, id, { ...filters });

    if (response.type === "W" && formikBag) {
        formikBag.setError(adjustIdFieldErrors(response.data.data));
    } else if (response.type === "W") {
        yield put(actions.movementsFailure());
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["creditCardDetails"]),
        );
    } else {
        const { statements, moreStatements, totalCount, totalCurrentPeriod, offset } = response.data.data;
        yield put(actions.movementsSuccess(statements, moreStatements, totalCount, totalCurrentPeriod, offset));
    }

    if (formikBag) {
        formikBag.setSubmitting(false);
    }
}
function* fetchMoreMovementsRequest({ id, filters, formikBag }) {
    const response = yield call(creditCardmw.movementsRequest, id, { ...filters });

    if (response.type === "W" && formikBag) {
        formikBag.setError(adjustIdFieldErrors(response.data.data));
    } else if (response.type === "W") {
        yield put(actions.fetchMoreMovementsFailure());
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["creditCardDetails"]),
        );
    } else {
        const { statements, moreStatements, totalCount, totalCurrentPeriod, offset, quantity } = response.data.data;

        yield put(
            actions.fetchMoreMovementsSuccess(
                statements,
                moreStatements,
                totalCount,
                totalCurrentPeriod,
                filters.pageNumber,
                offset,
                quantity,
            ),
        );
    }

    if (formikBag) {
        formikBag.setSubmitting(false);
    }
}

function* errorDownloadMovements(onFinishDownload) {
    yield put({ type: types.DOWNLOAD_MOVEMENTS_FAILURE });
    yield put(
        notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["creditCard/details"]),
    );
    onFinishDownload();
}

function* successDownloadMovements(onFinishDownload) {
    yield put({ type: types.DOWNLOAD_MOVEMENTS_SUCCESS });
    onFinishDownload();
}

function* downloadFileOnMobile(content, contentType, fileName, onFinishDownload) {
    const fileBlob = b64toBlob(content, contentType);
    downloadMobileFile(fileBlob, fileName, contentType);
    yield call(successDownloadMovements, onFinishDownload);
}

function* downloadFileOnWeb(content, format, fileName, onFinishDownload) {
    if (format === ".pdf") {
        downloadPdf(fileName, content);
        yield call(successDownloadMovements, onFinishDownload);
        yield;
        return;
    }

    if (format === ".xls") {
        downloadXls(fileName, content);
        yield call(successDownloadMovements, onFinishDownload);
        yield;
        return;
    }

    yield call(errorDownloadMovements, onFinishDownload);
}

function* downloadMovements({ idCreditCard, filters, format, onFinishDownload }) {
    const response = yield call(creditCardmw.downloadMovements, idCreditCard, filters, format);
    if (response.type === "W" || !response?.data?.data) {
        yield call(errorDownloadMovements, onFinishDownload);
        yield;
        return;
    }

    const { content, fileName, contentType } = response.data.data;
    if (!content || !contentType) {
        yield call(errorDownloadMovements, onFinishDownload);
        yield;
        return;
    }

    if (isMobileNativeFunc()) {
        yield call(downloadFileOnMobile, content, contentType, fileName || "file", onFinishDownload);
        yield;
        return;
    }

    yield call(downloadFileOnWeb, content, format, fileName || "file", onFinishDownload);
}

function* getMovementsTransit({ productId }) {
    const response = yield call(creditCardmw.getMovementsTransit, productId);
    if (!response) {
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["desktop", "login"]),
        );
        yield put({ type: types.MOVEMENTS_TRANSIT_FAILURE });
    } else {
        const { type } = response;
        if (type === "W") {
            yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["desktop"]));
            yield put(replace("/desktop"));
            yield put({ type: types.MOVEMENTS_TRANSIT_FAILURE });
        } else {
            yield put({
                type: types.MOVEMENTS_TRANSIT_SUCCESS,
                payload: { movementTransitList: response?.data?.data?.movementTransitList || [] },
            });
        }
    }
}

function* downloadMovementTransitList({ productId, contentType }) {
    const response = yield call(creditCardmw.downloadMovementsTransit, productId, contentType);
    if (!response) {
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["desktop", "login"]),
        );
        yield put({ type: types.MOVEMENTS_TRANSIT_FAILURE });
    } else {
        const { type } = response;
        if (type === "W") {
            yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["desktop"]));
            yield put(replace("/desktop"));
            yield put({ type: types.MOVEMENTS_TRANSIT_FAILURE });
        } else {
            if (response?.data?.data) {
                const { content, fileName, contentType: contentTypeResponse } = response.data.data;
                if (isMobileNativeFunc()) {
                    const fileBlob = b64toBlob(content, contentTypeResponse);
                    downloadMobileFile(fileBlob, fileName, contentTypeResponse);
                } else {
                    if (contentType === CONTENT_TYPE_PDF_EXTENSION) {
                        downloadPdf(fileName, content);
                    }
                    if (contentType === CONTENT_TYPE_XLS_EXTENSION) {
                        downloadXls(fileName, content);
                    }
                }
            }

            yield put({
                type: types.DOWNLOAD_MOVEMENTS_TRANSIT_SUCCESS,
            });
        }
    }
}

function* assignPinCode({ data }) {
    const { parametersSubmit, credentialsForm, formikBag, idActivity, paramsNotification } = data;
    const { pinCode } = credentialsForm;

    if (pinCode.length < 4) {
        yield put(changeStatusProductActions.modalHide());
        yield put(
            notificationActions.showNotification(
                i18n.get("creditCards.snackbar.assignPin.incomplete.messageError", null, paramsNotification?.error),
                "error",
                ["creditcards", "creditCardDetails", "desktop"],
            ),
        );
    }
    try {
        const response = yield call(products.changeProductStatus, idActivity, parametersSubmit, credentialsForm);

        if (response?.status === 200) {
            if (response.data.code === "COR020W") {
                formikBag.setErrors(adjustIdFieldErrors(response.data.data));
            } else if (response.data.code === "COR000I") {
                yield put(changeStatusProductActions.modalHide());
                yield put(
                    notificationActions.showNotification(
                        i18n.get("creditCards.snackbar.assignPin.messageOk", null, paramsNotification?.success),
                        "success",
                        ["creditcards", "creditCardDetails", "desktop"],
                    ),
                );
            } else {
                yield put(changeStatusProductActions.modalHide());
                yield put(
                    notificationActions.showNotification(
                        i18n.get("creditCards.snackbar.assignPin.messageError", null, paramsNotification?.error),
                        "error",
                        ["creditcards", "creditCardDetails", "desktop"],
                    ),
                );
            }
        } else {
            yield put(changeStatusProductActions.modalHide());
            yield put(
                notificationActions.showNotification(response.data.message, "error", [
                    "creditcards",
                    "creditCardDetails",
                    "desktop",
                ]),
            );
        }
    } catch (error) {
        let message = "";
        if (error?.data?.code === "COR019E") {
            message = i18n.get("entrust.token.blocked.message");
        } else {
            message = error?.data?.message || i18n.get("global.unexpectedError");
        }

        yield put(changeStatusProductActions.modalHide());
        yield put(
            notificationActions.showNotification(message, "error", ["creditcards", "creditCardDetails", "desktop"]),
        );
    }

    if (formikBag) {
        formikBag.setSubmitting(false);
    }
}

function* financingPlansRequest({ productId, onFinish }) {
    const response = yield call(creditCardmw.getFinancingPlans, productId);
    if (!response) {
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", [
                "creditCardDetailFinancingPlan",
            ]),
        );
    } else {
        const { type } = response;
        if (type === "W") {
            yield put(
                notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", [
                    "creditCardDetailFinancingPlan",
                ]),
            );
            yield put(replace("/desktop"));
        } else {
            yield put(balancePurchaseActions.closeBalancePurchaseModalRequest());
            yield put({
                type: types.FINANCING_PLANS_SUCCESS,
                financingPlans: response?.data?.data?.financingPlans || [],
            });
        }
    }

    if (onFinish) {
        onFinish();
    }
}
