/* eslint import/prefer-default-export: 0 */
import * as API from "middleware/api";
import { credentialsToUnderscoreFormat } from "util/form";

export const listRequest = () => API.executeWithAccessToken("/debitcard.list");

export const requestPinRequest = (cardNumber, credentials) => {
    const credentialsWithUnderscore = credentialsToUnderscoreFormat(credentials);
    return API.executeWithAccessToken("/debitCards.requestPin", {
        cardNumber,
        ...credentialsWithUnderscore,
    });
};

export const activateNewDebitCard = (cardNumber, credentials) => {
    const credentialsWithUnderscore = credentialsToUnderscoreFormat(credentials);
    return API.executeWithAccessToken("/debitCards.new.activate", {
        cardNumber,
        ...credentialsWithUnderscore,
    });
};
