import classNames from "classnames";
import { Form, Formik } from "formik";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import FormattedAmount from "pages/_components/FormattedAmount";
import FormattedDate from "pages/_components/FormattedDate";
import GridTable from "pages/_components/GridTable/GridTable";
import GridTableContainer from "pages/_components/GridTable/GridTableContainer";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import CredentialTokenComponent from "pages/forms/_components/credential/CredentialTokenComponent";
import TransactionStatus from "pages/transactions/_components/TransactionStatus";
import { arrayOf, bool, func, shape } from "prop-types";
import React, { useEffect } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { selectors as transactionsSelectors, actions as transactionsActions } from "reducers/transactions";
import { push } from "react-router-redux";
import * as i18n from "util/i18n";
import * as Yup from "yup";

const MultipleSignatureConfirmation = ({
    transactionsToProcess,
    history,
    isDesktop,
    isDeleteMode,
    dispatch,
    fetching,
    isSignatureComplete,
}) => {
    useEffect(
        () => () => {
            dispatch(transactionsActions.clearTransactionsToProcess());
        },
        [dispatch],
    );

    const handleBack = () => {
        history.goBack();
    };

    const handleSubmit = (credentials) => {
        if (isDeleteMode) {
            dispatch(transactionsActions.rejectBulkTransactionsRequest(transactionsToProcess, credentials));
        } else {
            dispatch(transactionsActions.authorizeBulkTransactionsRequest(transactionsToProcess, credentials));
        }
    };

    const handleHome = () => {
        dispatch(transactionsActions.setInitialState());
        dispatch(push("/desktop"));
    };

    const validationSchema = () =>
        Yup.object().shape({
            otp: Yup.string().required(i18n.get("global.validation.error.required")),
        });

    // eslint-disable-next-line no-nested-ternary
    const columnsTemplate = isDesktop
        ? isSignatureComplete
            ? "repeat(5, 1fr)"
            : "repeat(4, 1fr) "
        : "1fr auto 1.5rem";

    const getBeneficiary = (tx) => {
        const { data } = tx;
        let destinatary = null;

        if (data?.beneficiaryDescription !== null && data?.beneficiaryDescription !== undefined) {
            destinatary = data.beneficiaryDescription;
        } else if (data?.beneficiary?.name !== null && data?.beneficiary?.name !== undefined) {
            destinatary = data.beneficiary.name;
        } else if (isDesktop) {
            destinatary = "-";
        }

        return destinatary;
    };

    const getTitleLabel = () => {
        if (isSignatureComplete) {
            return "transactions.bulk.confirmation.title";
        }
        if (isDeleteMode) {
            return "transactions.bulk.confirmation.title.delete";
        }
        return "transactions.bulk.confirmation.title.sign";
    };

    const listOfTransactions =
        transactionsToProcess &&
        transactionsToProcess.map((tx) => (
            <GridTableContainer className="product-data" columnsTemplate={columnsTemplate}>
                <GridTable.Data columnStart={1} alignX="flex-start" alignY="center">
                    <Text {...(!isDesktop && { bold: true })}>{tx.activityName}</Text>
                </GridTable.Data>
                <GridTable.Data
                    columnStart={isDesktop ? 2 : 1}
                    rowStart={isDesktop ? 1 : 3}
                    alignX={isDesktop ? "center" : "flex-start"}
                    alignY={isDesktop ? "center" : "flex-start"}>
                    <FormattedDate date={tx.creationDateTime} anotherFormat="DD/MM/YYYY HH:mm" />
                </GridTable.Data>
                <GridTable.Data
                    columnStart={isDesktop ? 3 : 1}
                    rowStart={isDesktop ? 1 : 2}
                    alignX={isDesktop ? "center" : "flex-start"}
                    alignY={isDesktop ? "center" : "flex-start"}>
                    <Text>{getBeneficiary(tx)}</Text>
                </GridTable.Data>

                <GridTable.Data columnStart={4} alignX="flex-end" alignY="center">
                    <FormattedAmount currency={tx.data.amount.currency} quantity={tx.data.amount.quantity} />
                </GridTable.Data>
                {isDesktop && isSignatureComplete && (
                    <GridTable.Data columnStart={5} alignX="center" alignY="center">
                        <TransactionStatus showIcon={false} idTransactionStatus={tx.idTransactionStatus} showLabel />
                    </GridTable.Data>
                )}
                {!isDesktop && isSignatureComplete && (
                    <GridTable.Data columnStart={4} rowStart={2} rowWidth={2} alignX="center" alignY="center">
                        <TransactionStatus showIcon={false} idTransactionStatus={tx.idTransactionStatus} showLabel />
                    </GridTable.Data>
                )}
            </GridTableContainer>
        ));

    return (
        <>
            <Head onBack={handleBack} title="pendingDispatch.list.title" />
            <Notification scopeToShow="transactions" />
            <MainContainer showLoader={false}>
                <Formik
                    initialValues={{ otp: "" }}
                    onSubmit={handleSubmit}
                    validateOnChange={false}
                    validateOnBlur={false}
                    enableReinitialize
                    validationSchema={validationSchema()}>
                    {(props) => (
                        <Form className="form-confirmation-wrapper">
                            <Row className="mb-8">
                                <Col xs={12} md={10} mdOffset={1}>
                                    <Box
                                        background="component-background"
                                        borderRadius="default"
                                        className="form-confirmation-box">
                                        <Text
                                            component="h2"
                                            size="4"
                                            color="heading-color"
                                            className="m-0 pb-7 pt-5 pt-md-7 px-5"
                                            bold
                                            labelKey={getTitleLabel()}
                                        />

                                        <GridTable>
                                            <GridTable.Header>
                                                <GridTable.Container columnsTemplate={columnsTemplate}>
                                                    <GridTable.Data
                                                        columnStart={1}
                                                        columnWidth={1}
                                                        alignX="flex-start"
                                                        alignY="center"
                                                        inHeader>
                                                        <Text labelKey="transactions.bulk.confirmation.table.operationType" />
                                                    </GridTable.Data>
                                                    {isDesktop && (
                                                        <>
                                                            <GridTable.Data
                                                                columnStart={2}
                                                                columnWidth={1}
                                                                alignX="center"
                                                                alignY="center"
                                                                inHeader>
                                                                <Text labelKey="transactions.bulk.confirmation.table.dateTime" />
                                                                <Button
                                                                    imageXs
                                                                    black
                                                                    className="px-3"
                                                                    // onClick={this.handleOrderList}
                                                                    image="images/arrow-down.svg"
                                                                />
                                                            </GridTable.Data>
                                                            <GridTable.Data
                                                                columnStart={3}
                                                                columnWidth={1}
                                                                alignX="center"
                                                                alignY="center"
                                                                inHeader>
                                                                <Text labelKey="transactions.bulk.confirmation.table.beneficiary" />
                                                            </GridTable.Data>
                                                            <GridTable.Data
                                                                columnStart={4}
                                                                columnWidth={1}
                                                                alignX="flex-end"
                                                                alignY="center"
                                                                inHeader>
                                                                <Text labelKey="transactions.bulk.confirmation.table.amount" />
                                                            </GridTable.Data>
                                                        </>
                                                    )}
                                                    {isDesktop && isSignatureComplete && (
                                                        <GridTable.Data
                                                            columnStart={5}
                                                            columnWidth={1}
                                                            alignX="center"
                                                            alignY="center"
                                                            inHeader>
                                                            <Text labelKey="transactions.bulk.confirmation.table.status" />
                                                        </GridTable.Data>
                                                    )}
                                                    {!isDesktop && isSignatureComplete && (
                                                        <GridTable.Data
                                                            columnStart={5}
                                                            columnWidth={1}
                                                            alignX="center"
                                                            alignY="center"
                                                            inHeader>
                                                            <Text labelKey="transactions.bulk.confirmation.table.amountStatus" />
                                                        </GridTable.Data>
                                                    )}
                                                </GridTable.Container>
                                            </GridTable.Header>
                                            <GridTable.Body>{listOfTransactions}</GridTable.Body>
                                        </GridTable>
                                        {isSignatureComplete &&
                                            isDeleteMode &&
                                            (listOfTransactions === undefined || listOfTransactions.length === 0) && (
                                                <Box
                                                    display="flex"
                                                    fullWidth
                                                    alignX="center"
                                                    className="no-more-data min-height-8rem">
                                                    <Text
                                                        labelKey="pending.transactions.list.none"
                                                        component="p"
                                                        align="center"
                                                        bold
                                                        size="6"
                                                        color="text"
                                                    />
                                                </Box>
                                            )}

                                        {!isSignatureComplete && (
                                            <Box>
                                                <CredentialTokenComponent
                                                    credentials={["otp"]}
                                                    propsForm={props}
                                                    onChangeToken={(tokenCode) => {
                                                        // eslint-disable-next-line react/prop-types
                                                        if (props?.setFieldValue) {
                                                            // eslint-disable-next-line react/prop-types
                                                            props.setFieldValue("otp", tokenCode);
                                                        }
                                                    }}
                                                />
                                            </Box>
                                        )}
                                    </Box>
                                </Col>
                            </Row>
                            <Row>
                                {isSignatureComplete ? (
                                    <Col xs={12} md={4} mdOffset={4}>
                                        <Button label="global.home" bsStyle="outline" onClick={handleHome} block />
                                    </Col>
                                ) : (
                                    <>
                                        <Col xs={12} md={3} mdOffset={3}>
                                            <Button
                                                label="global.cancel"
                                                bsStyle="outline"
                                                onClick={handleBack}
                                                block
                                            />
                                        </Col>
                                        <Col
                                            xs={12}
                                            md={3}
                                            className={classNames({
                                                "grid-reversed": !isDesktop,
                                            })}>
                                            <Button
                                                label="global.confirm"
                                                bsStyle="primary"
                                                type="submit"
                                                loading={fetching}
                                                block
                                            />
                                        </Col>
                                    </>
                                )}
                            </Row>
                        </Form>
                    )}
                </Formik>
            </MainContainer>
        </>
    );
};

MultipleSignatureConfirmation.propTypes = {
    isDesktop: bool.isRequired,
    history: shape({
        push: func.isRequired,
    }).isRequired,
    transactionsToProcess: arrayOf(shape({})),
    isDeleteMode: bool,
    dispatch: func.isRequired,
    fetching: bool,
    isSignatureComplete: bool.isRequired,
};

MultipleSignatureConfirmation.defaultProps = { isDeleteMode: false, fetching: false, transactionsToProcess: [] };

const mapStateToProps = (state) => ({
    transactionsToProcess: transactionsSelectors.getTransactionsToProcess(state),
    fetching: transactionsSelectors.getFetching(state),
    isSignatureComplete: transactionsSelectors.isSignatureComplete(state),
    isDeleteMode: transactionsSelectors.isDeleteMode(state),
});
export default connect(mapStateToProps)(MultipleSignatureConfirmation);
