import { call, put, takeLatest } from "redux-saga/effects";

import { types } from "reducers/template";
import * as template from "middleware/template";
import { actions as notificationActions } from "reducers/notification";
import * as i18n from "util/i18n";
import * as form from "middleware/form";

const sagas = [
    takeLatest(types.LOAD_TEMPLATE_LIST, loadTemplateList),
    takeLatest(types.DELETE_TEMPLATE, deleteTemplate),
    takeLatest(types.SAVE_TEMPLATE_REQUEST, saveTemplateTransaction),
];

export default sagas;

function* saveTemplateTransaction({ idForm, data, templateName, formikBag, idActivityTemplate }) {
    const response = yield call(
        form.saveTemplate,
        idForm,
        data,
        templateName,
        idActivityTemplate === "transfers.local.send" ? "transfers.thirdParties.send" : idActivityTemplate,
    );

    if (response.type === "W") {
        formikBag.setErrors(response.data.data);
        yield put({ type: types.SAVE_TEMPLATE_FAILURE });
    } else {
        const confirmationMessage = i18n.get("forms.saveTemplate.confirmationMessage");

        yield put({ type: types.SAVE_TEMPLATE_SUCCESS, idActivityTemplate, data: response.data.data });
        yield put({ type: types.LOAD_TEMPLATE_LIST, idForm, idActivityTemplate });
        yield put(notificationActions.showNotification(confirmationMessage, "success", ["form"]));
    }

    formikBag.setSubmitting(false);
}

function* loadTemplateList({ idForm, idActivityTemplate }) {
    let response;
    if (idForm) {
        response = yield call(template.loadListByIdForm, idForm);
    } else {
        response = yield call(
            template.loadListByIdActivity,
            idActivityTemplate === "transfers.local.send" ? "transfers.thirdParties.send" : idActivityTemplate,
        );
    }

    if (response.type === "W") {
        yield put({ type: types.LOAD_TEMPLATE_LIST_FAILURE });
        yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["form"]));
    } else {
        yield put({
            type: types.LOAD_TEMPLATE_LIST_SUCCESS,
            templates: response.data.data.templates,
        });
    }
}

function* deleteTemplate({ item }) {
    const response = yield call(template.deleteItem, item);

    if (response.type === "W") {
        yield put({ type: types.DELETE_TEMPLATE_FAILURE });
        yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["form"]));
    } else {
        yield put({
            type: types.DELETE_TEMPLATE_SUCCESS,
            item,
        });
    }
}
