import { useState } from "react";
import * as config from "util/config";
import * as i18n from "util/i18n";

const readFileAsDataUrl = async (fileData) => {
    const fileReader = new FileReader();

    return new Promise((resolve, reject) => {
        fileReader.onload = (fileLoadedEvent) => {
            resolve(fileLoadedEvent.target.result);
        };

        fileReader.onerror = (error) => {
            reject(error);
        };

        fileReader.readAsDataURL(fileData);
    });
};

const useFileProcessor = () => {
    const [dataFile, setDataFile] = useState(false);
    const [date, setDate] = useState(null);
    const [loadingFile, setLoadingFile] = useState(false);
    const [errorFile, setErrorFile] = useState(null);

    const processFile = async (file, otherData) => {
        setDate(new Date());
        setLoadingFile(true);
        setErrorFile(null);
        setDataFile(null);
        try {
            if (file) {
                const fileTypes = config.get("settings.userData.allowedFileExtensions");
                const fileExtension = `${file.name.split(".").slice(-1)}`;
                if (fileTypes.indexOf(fileExtension) > -1) {
                    const fileMaxSize = config.get("core.maxFileSize");
                    if (file.size < fileMaxSize) {
                        const dataBase64 = await readFileAsDataUrl(file);
                        setDataFile({ file, dataBase64, otherData });
                    } else {
                        setErrorFile(
                            `${i18n.get("communications.compose.attachment.maxFileSize") +
                                fileMaxSize / (1024 * 1024)}Mb`,
                        );
                    }
                } else {
                    setErrorFile(i18n.get("communications.compose.attachment.invalid"));
                }
            }
        } catch (error) {
            setErrorFile(error);
        } finally {
            setLoadingFile(false);
        }
    };

    return { date, dataFile, loadingFile, errorFile, processFile };
};

export default useFileProcessor;
