import { createReducer, makeActionCreator } from "util/redux";

export const types = {
    TRANSACTIONAL_COUNTRIES_REQUEST: "transactionalProfile/TRANSACTIONAL_COUNTRIES_REQUEST",
    TRANSACTIONAL_COUNTRIES_SUCCESS: "transactionalProfile/TRANSACTIONAL_COUNTRIES_SUCCESS",
    TRANSACTIONAL_COUNTRIES_FAILED: "transactionalProfile/TRANSACTIONAL_COUNTRIES_FAILED",
    TRANSACTIONAL_COUNTRIES_SETTINGS_REQUEST: "transactionalProfile/TRANSACTIONAL_COUNTRIES_SETTINGS_REQUEST",
    TRANSACTIONAL_COUNTRIES_SETTINGS_SUCCESS: "transactionalProfile/TRANSACTIONAL_COUNTRIES_SETTINGS_SUCCESS",
    TRANSACTIONAL_COUNTRIES_SETTINGS_FAILED: "transactionalProfile/TRANSACTIONAL_COUNTRIES_SETTINGS_FAILED",
    TRANSACTIONAL_COUNTRIES_UPDATE_REQUEST: "transactionalProfile/TRANSACTIONAL_COUNTRIES_UPDATE_REQUEST",
    TRANSACTIONAL_COUNTRIES_UPDATE_SUCCESS: "transactionalProfile/TRANSACTIONAL_COUNTRIES_UPDATE_SUCCESS",
    TRANSACTIONAL_COUNTRIES_UPDATE_FAILED: "transactionalProfile/TRANSACTIONAL_COUNTRIES_UPDATE_FAILED",
    TRANSACTIONAL_CREDENTIALS_GROUPS_REQUEST: "transactionalProfile/TRANSACTIONAL_CREDENTIALS_GROUPS_REQUEST",
    TRANSACTIONAL_CREDENTIALS_GROUPS_SUCCESS: "transactionalProfile/TRANSACTIONAL_CREDENTIALS_GROUPS_SUCCESS",

    SHOW_APPROVAL_TRANSFER_REQUEST: "transactionalProfile/SHOW_APPROVAL_TRANSFER_REQUEST",
    SHOW_APPROVAL_TRANSFER_SUCCESS: "transactionalProfile/SHOW_APPROVAL_TRANSFER_SUCCESS",
    SHOW_APPROVAL_TRANSFER_FAILED: "transactionalProfile/SHOW_APPROVAL_TRANSFER_FAILED",

    MODAL_SHOW: "transactionalProfile/MODAL_SHOW",
    MODAL_CLOSE: "transactionalProfile/MODAL_CLOSE",
    MODAL_SHOW_MESSAGE: "transactionalProfile/MODAL_SHOW_MESSAGE",
    MODAL_CLOSE_MESSAGE: "transactionalProfile/MODAL_CLOSE_MESSAGE",
    MODAL_SHOW_EDIT_PROFILE: "transactionalProfile/MODAL_SHOW_EDIT_PROFILE",
    MODAL_CLOSE_EDIT_PROFILE: "transactionalProfile/MODAL_CLOSE_EDIT_PROFILE",
    SET_MODIFIED_COUNTRY: "transactionalProfile/SET_MODIFIED_COUNTRY",
    SET_MODIFIED_COUNTRY_LIST: "transactionalProfile/SET_MODIFIED_COUNTRY_LIST",
    SET_IS_ALL_IN_CHECKED_AUTHORIZED: "transactionalProfile/SET_IS_ALL_IN_CHECKED_AUTHORIZED",
    SET_IS_ALL_OUT_CHECKED_AUTHORIZED: "transactionalProfile/SET_IS_ALL_OUT_CHECKED_AUTHORIZED",
    SET_IS_ALL_IN_CHECKED_UNAUTHORIZED: "transactionalProfile/SET_IS_ALL_IN_CHECKED_UNAUTHORIZED",
    SET_IS_ALL_OUT_CHECKED_UNAUTHORIZED: "transactionalProfile/SET_IS_ALL_OUT_CHECKED_UNAUTHORIZED",
    RESET_COUNTRY_SELECTED: "transactionalProfile/RESET_COUNTRY_SELECTED",
};

export const INITIAL_STATE = {
    isFetching: false,
    countriesList: [],
    modifiedCountries: {},
    accountList: [],
    credentialsGroups: [],
    hasCompleteCredentialGroups: false,
    displayModal: false,
    displayModalMessage: false,
    displayModalEdit: false,
    isAllInCheckedAuthorized: false,
    isAllOutCheckedAuthorized: false,
    isAllInCheckedUnauthorized: false,
    isAllOutCheckedUnauthorized: false,
    modalProps: {},
    isOpenFromMenu: false,

    showApprovalTransfer: false,
    isFetchingTransfer: false,
    countriesTransferList: [],
    modifiedTransferCountries: [],
};

export default createReducer(INITIAL_STATE, {
    [types.TRANSACTIONAL_COUNTRIES_REQUEST]: (state, action) => ({
        ...state,
        isFetching: true,
        countriesList: [],
        hasProfile: null,
        accountSelected: action.account,
    }),

    [types.TRANSACTIONAL_COUNTRIES_SUCCESS]: (state, action) => {
        const countriesMap = {};
        action.countries.forEach((item) => {
            countriesMap[item.id] = item;
        });

        return {
            ...state,
            isFetching: false,
            countriesList: action.countries,
            modifiedCountries: countriesMap,
            hasProfile: action.hasProfile,
        };
    },

    [types.TRANSACTIONAL_COUNTRIES_FAILED]: (state) => ({
        ...state,
        isFetching: false,
        countriesList: [],
        hasProfile: null,
        accountSelected: null,
    }),

    [types.SHOW_APPROVAL_TRANSFER_REQUEST]: (state) => ({
        ...state,
        isFetchingTransfer: true,
        countriesTransferList: [],
        showApprovalTransfer: null,
        hasProfileTransfer: null,
    }),
    [types.SHOW_APPROVAL_TRANSFER_SUCCESS]: (state, action) => {
        const countriesMap = {};
        action.countries.forEach((item) => {
            countriesMap[item.id] = item;
        });

        return {
            ...state,
            isFetchingTransfer: false,
            countriesTransferList: action.countries,
            modifiedTransferCountries: countriesMap,
            showApprovalTransfer: action.showApprovalTransfer,
            hasProfileTransfer: action.hasProfileTransfer,
        };
    },

    [types.SHOW_APPROVAL_TRANSFER_FAILED]: (state) => ({
        ...state,
        isFetchingTransfer: false,
        countriesTransfrList: [],
        showApprovalTransfer: null,
        hasProfileTransfer: null,
    }),

    [types.MODAL_SHOW]: (state, action) => ({
        ...state,
        displayModal: true,
        modalProps: action.modalProps,
        isOpenFromMenu: action.isOpenFromMenu,
    }),
    [types.MODAL_CLOSE]: (state) => ({ ...state, displayModal: false, isOpenFromMenu: false }),

    [types.MODAL_SHOW_MESSAGE]: (state) => ({ ...state, displayModalMessage: true, isOpenFromMenu: false }),
    [types.MODAL_CLOSE_MESSAGE]: (state) => ({ ...state, displayModalMessage: false, isOpenFromMenu: false }),

    [types.MODAL_SHOW_EDIT_PROFILE]: (state) => ({ ...state, displayModalEdit: true, isOpenFromMenu: false }),
    [types.MODAL_CLOSE_EDIT_PROFILE]: (state) => ({ ...state, displayModalEdit: false, isOpenFromMenu: false }),

    [types.RESET_COUNTRY_SELECTED]: (state) => {
        const countriesMap = {};
        state.countriesList.forEach((item) => {
            countriesMap[item.id] = item;
        });
        return {
            ...state,
            modifiedCountries: countriesMap,
        };
    },

    [types.TRANSACTIONAL_COUNTRIES_SETTINGS_REQUEST]: (state) => ({
        ...state,
        isFetching: true,
        countriesList: [],
        hasProfile: false,
        accountSelected: null,
        isAllInCheckedAuthorized: false,
        isAllOutCheckedAuthorized: false,
        isAllInCheckedUnauthorized: false,
        isAllOutCheckedUnauthorized: false,
        accountList: [],
    }),

    [types.TRANSACTIONAL_COUNTRIES_SETTINGS_SUCCESS]: (state, action) => {
        const countriesMap = {};
        action.countries.forEach((item) => {
            countriesMap[item.id] = item;
        });

        return {
            ...state,
            isFetching: false,
            countriesList: action.countries,
            modifiedCountries: countriesMap,
            hasProfile: action.hasProfile,
            accountList: action.accounts,
            accountSelected: action.account,
        };
    },

    [types.TRANSACTIONAL_COUNTRIES_SETTINGS_FAILED]: (state) => ({
        ...state,
        isFetching: false,
        countriesList: [],
        hasProfile: false,
        accountSelected: null,
        accountList: [],
        displayModal: false,
    }),
    [types.TRANSACTIONAL_COUNTRIES_UPDATE_REQUEST]: (state) => ({
        ...state,
        isFetching: true,
    }),

    [types.TRANSACTIONAL_COUNTRIES_UPDATE_SUCCESS]: (state, action) => ({
        ...state,
        isFetching: false,
        countriesList: action.countries,
        hasProfile: action.hasProfile,
    }),

    [types.TRANSACTIONAL_COUNTRIES_UPDATE_FAILED]: (state) => ({
        ...state,
        isFetching: false,
    }),
    [types.TRANSACTIONAL_CREDENTIALS_GROUPS_SUCCESS]: (state, action) => ({
        ...state,
        credentialsGroups: action.credentialsGroups,
        hasCompleteCredentialGroups: true,
    }),
    [types.TRANSACTIONAL_CREDENTIALS_GROUPS_REQUEST]: (state) => ({
        ...state,
        hasCompleteCredentialGroups: false,
    }),
    [types.SET_MODIFIED_COUNTRY]: (state, action) => {
        const newState = { ...state };
        if (!action.value) {
            const { isAuthorized, _type } = action;

            if (isAuthorized) {
                if (_type === "in" && state.isAllInCheckedAuthorized) {
                    newState.isAllInCheckedAuthorized = false;
                } else if (_type === "out" && state.isAllOutCheckedAuthorized) {
                    newState.isAllOutCheckedAuthorized = false;
                }
            } else if (_type === "in" && state.isAllInCheckedUnauthorized) {
                newState.isAllInCheckedUnauthorized = false;
            } else if (_type === "out" && state.isAllOutCheckedUnauthorized) {
                newState.isAllOutCheckedUnauthorized = false;
            }
        }

        const modifiedCountriesMap = {
            ...newState.modifiedCountries,
            [action.countryId]: { ...state.modifiedCountries[action.countryId], [action._type]: action.value },
        };
        return {
            ...newState,
            modifiedCountries: modifiedCountriesMap,
        };
    },
    [types.SET_MODIFIED_COUNTRY_LIST]: (state, action) => {
        let actualCountryList = [];
        let modifiedCountriesList = {};
        if (action.isAuthorized) {
            actualCountryList = state?.countriesList.filter((country) => country.in === false && country.out === false);
        } else {
            actualCountryList = state?.countriesList.filter((country) => country.in === true || country.out === true);
        }
        modifiedCountriesList = Object.values(state.modifiedCountries).map((item) => {
            const actualCountry = actualCountryList.find((el) => el.id === item.id);
            return {
                ...item,
                [action._type]: actualCountry ? item[action._type] : action.value,
            };
        });

        const modifiedCountriesMap = {};
        modifiedCountriesList.forEach((item) => {
            modifiedCountriesMap[item.id] = item;
        });

        return {
            ...state,
            modifiedCountries: modifiedCountriesMap,
        };
    },
    [types.SET_IS_ALL_IN_CHECKED_AUTHORIZED]: (state, action) => ({
        ...state,
        isAllInCheckedAuthorized: action.isAllInCheckedAuthorized,
    }),
    [types.SET_IS_ALL_OUT_CHECKED_AUTHORIZED]: (state, action) => ({
        ...state,
        isAllOutCheckedAuthorized: action.isAllOutCheckedAuthorized,
    }),
    [types.SET_IS_ALL_IN_CHECKED_UNAUTHORIZED]: (state, action) => ({
        ...state,
        isAllInCheckedUnauthorized: action.isAllInCheckedUnauthorized,
    }),
    [types.SET_IS_ALL_OUT_CHECKED_UNAUTHORIZED]: (state, action) => ({
        ...state,
        isAllOutCheckedUnauthorized: action.isAllOutCheckedUnauthorized,
    }),
});

export const actions = {
    transactionalCountriesRequest: makeActionCreator(types.TRANSACTIONAL_COUNTRIES_REQUEST, "accountId", "account"),

    transactionalCountriesRequestSuccess: makeActionCreator(
        types.TRANSACTIONAL_COUNTRIES_SUCCESS,
        "countries",
        "hasProfile",
    ),

    transactionalCountriesRequestFailed: makeActionCreator(types.TRANSACTIONAL_COUNTRIES_FAILED),

    showApprovalTransferRequest: makeActionCreator(types.SHOW_APPROVAL_TRANSFER_REQUEST, "accountId", "handleReset"),

    showApprovalTransferRequestSuccess: makeActionCreator(
        types.SHOW_APPROVAL_TRANSFER_SUCCESS,
        "countries",
        "hasProfile",
        "showApprovalTransfer",
    ),

    showApprovalTransferRequestFailed: makeActionCreator(types.SHOW_APPROVAL_TRANSFER_FAILED),

    transactionalCountriesSettingRequest: makeActionCreator(
        types.TRANSACTIONAL_COUNTRIES_SETTINGS_REQUEST,
        "accountId",
        "onClose",
    ),
    transactionalCountriesSettingSuccess: makeActionCreator(
        types.TRANSACTIONAL_COUNTRIES_SETTINGS_SUCCESS,
        "countries",
        "hasProfile",
        "accounts",
        "account",
    ),
    transactionalCountriesSettingFailed: makeActionCreator(types.TRANSACTIONAL_COUNTRIES_SETTINGS_FAILED),

    transactionalCountriesUpdateRequest: makeActionCreator(
        types.TRANSACTIONAL_COUNTRIES_UPDATE_REQUEST,
        "accountId",
        "countriesList",
        "onFinish",
        "otp",
        "formikBag",
        "isCreate",
        "hasTransactionalProfile",
    ),
    transactionalCountriesUpdateSuccess: makeActionCreator(
        types.TRANSACTIONAL_COUNTRIES_UPDATE_SUCCESS,
        "countries",
        "hasProfile",
    ),
    transactionalCountriesUpdateFailed: makeActionCreator(types.TRANSACTIONAL_COUNTRIES_UPDATE_FAILED),
    transactionalProfileActions: makeActionCreator(types.TRANSACTIONAL_COUNTRIES_UPDATE_FAILED),
    credentialsGroupsRequest: makeActionCreator(types.TRANSACTIONAL_CREDENTIALS_GROUPS_REQUEST, "data"),
    credentialsGroupsSuccess: makeActionCreator(types.TRANSACTIONAL_CREDENTIALS_GROUPS_SUCCESS, "credentialsGroups"),
    modalShow: makeActionCreator(types.MODAL_SHOW, "modalProps", "isOpenFromMenu"),
    modalHide: makeActionCreator(types.MODAL_CLOSE),
    modalMessageShow: makeActionCreator(types.MODAL_SHOW_MESSAGE),
    modalMessageHide: makeActionCreator(types.MODAL_CLOSE_MESSAGE),
    modalEditShow: makeActionCreator(types.MODAL_SHOW_EDIT_PROFILE),
    modalEditHide: makeActionCreator(types.MODAL_CLOSE_EDIT_PROFILE),
    setModifiedCountry: makeActionCreator(types.SET_MODIFIED_COUNTRY, "value", "countryId", "_type", "isAuthorized"),
    setModifiedCountryList: makeActionCreator(types.SET_MODIFIED_COUNTRY_LIST, "_type", "value", "isAuthorized"),
    setIsAllInCheckedAuthorized: makeActionCreator(types.SET_IS_ALL_IN_CHECKED_AUTHORIZED, "isAllInCheckedAuthorized"),
    setIsAllOutCheckedAuthorized: makeActionCreator(
        types.SET_IS_ALL_OUT_CHECKED_AUTHORIZED,
        "isAllOutCheckedAuthorized",
    ),
    setIsAllInCheckedUnauthorized: makeActionCreator(
        types.SET_IS_ALL_IN_CHECKED_UNAUTHORIZED,
        "isAllInCheckedUnauthorized",
    ),
    setIsAllOutCheckedUnauthorized: makeActionCreator(
        types.SET_IS_ALL_OUT_CHECKED_UNAUTHORIZED,
        "isAllOutCheckedUnauthorized",
    ),
    resetCountrySelected: makeActionCreator(types.RESET_COUNTRY_SELECTED),
};

export const selectors = {
    getFetching: ({ transactionalProfile }) => transactionalProfile.isFetching,
    getCountriesList: ({ transactionalProfile }) => transactionalProfile.countriesList,
    getModifiedCountries: ({ transactionalProfile }) => transactionalProfile.modifiedCountries,
    getSelectedCountryId: ({ transactionalProfile }) => transactionalProfile.modalProps?.countryId,
    getHasProfile: ({ transactionalProfile }) => transactionalProfile.hasProfile,
    getAccount: ({ transactionalProfile }) => transactionalProfile.accountSelected,
    getAccounts: ({ transactionalProfile }) => transactionalProfile.accountList,
    getCredentialsGroups: ({ transactionalProfile }) => transactionalProfile.credentialsGroups,
    hasCompleteCredentialGroups: ({ transactionalProfile }) => transactionalProfile.hasCompleteCredentialGroups,
    getDisplayModal: ({ transactionalProfile }) => transactionalProfile?.displayModal,
    getModalProps: ({ transactionalProfile }) => transactionalProfile?.modalProps,
    getIsOpenFromMenu: ({ transactionalProfile }) => transactionalProfile?.isOpenFromMenu,
    getDisplayModalMessage: ({ transactionalProfile }) => transactionalProfile?.displayModalMessage,
    getDisplayModalEdit: ({ transactionalProfile }) => transactionalProfile?.displayModalEdit,
    getIsAllInCheckedAuthorized: ({ transactionalProfile }) => transactionalProfile.isAllInCheckedAuthorized,
    getIsAllOutCheckedAuthorized: ({ transactionalProfile }) => transactionalProfile.isAllOutCheckedAuthorized,
    getIsAllInCheckedUnauthorized: ({ transactionalProfile }) => transactionalProfile.isAllInCheckedUnauthorized,
    getIsAllOutCheckedUnauthorized: ({ transactionalProfile }) => transactionalProfile.isAllOutCheckedUnauthorized,

    getFetchingTransfer: ({ transactionalProfile }) => transactionalProfile.isFetchingTransfer,
    getCountriesTransferList: ({ transactionalProfile }) => transactionalProfile.countriesTransferList,
    getShowApprovalTransfer: ({ transactionalProfile }) => transactionalProfile.showApprovalTransfer,
};
