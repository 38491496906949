import { USD_CURRENCY } from "constants.js";
import { Field } from "formik";
import moment from "moment";
import * as FormFieldsComponents from "pages/forms/_components/_fields/Index";
import Accordion from "pages/_components/Accordion";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import FieldLabel from "pages/_components/fields/FieldLabel";
import FormattedAmount from "pages/_components/FormattedAmount";
import CurrencyModal from "pages/_components/modal/CurrencyModal";
import { resizableRoute } from "pages/_components/Resizable";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import Image from "pages/_components/Image";
import Tooltip from "pages/_components/Tooltip";
import ConfirmDialog from "pages/_components/modal/ConfirmDialog";
import RadioButtonGroup from "pages/forms/_components/_fields/_commons/RadioButtonGroup";
import useAsyncTicketProcessing from "hooks/useAsyncTicketProcessing";
import { connect } from "react-redux";
import { bool, func, shape, string } from "prop-types";
import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/lib/Col";
import { calculateShortLabel } from "util/account";
import * as configUtils from "util/config";
import * as i18n from "util/i18n";
import { numberFormat } from "util/number";
import { selectors as sessionSelectors } from "reducers/session";
import { actions as notificationActions } from "reducers/notification";
import {
    actions as transactionalProfileActions,
    selectors as transactionalProfileSelectors,
} from "reducers/transactionalProfile";
import { generateExchangeRateLabel } from "../hooks/TransferCustomHooks";
import InfoExchangeRates from "../_customFields/InfoExchangeRates";
import SupportFiles from "./SupportFiles";

/**
 *
 * @returns
 */
const FromComponent = ({
    genericProps,
    preData,
    debitAccountOrigin,
    values,
    mode,
    transaction,
    currentLang,
    idForm,
    exchageData,
    setExchangeData,
    currentAmount,
    currentDebitAccount,
    setFieldValueCustom,
    isDesktop,
    previewData,
    commission,
    setCommission,
    tax,
    setTax,
    totalAmountEstimated,
    setTotalAmountEstimated,
    dispatch,
    fetchingTransactionalProfile,
    estimatedAmount,
    setEstimatedAmount,
    isAdministrator,
    activeEnvironment,
    ratesUrl,
    isOpenModal,
    isOpenEditModal,
    countriesList,
    bankSelectedCountryId,
}) => {
    const [isOpenCurrencyModal, setIsOpenCurrencyModal] = useState(false);
    const [TooltipProcessingComponent] = useAsyncTicketProcessing(transaction);
    const [selectedCountry, setSelectedCountryId] = useState(null);
    const { decimalSeparator, thousandSeparator } = numberFormat();
    const reasonTransferArray =
        preData?.descriptionList?.map((item) => ({
            id: item?.id || "",
            label: item?.label || "",
        })) || [];

    const dataAmount = {
        decimalSeparator,
        precision: 2,
        thousandsSeparator: thousandSeparator,
        options:
            preData?.currencyList?.map((c) => ({
                id: c.backendId,
                label: c.code,
            })) || [],
    };

    const selectorOptions = {
        options: preData?.debitAccountList?.map((acc) => ({
            ...acc,
            balance: { currency: acc.currency, quantity: acc.balance },
            id: acc.idProduct,
            disabled: acc.isDisabled,
        })),
    };

    const calculateRateComponent = () => {
        if (!currentAmount || !currentDebitAccount || !preData || !preData.ExchangeRateList) {
            setExchangeData(undefined);
            return;
        }
        const { currency: currencyOriginExchange } = currentDebitAccount;
        const { currency: currencyAmountExchange, quantity: valueAmount } = currentAmount;
        setExchangeData(
            currencyOriginExchange,
            currencyAmountExchange,
            currencyAmountExchange,
            valueAmount,
            preData.ExchangeRateList,
        );
    };

    const handleCountryChange = (selectedCountryId, countriesExcluded) => {
        const isCountryExcluded = countriesExcluded.some(
            (item) => item.toLowerCase() === selectedCountryId?.toLowerCase(),
        );
        if (isCountryExcluded) {
            dispatch(
                notificationActions.showNotification(
                    i18n.get("transactional.country.blocked"),
                    "error",
                    ["form"],
                    true,
                ),
            );
            return true;
        }

        const country = countriesList.find((element) => element.id === selectedCountryId);
        setSelectedCountryId(country);

        if (values?.profileCountryAdd !== selectedCountryId) {
            setFieldValueCustom("profileCountryAdd", null);
            setFieldValueCustom("profileCountryAddLabel", null);
        }
        if (
            country &&
            !country.out &&
            (!values?.profileCountryAdd || values?.profileCountryAdd !== selectedCountryId)
        ) {
            dispatch(transactionalProfileActions.modalEditShow());
        }
        return false;
    };

    useEffect(() => {
        if (!values?.debitAccount || fetchingTransactionalProfile) {
            return;
        }
        if (bankSelectedCountryId && mode === "edit") {
            const countriesExcluded = configUtils.getArray("transfers.foreign.excluded.countries");
            handleCountryChange(bankSelectedCountryId, countriesExcluded);
        }
    }, [bankSelectedCountryId, fetchingTransactionalProfile]);

    /**
     * Observe changes on amount or debit account or creditaccount and calculates rate.
     */
    useEffect(() => {
        calculateRateComponent();
    }, [currentAmount, currentDebitAccount]);

    /**
     * Observe changes rate and save it on values request.
     */
    useEffect(() => {
        if (setFieldValueCustom) {
            setFieldValueCustom("exchangeData", exchageData);
        }
    }, [exchageData]);

    useEffect(() => {
        if (setFieldValueCustom && previewData) {
            setTax({ currency: previewData?.tax?.currency, quantity: previewData?.tax?.quantity });
            setCommission({ currency: previewData?.commission?.currency, quantity: previewData?.commission?.quantity });
            setTotalAmountEstimated({
                currency: previewData?.totalAmountEstimated?.currency,
                quantity: previewData?.totalAmountEstimated?.quantity,
            });
            setEstimatedAmount({
                currency: previewData?.estimatedAmount?.currency,
                quantity: previewData?.estimatedAmount?.quantity,
            });
        }
    }, [previewData]);

    const isLocalCurrency = (amount) => amount && amount.currency && amount.currency === USD_CURRENCY;

    const handleOpenModal = () => {
        setIsOpenCurrencyModal(true);
    };

    const handleReset = () => {
        setFieldValueCustom("profileCountryAdd", null);
        setFieldValueCustom("profileCountryAddLabel", null);
        setFieldValueCustom("showApprovalTransfer", null);
    };

    const hasTransactionalProfile = isAdministrator || activeEnvironment.permissions.transactionalProfile;

    const renderFromDetail = () => (
        <Box display="flex" fullWidth column>
            {mode === "view" && (
                <FormFieldsComponents.ReadTextCustom
                    {...genericProps}
                    value={i18n.get("forms.transfers.foreign.operationType.foreignTransfer")}
                    label="forms.transfers.foreign.operationType.label_preview"
                />
            )}
            <FormFieldsComponents.ReadTextCustom
                {...genericProps}
                value={calculateShortLabel(values?.debitAccountData?.productType, values?.debitAccountData?.number)}
                label="forms.transfers.foreign.debitAccount.label_preview"
            />
            <FormFieldsComponents.ReadTextCustom
                {...genericProps}
                value={values?.debitReference && values?.debitReference[0]?.label ? values.debitReference[0].label : ""}
                label="forms.transfers.foreign.debitReference.label_preview"
            />
            {values && values.reason && (
                <FormFieldsComponents.ReadTextCustom
                    {...genericProps}
                    value={values.reason}
                    label="forms.transfers.foreign.reason.label_preview"
                />
            )}
            {values?.exchangeData && (
                <FormFieldsComponents.ReadTextCustom
                    {...genericProps}
                    value={generateExchangeRateLabel(values?.exchangeData)}
                    label="forms.transfers.foreign.balance.label_preview"
                />
            )}

            {mode === "view" && (
                <div className="data-wrapper">
                    <FieldLabel
                        labelText={i18n.get("forms.transfers.foreign.totalAmount.abroad.label_preview")}
                        uppercase
                        optional=""
                        labelNoMarginTop
                    />
                    <Box display="flex" alignY="center">
                        <FormattedAmount
                            size="5"
                            className="data-amount content-data-strong"
                            currency={transaction?.data?.estimatedAmount?.currency || totalAmountEstimated.currency}
                            quantity={transaction?.data?.estimatedAmount?.quantity || totalAmountEstimated.quantity}
                        />
                        <Tooltip forEllipsis text="transferForeing.tooltip.message" />
                    </Box>
                </div>
            )}

            {mode === "preview" && previewData?.estimatedAmount && (
                <div className="data-wrapper">
                    <FieldLabel
                        labelText={i18n.get(
                            isLocalCurrency(values.amount)
                                ? "forms.transfers.foreign.amount.label_preview"
                                : "forms.transfers.foreign.labelAmountEstimate.label",
                        )}
                        optional=""
                        labelNoMarginTop
                    />
                    <FormattedAmount
                        size="5"
                        className="data-amount content-data-strong"
                        currency={estimatedAmount.currency || ""}
                        quantity={estimatedAmount.quantity || 0}
                    />
                </div>
            )}
            {mode === "preview" && previewData?.commission && (
                <div className="data-wrapper">
                    <FieldLabel
                        labelText={i18n.get("forms.transfers.foreign.commission.label_preview")}
                        optional=""
                        labelNoMarginTop
                    />
                    <FormattedAmount
                        size="5"
                        className="data-amount content-data-strong"
                        currency={commission.currency || ""}
                        quantity={commission.quantity || 0}
                    />
                </div>
            )}
            {mode === "preview" && previewData?.tax && (
                <div className="data-wrapper">
                    <FieldLabel
                        labelText={i18n.get("forms.transfers.foreign.tax.label_preview", null, {
                            TAX: configUtils.get("transfer.foreign.tax.percent"),
                        })}
                        optional=""
                        labelNoMarginTop
                    />
                    <FormattedAmount
                        size="5"
                        className="data-amount content-data-strong"
                        currency={tax.currency || ""}
                        quantity={tax.quantity || 0}
                    />
                </div>
            )}
            {mode === "preview" && previewData?.totalAmountEstimated && (
                <>
                    <Box className="data-wrapper pt-md-5 mt-md-2" {...(isDesktop && { border: "top-heading-color-1" })}>
                        <FieldLabel
                            labelText={i18n.get(
                                isLocalCurrency(values.amount)
                                    ? "forms.transfers.foreign.totalAmount.label_preview"
                                    : "forms.transfers.foreign.totalAmount.abroad.label_preview",
                            )}
                            optional=""
                            labelNoMarginTop
                            uppercase
                        />
                        <FormattedAmount
                            size="5"
                            className="data-amount content-data-strong"
                            currency={totalAmountEstimated.currency || ""}
                            quantity={totalAmountEstimated.quantity || 0}
                        />
                    </Box>
                    <Box>
                        <Button
                            bsStyle="link"
                            className="link-styled"
                            secondary
                            small
                            externalHref={ratesUrl}
                            label="transfer.foreign.international.tariff.url.text"
                        />
                    </Box>
                </>
            )}
        </Box>
    );

    const getDateField = () => {
        try {
            if (mode === "preview") {
                return moment().format("DD/MM/YYYY");
            }
            const date = transaction?.idParentTransaction ? transaction?.submitDateTime : transaction?.creationDateTime;
            return date ? moment(date).format("DD/MM/YYYY") : "-";
        } catch (e) {
            return "-";
        }
    };

    const renderDateField = () => (
        <FormFieldsComponents.ReadTextCustom
            {...genericProps}
            value={getDateField()}
            label="forms.transfers.foreign.date.label_preview"
        />
    );
    const renderAmountField = () => (
        <Box display="flex" alignX="center" fullWidth className="amount-wrapper">
            <Field
                {...genericProps}
                component={FormFieldsComponents.Amount}
                data={dataAmount}
                key="amount"
                name="amount"
                value={values.amount}
                idField="amount"
                bigDataAmount
                label_viewMap={
                    transaction?.idTransactionStatus === "FINISHED"
                        ? { [currentLang]: i18n.get(`forms.${idForm}.amount.label_view`) }
                        : { [currentLang]: i18n.get(`forms.${idForm}.amount.label`) }
                }
            />
        </Box>
    );

    const renderViewMode = () => (
        <>
            {renderAmountField()}
            {TooltipProcessingComponent}
            <Box className="ticket-info-wrapper mb-3 mb-md-0">
                {mode === "view" && transaction && transaction?.data?.backendReference && (
                    <FormFieldsComponents.ReadTextCustom
                        {...genericProps}
                        value={transaction?.data?.backendReference}
                        label="forms.transfers.foreign.referenceTrx.label_preview"
                    />
                )}
                {renderDateField("view")} {renderFromDetail()}
            </Box>
        </>
    );

    const renderPreviewMode = () => (
        <>
            {values?.debitAccountData?.currency === "EUR" && (
                <Box display="flex" alignX="flex-end" className="mb-3" position="absolute" top="currency-Btn" right="0">
                    <Button
                        bsStyle="link"
                        className="ml-auto"
                        onClick={handleOpenModal}
                        label="currency.exchangeRate.btn"
                        small
                    />
                </Box>
            )}
            <Box display="flex" alignX="center" fullWidth className="amount-wrapper">
                {renderAmountField()}
            </Box>
            <Box className="ticket-info-wrapper">
                {renderDateField("preview")}
                {isDesktop ? (
                    <Accordion grayBox defaultOpenItem={0}>
                        <Accordion.Item title={`forms.${idForm}.fromComponent.title`} number={0}>
                            {renderFromDetail()}
                        </Accordion.Item>
                    </Accordion>
                ) : (
                    <>
                        <Text
                            component="h4"
                            color="primary"
                            size="5"
                            bold
                            labelKey={`forms.${idForm}.fromComponent.title`}
                            className="mt-0 mb-4"
                        />
                        {renderFromDetail()}
                    </>
                )}
            </Box>
            {values?.activeSupportFiles === "true" && (
                <Col xs={12} md={12}>
                    <Field
                        {...genericProps}
                        component={SupportFiles}
                        name="_supportFiles"
                        idField="_supportFiles"
                        ID_FORM="transfers.foreign"
                        mode={mode}
                        isDesktop={isDesktop}
                        supportDocuments={values?._supportFiles || []}
                    />
                </Col>
            )}
            <CurrencyModal
                show={isOpenCurrencyModal}
                list={preData.ExchangeRateList || []}
                onClose={() => {
                    setIsOpenCurrencyModal(false);
                }}
            />
        </>
    );

    return (
        <>
            {mode === "edit" && (
                <Box
                    background="white"
                    className="pt-5 pt-md-7 pl-5 pl-lg-10 pr-5 pr-lg-10 pb-5 pb-md-8 mx-n-5 mb-7"
                    borderRadius="default">
                    <Row>
                        <Box display="flex" alignY="center" fullWidth>
                            <Text size="4" color="primary" bold className="mr-2">
                                {i18n.get(`forms.${idForm}.fromComponent.title`)}
                            </Text>
                        </Box>

                        <Col xs={12} md={6}>
                            <Box position="relative">
                                <Field
                                    {...genericProps}
                                    component={FormFieldsComponents.ProductselectorCustom}
                                    data={selectorOptions || []}
                                    key="debitAccount"
                                    name="debitAccount"
                                    renderAs="combo"
                                    labelNoMarginTop
                                    value={debitAccountOrigin || ""}
                                    idField="debitAccount"
                                    loadBalance
                                    withSingleOption={false}
                                    onChange={() => {
                                        if (values.debitAccount !== currentDebitAccount?.idProduct) {
                                            dispatch(
                                                transactionalProfileActions.showApprovalTransferRequest(
                                                    values.debitAccount,
                                                    handleReset,
                                                ),
                                            );
                                        }
                                    }}
                                />
                                {fetchingTransactionalProfile && (
                                    <Box
                                        display="flex"
                                        alignY="center"
                                        alignX="flex-start"
                                        className="pt-7 px-2"
                                        fullHeight
                                        fullWidth
                                        position="absolute"
                                        left="0"
                                        top="0">
                                        <Button loading bsStyle="link" className="min-width-12" />
                                        <Box
                                            display="flex"
                                            alignY="center"
                                            alignX="center"
                                            className="min-height-7 min-width-12 background-loading-in-select"
                                            fullWidth
                                            position="absolute"
                                            borderRadius="form"
                                            background="component-background"
                                            top="8"
                                            left="0"
                                        />
                                    </Box>
                                )}
                            </Box>
                        </Col>
                        <Col xs={12} md={6}>
                            <Field
                                {...genericProps}
                                component={FormFieldsComponents.Selector}
                                key="debitReference"
                                name="debitReference"
                                idField="debitReference"
                                labelIdField="react-select-debitReference-input"
                                tooltipText={`forms.${idForm}.debitReference.tooltip`}
                                optionList={reasonTransferArray}
                                renderAs="combo"
                                labelNoMarginTop
                                isFocused={false}
                            />
                        </Col>
                        <Col xs={12} md={6}>
                            <Field
                                {...genericProps}
                                component={FormFieldsComponents.Amount}
                                data={dataAmount}
                                key="amount"
                                name="amount"
                                value={
                                    preData.currencyList && preData.currencyList[0]
                                        ? { ...preData.currencyList[0], currency: preData.currencyList[0].backendId }
                                        : {}
                                }
                                idField="amount"
                                customPlaceholderCurrency=""
                                customPlaceholderQuantity="0.00"
                                quantityTextAlign="text-left"
                                tooltip={
                                    <Text
                                        color="inverse"
                                        size="7"
                                        labelKey="forms.transfers.foreign.field.amount.tooltip"
                                    />
                                }
                                tooltipPosition="bottom-left"
                            />
                        </Col>
                        <Col xs={12} md={6}>
                            <Field
                                {...genericProps}
                                component={FormFieldsComponents.Text}
                                key="reason"
                                name="reason"
                                idField="reason"
                                isRequired={false}
                                validationRegularExpresion="^[a-zA-Z0-9 ]*$"
                                optional={i18n.get("form.field.optional")}
                                labelNoMarginTop
                                maxLength={
                                    configUtils.getInteger("form.transfers.foreign.field.reason.maxLength") || 35
                                }
                            />
                        </Col>
                        <Box>
                            <Row alignY="center">
                                {exchageData && (
                                    <>
                                        <Col xs={12} md={6}>
                                            <InfoExchangeRates
                                                labelAmountEstimate="forms.transfers.foreign.labelAmountEstimate.label"
                                                amountEstimate={exchageData.estimatedAmount || 0}
                                                exchangeRateEstimate={generateExchangeRateLabel(exchageData)}
                                                currencyEstimate={exchageData.currencyEstimate}
                                                twoInlineLayout
                                            />
                                        </Col>
                                    </>
                                )}
                                {values?.debitAccountData?.currency === "EUR" &&
                                    values?.amount?.currency !== "EUR" &&
                                    values?.amount?.currency !== "USD" && (
                                        <>
                                            <Col xs={6} md={6}>
                                                <Button
                                                    bsStyle="link"
                                                    onClick={handleOpenModal}
                                                    label="currency.exchangeRate.btn"
                                                />
                                            </Col>
                                        </>
                                    )}
                            </Row>
                        </Box>
                        {/** Support files */}
                        <Box>
                            <Row alignY="center">
                                <Col xs={12} md={6}>
                                    <Box display="block" className="my-auto pb-5">
                                        <Text
                                            align="left"
                                            color="text-boton"
                                            labelKey="forms.transfers.foreign.activeSupportFiles.label"
                                            className="data-label data-label-no-margin-top"
                                        />
                                        <Tooltip text="forms.transfers.foreign.activeSupportFiles.info" />
                                        <Box display="block" className="my-auto pt-5">
                                            <RadioButtonGroup
                                                inLineControl={false}
                                                name="activeSupportFiles"
                                                onChange={(e) => {
                                                    setFieldValueCustom("activeSupportFiles", e);
                                                }}
                                                selectorId="activeSupportFiles"
                                                options={[
                                                    {
                                                        id: "true",
                                                        label: i18n.get(
                                                            "forms.transfers.foreign.activeSupportFiles.yes",
                                                        ),
                                                    },
                                                    {
                                                        id: "false",
                                                        label: i18n.get(
                                                            "forms.transfers.foreign.activeSupportFiles.no",
                                                        ),
                                                    },
                                                ]}
                                                value={values?.activeSupportFiles}
                                                radioButtonGroupFlex
                                            />
                                        </Box>
                                    </Box>
                                </Col>

                                {values?.activeSupportFiles === "true" && (
                                    <Col xs={12} md={12}>
                                        <Row>
                                            <Col xs={12} md={6} mdOffset={3}>
                                                <Field
                                                    {...genericProps}
                                                    component={SupportFiles}
                                                    name="_supportFiles"
                                                    idField="_supportFiles"
                                                    ID_FORM="transfers.foreign"
                                                    mode={mode}
                                                    isDesktop={isDesktop}
                                                    supportDocuments={values?._supportFiles || []}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                )}
                            </Row>
                        </Box>
                    </Row>
                    <CurrencyModal
                        show={isOpenCurrencyModal}
                        list={preData.ExchangeRateList || []}
                        onClose={() => {
                            setIsOpenCurrencyModal(false);
                        }}
                    />
                    {/** Modal de declaracion de transferencia internacional */}
                    <ConfirmDialog
                        showDialog={isOpenModal}
                        title={
                            hasTransactionalProfile
                                ? i18n.get("transactional.profile.modal.allows.international.transfer.title")
                                : ""
                        }
                        description={
                            hasTransactionalProfile
                                ? i18n.get("transactional.profile.modal.allows.international.transfer.description")
                                : i18n.get(
                                      "transactional.profile.modal.no.country.founded.withoutpermissions.description",
                                  )
                        }
                        headingSize={4}
                        onConfirm={() => {
                            setFieldValueCustom("showApprovalTransfer", hasTransactionalProfile ? "1" : null);
                            dispatch(transactionalProfileActions.modalMessageHide());
                        }}
                        onCancel={() => {
                            setFieldValueCustom("showApprovalTransfer", null);
                            setFieldValueCustom("debitAccount", null);
                            setFieldValueCustom("debitAccountData", null);
                            dispatch(transactionalProfileActions.modalMessageHide());
                        }}
                        modalId="transactional.profile.modal.allows.international.transfer"
                        loading={false}
                        confirmLabel="global.approve"
                    />

                    {/** Modal de actualizacion de perfil internacional */}
                    <ConfirmDialog
                        showDialog={isOpenEditModal}
                        description={
                            hasTransactionalProfile
                                ? i18n.get("transactional.profile.modal.edit.description")
                                : i18n.get(
                                      "transactional.profile.modal.no.country.founded.withoutpermissions.description",
                                  )
                        }
                        onConfirm={() => {
                            setFieldValueCustom(
                                "profileCountryAdd",
                                hasTransactionalProfile ? selectedCountry?.id : null,
                            );
                            setFieldValueCustom(
                                "profileCountryAddLabel",
                                hasTransactionalProfile ? selectedCountry?.label : null,
                            );
                            dispatch(transactionalProfileActions.modalEditHide());
                        }}
                        onCancel={() => dispatch(transactionalProfileActions.modalEditHide())}
                        modalId="transactional.profile.modal.edit"
                        loading={false}
                        confirmLabel="global.confirm">
                        {hasTransactionalProfile && (
                            <Box display="flex" alignX="center" className="pt-6 px-8">
                                <Box className="btn py-0 btn-big btn-block" fitWidth>
                                    <Box display="flex" className="pr-2">
                                        <Image
                                            src={`images/Flags/${selectedCountry?.id?.toLowerCase()}.svg`}
                                            width="5"
                                            height="5"
                                        />
                                    </Box>
                                    <Text size="6" ellipsis>
                                        {selectedCountry?.label}
                                    </Text>
                                </Box>
                            </Box>
                        )}
                    </ConfirmDialog>
                </Box>
            )}

            {mode === "preview" && renderPreviewMode()}
            {mode === "view" && renderViewMode()}
        </>
    );
};

FromComponent.propTypes = {
    genericProps: shape({}).isRequired,
    preData: shape({}).isRequired,
    debitAccountOrigin: string.isRequired,
    values: shape({}).isRequired,
    mode: string.isRequired,
    transaction: shape({}).isRequired,
    currentLang: string.isRequired,
    idForm: string.isRequired,
    setExchangeData: func.isRequired,
    exchageData: shape({}).isRequired,
    currentAmount: shape({}).isRequired,
    currentDebitAccount: shape({}).isRequired,
    currentCreditAccount: shape({}).isRequired,
    setFieldValueCustom: func.isRequired,
    isDesktop: bool,
    previewData: shape({}).isRequired,
    setCommission: func.isRequired,
    commission: shape({}).isRequired,
    setTax: func.isRequired,
    tax: shape({}).isRequired,
    setTotalAmountEstimated: func.isRequired,
    totalAmountEstimated: shape({}).isRequired,
    estimatedAmount: shape({}).isRequired,
    setEstimatedAmount: func.isRequired,
    ratesUrl: shape({}).isRequired,
    dispatch: func.isRequired,
    countriesList: shape([]),
    fetchingTransactionalProfile: bool,
    isAdministrator: bool,
    activeEnvironment: shape({ type: string }).isRequired,
    isOpenModal: bool.isRequired,
    isOpenEditModal: bool.isRequired,
    accountTransactionalProfile: shape({}),
    bankSelectedCountryId: string,
};

FromComponent.defaultProps = {
    isDesktop: true,
    countriesList: [],
    fetchingTransactionalProfile: false,
    isAdministrator: false,
    accountTransactionalProfile: {},
    bankSelectedCountryId: null,
};

const mapStateToProps = (state) => ({
    countriesList: transactionalProfileSelectors.getCountriesTransferList(state),
    fetchingTransactionalProfile: transactionalProfileSelectors.getFetchingTransfer(state),
    isAdministrator: sessionSelectors.isAdministrator(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    accountTransactionalProfile: transactionalProfileSelectors.getAccount(state),
    isOpenModal: transactionalProfileSelectors.getDisplayModalMessage(state),
    isOpenEditModal: transactionalProfileSelectors.getDisplayModalEdit(state),
});

export default connect(mapStateToProps)(resizableRoute(FromComponent));
