import * as supportTransferApi from "middleware/transferSupport/transferSupport.middleware";
import { call, put, takeLatest } from "redux-saga/effects";
import {
    types as transferSupportTypes,
    actions as transferSupportActions,
} from "reducers/transferSupport/transferSupport.reducer";
import { actions as notificationActions } from "reducers/notification";
import * as i18n from "util/i18n";
import { TRANSFER_SUPPORT_AMENDMENT_TYPE, TRANSFER_SUPPORT_CONFIRM_TYPE } from "util/transferSupport.util";

function* showGenericErrorTransferList(errorMessage) {
    yield put({ type: transferSupportTypes.GET_TRANSFER_LIST_FAILURE });
    yield put(
        notificationActions.showNotification(
            i18n.get(errorMessage || "transfers.foreign.support.list.default.error.message"),
            "error",
            ["InternationalTransferTracking"],
            false,
        ),
    );
}

function* getTransferList() {
    const responseApi = yield call(supportTransferApi.getTransferList);
    if (!responseApi?.type || !responseApi?.data?.data) {
        yield call(showGenericErrorTransferList);
        yield;
        return;
    }

    const { type, data } = responseApi;
    if (type === "W") {
        // TODO: custom message error
        yield call(showGenericErrorTransferList);
        yield;
        return;
    }

    const { transactionList = [], notification } = data.data;
    const transferMapperList = transactionList.map((item) => ({
        date: item?.creationDateTime,
        recipient: item?.data?.beneficiary?.name || "-",
        amount: item?.data?.amount?.quantity,
        currency: item?.data?.amount?.currency,
        tracking: item?.data?.backendReference || "-",
        accountLabel: item?.data?.debitAccountData?.otherLabel || "-",
        debitAccount: item?.data?.debitAccount,
        transactionId: item?.idTransaction,
        originalData: item?.data,
        idTransactionStatus: item?.idTransactionStatus,
        wasConfirmed: item?.data?.wasConfirmed,
        wasAmmended: item?.data?.wasAmmended,
    }));

    if (notification) {
        yield put(
            notificationActions.showNotification(notification, "warning", ["InternationalTransferTracking"], false),
        );
    }
    yield put({ type: transferSupportTypes.GET_TRANSFER_LIST_SUCCESS, transferList: transferMapperList });
}

function* showGenericErrorSendSupportReq({ callbackError, errorMessage, scopeError }) {
    yield put({ type: transferSupportTypes.SEND_SUPPORT_TRANSFER_REQUEST_FAILURE });
    yield put(
        notificationActions.showNotification(
            i18n.get(errorMessage || "transfers.foreign.support.request.default.error.message"),
            "error",
            scopeError || ["InternationalTransferTracking"],
            false,
        ),
    );
    if (callbackError) {
        callbackError();
    }
}

function* sendSupportRequest({
    supportRequestType,
    transactionIdentifier,
    accountId,
    beneficiaryData,
    callback,
    callbackError,
    scopeError,
}) {
    const responseApi = yield call(
        supportTransferApi.sendSupportRequest,
        supportRequestType,
        transactionIdentifier,
        accountId,
        beneficiaryData,
    );

    if (!responseApi?.type || !responseApi?.data?.data) {
        yield call(showGenericErrorSendSupportReq, { callbackError, scopeError });
        yield;
        return;
    }

    const { type, data } = responseApi;
    if (type === "W") {
        // TODO: custom message error
        yield call(showGenericErrorSendSupportReq, { callbackError, scopeError });
        yield;
        return;
    }

    const { procedureNumber } = data.data;
    if (!procedureNumber || procedureNumber === "") {
        yield call(showGenericErrorSendSupportReq, { callbackError, scopeError });
        yield;
        return;
    }

    if (
        supportRequestType === TRANSFER_SUPPORT_CONFIRM_TYPE ||
        supportRequestType === TRANSFER_SUPPORT_AMENDMENT_TYPE
    ) {
        yield put(transferSupportActions.sendTransferSupportSuccess(transactionIdentifier, supportRequestType));
        yield put(transferSupportActions.setShowSupportTransferWarningModal(false));
    } else {
        yield put({ type: transferSupportTypes.SEND_SUPPORT_TRANSFER_REQUEST_SUCCESS });
    }

    if (callback) {
        callback(procedureNumber);
    }
}

const sagas = [
    takeLatest(transferSupportTypes.GET_TRANSFER_LIST_REQUEST, getTransferList),
    takeLatest(transferSupportTypes.SEND_SUPPORT_TRANSFER_REQUEST, sendSupportRequest),
];
export default sagas;
