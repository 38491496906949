import React from "react";
import classNames from "classnames";
import Box from "pages/_components/Box";
import Row from "pages/_components/Row";
import Col from "react-bootstrap/lib/Col";
import Text from "pages/_components/Text";
import { bool, string } from "prop-types";

/**
 * * Puede considerarse ubicarlo en sitios diferentes para bloquear toda o parte de la app
 */

const Spinner = () => (
    <div className="widget-preloader no-background min-height-2rem span-color">
        <div>
            <span />
            <span />
            <span />
        </div>
    </div>
);

const PageLoadingInfo = ({ infoTitle, infoDescription, viewLoading }) => (
    <Box className="form-confirmation-wrapper">
        <Row {...{ heightAuto: true }} className="mb-8">
            <Col xs={12} md={8} mdOffset={2}>
                <Box background="white" borderRadius="xxl" className={classNames("form-confirmation-box")}>
                    <Box display="flex" column alignX="center" alignY="center" className="pt-10 pb-10 px-8">
                        <Box display="flex" alignX="center" className="mt-1">
                            <Text bold size="16" labelKey={infoTitle} />
                        </Box>

                        <Box display="flex" alignX="center" className="mt-4">
                            <Text size="12" align="center" labelKey={infoDescription} />
                        </Box>
                        {viewLoading && <Box className="mt-7">{Spinner()}</Box>}
                    </Box>
                </Box>
            </Col>
        </Row>
    </Box>
);

PageLoadingInfo.propTypes = {
    infoTitle: string,
    infoDescription: string,
    viewLoading: bool,
};

PageLoadingInfo.defaultProps = {
    infoTitle: "",
    infoDescription: "",
    viewLoading: true,
};

export default PageLoadingInfo;
