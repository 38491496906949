import * as API from "middleware/api";

export const getTransferList = (idAccount) =>
    API.executeWithAccessToken("/support.transfer.foreign.relation", { idAccount });

export const sendUploadSupportRequest = (idAccount, transferFT, supportFiles, reason, relation) =>
    API.executeWithAccessToken("/support.transfer.foreign.received.send", {
        idAccount,
        transferFT,
        _supportFiles: supportFiles,
        reason,
        relation,
    });
