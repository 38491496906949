import Box from "pages/_components/Box/Box";
import Text from "pages/_components/Text";
import Image from "pages/_components/Image";
import React from "react";
import { string, shape } from "prop-types";

const TransactionalProfileInfo = ({ ID_FORM, values }) => (
    <Box display="block" alignX="center" fullWidth>
        <Box display="flex" fullWidth className="my-6" alignX="center">
            <Text
                bold
                align="center"
                labelKey={
                    values?.showApprovalTransfer === "1" && values?.profileCountryAdd
                        ? `forms.${ID_FORM}.showApprovalTransfer.title`
                        : `forms.${ID_FORM}.profileCountryAdd.title`
                }
            />
        </Box>
        <Box display="flex" fullWidth className="mb-6">
            <Text
                align="center"
                labelKey={
                    values?.showApprovalTransfer === "1" && values?.profileCountryAdd
                        ? `forms.${ID_FORM}.showApprovalTransfer.description`
                        : `forms.${ID_FORM}.profileCountryAdd.description`
                }
            />
        </Box>
        {values?.profileCountryAdd && (
            <Box display="flex" alignX="center">
                <Box display="flex" alignX="center" className="pt-6 px-8">
                    <Box className="btn py-0 btn-big btn-block" fitWidth>
                        <Box display="flex" className="pr-2">
                            <Image
                                src={`images/Flags/${values?.profileCountryAdd?.toLowerCase()}.svg`}
                                width="5"
                                height="5"
                            />
                        </Box>
                        <Text size="6" ellipsis>
                            {values?.profileCountryAddLabel}
                        </Text>
                    </Box>
                </Box>
            </Box>
        )}
    </Box>
);

TransactionalProfileInfo.propTypes = {
    ID_FORM: string.isRequired,
    values: shape({}),
};

TransactionalProfileInfo.defaultProps = {
    values: {},
};
export default TransactionalProfileInfo;
