import * as config from "util/config";
import * as i18n from "util/i18n";

const ID_FORM = "transfers.foreign";

const readFileAsDataUrl = async (fileData) => {
    const fileReader = new FileReader();

    return new Promise((resolve, reject) => {
        fileReader.onload = (fileLoadedEvent) => {
            resolve(fileLoadedEvent.target.result);
        };

        fileReader.onerror = (error) => {
            reject(error);
        };

        fileReader.readAsDataURL(fileData);
    });
};

const processingFile = (dataFile, supportDocuments, setFieldValue, idField) => {
    if (dataFile) {
        const { file, dataBase64 } = dataFile;
        const newFileLoaded = {
            key: file.name,
            name: file.name,
            size: file.size,
            content: dataBase64.split(",")[1],
        };

        const supportDocumentsCopy = [...supportDocuments];

        const objetoExistenteIndex = supportDocuments?.findIndex((item) => item.key === `${file.name}`);

        if (objetoExistenteIndex !== -1) {
            supportDocumentsCopy[objetoExistenteIndex] = newFileLoaded;
        } else {
            supportDocumentsCopy.push(newFileLoaded);
        }
        setFieldValue(idField, supportDocumentsCopy);
    }
};

const useFileProcessor = () => {
    const processFileAsync = async (file, supportDocuments, setFieldValue, idField, dispatch, notificationActions) => {
        if (!file) {
            return;
        }

        const fileName = `${file.name.split(".")[0]}`;
        const fileExtension = `${file.name.split(".").slice(-1)}`;
        const fileTypes = config.get("support.transfer.file.allowedFileExtensions", "pdf|jpg");
        const fileMaxSize = config.get("support.transfer.file.maxFileSize", 10485760);
        const fileValidRegex = config.get("support.transfer.file.name.pattern", "^[a-zA-Z0-9 ]+$");
        const validRegex = new RegExp(fileValidRegex);

        if (!fileTypes.includes(fileExtension)) {
            dispatch(
                notificationActions.showNotification(
                    i18n.get(`forms.${ID_FORM}.supportFiles.typeError`),
                    "error",
                    ["form", "ListSupportTransferReceived"],
                    true,
                ),
            );
        } else if (file.size >= fileMaxSize) {
            dispatch(
                notificationActions.showNotification(
                    i18n.get(`forms.${ID_FORM}.supportFiles.fileMaxSizeError`),
                    "error",
                    ["form", "ListSupportTransferReceived"],
                    true,
                ),
            );
        } else if (!validRegex.test(fileName)) {
            dispatch(
                notificationActions.showNotification(
                    i18n.get(`forms.${ID_FORM}.supportFiles.fileNameError`),
                    "error",
                    ["form", "ListSupportTransferReceived"],
                    true,
                ),
            );
        } else {
            try {
                const dataBase64 = await readFileAsDataUrl(file);
                processingFile({ file, dataBase64 }, supportDocuments, setFieldValue, idField);
            } catch (error) {
                dispatch(
                    notificationActions.showNotification(
                        i18n.get(`forms.${ID_FORM}.supportFiles.documentError`),
                        "error",
                        ["form", "ListSupportTransferReceived"],
                        true,
                    ),
                );
            }
        }
    };

    return { processFileAsync };
};

export default useFileProcessor;
