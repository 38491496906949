import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Text from "pages/_components/Text";
import { bool, string } from "prop-types";
import React from "react";

const LinkText = ({ isInfoCheck, linkLabel, link, linkColor, hasUnderline }) => (
    <Box
        display="flex"
        fullWidth
        {...(isInfoCheck && {
            position: "absolute",
            top: "100",
        })}>
        {link && (
            <Box display="flex" flex1>
                <Button bsStyle="link" externalHref={link} className="p-0 remove-min-height" primaryLink={hasUnderline}>
                    <Text labelKey={linkLabel} size="7" color={linkColor || "primary-hover-color"} />
                </Button>
            </Box>
        )}
    </Box>
);

LinkText.propTypes = {
    isInfoCheck: bool,
    linkLabel: string,
    link: string,
    linkColor: string,
    hasUnderline: bool,
};

LinkText.defaultProps = {
    isInfoCheck: false,
    linkLabel: null,
    link: null,
    linkColor: null,
    hasUnderline: true,
};

export default LinkText;
