const TRANSACTIONS_KIND = {
    "transfers.internal.send": "transfers",
    "transfers.local.send": "transfers",
    "transfers.foreign.send": "transfers",
    "creditCards.changeStatus.block.send": "block",
    "creditCards.changeStatus.unlock.send": "unlock",
    "creditCard.payment.creditCardLocal.send": "cardPayment",
    "creditCard.payment.creditCardThird.send": "cardPayment",
    "creditCard.payment.financingPlan.creditCardLocal.send": "cardPayment",
    "loan.payment.send": "loanPayment",
    "creditCard.recharge.creditCardLocal.send": "recharge",
    "creditCard.recharge.creditCardThird.send": "recharge",
    "transfers.wally.send": "wally",
    "requestTransfers.wally.send": "requestTransferWally",
    "receivedTransfers.wally.send": "receivedTransferWally",
    "transfers.approve.wally.send": "pendingApproveWally",
    "historic.transaction": "historicTransaction",
    "activate.wally.user": "activateWally",
    "deactivate.wally.user": "deactivateWally",
    "update.wally.user": "updateWally",
    "transfers.payService.send": "servicePayment",
    "fixed.term.deposit.create.send": "fixedTermDeposit",
    "checks.suspend.send": "suspendChecks",
    "product.exchange.points.send": "productExchangePoints",
    "beneficiary.create.send": "beneficiaryCreate",
    "report.replace.card.send": "replaceCard",
    "report.renew.card.send": "renewCard",
    "installment.purchase.send": "installmentPurchase",
    "requests.checkBooks.send": "requestsCheckBooks",
    "transfers.thirdParties.send": "transfers",
    "redeem.miles.cash.send": "redeem",
    "redeem.miles.connect.send": "redeem.miles",
    "redeem.miles.connect.send.lifemiles": "redeem.miles.lifemiles",
    "pay.2x3.send": "pay.2x3",
    "balance.purchase.create.send": "balance.purchase",
    "debitCards.renew.send": "renew.debitCard",
};

export const getTransactionKind = (activityId) => TRANSACTIONS_KIND[activityId] || "transaction";

export default {};
