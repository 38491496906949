import * as supportTransferReceivedApi from "middleware/supportTransferReceived";
import { call, put, takeLatest } from "redux-saga/effects";
import { types as transferSupportTypes } from "reducers/supportTransferReceived";
import { actions as notificationActions } from "reducers/notification";
import * as i18n from "util/i18n";

const sagas = [
    takeLatest(transferSupportTypes.TRANSFER_RECEIVED_LIST_REQUEST, getTransferList),
    takeLatest(transferSupportTypes.SEND_UPLOAD_SUPPORT_TRANSFER_REQUEST, sendUploadSupportRequest),
];

function* showGenericErrorTransferList() {
    yield put({ type: transferSupportTypes.TRANSFER_RECEIVED_LIST_FAILURE });
    yield put(
        notificationActions.showNotification(
            i18n.get("forms.support.transfer.foreign.received.list.error"),
            "error",
            ["ListSupportTransferReceived"],
            false,
        ),
    );
}

function* getTransferList({ idAccount }) {
    const responseApi = yield call(supportTransferReceivedApi.getTransferList, idAccount);
    if (!responseApi?.type || !responseApi?.data?.data) {
        yield call(showGenericErrorTransferList);
        yield;
        return;
    }

    const { type, data } = responseApi;
    if (type === "W") {
        yield call(showGenericErrorTransferList);
        yield;
        return;
    }

    const { transferList = [] } = data.data;

    yield put({ type: transferSupportTypes.TRANSFER_RECEIVED_LIST_SUCCESS, transferList: transferList });
}

function* sendUploadSupportRequest({
    idAccount,
    transferFT,
    supportFiles,
    reason,
    relation,
}) {
    const responseApi = yield call(
        supportTransferReceivedApi.sendUploadSupportRequest,
        idAccount,
        transferFT,
        supportFiles,
        reason,
        relation,
    );

    if (responseApi && responseApi.status === 200 && responseApi.data.code === "COR000I") {
        yield put({ type: transferSupportTypes.SEND_UPLOAD_SUPPORT_TRANSFER_REQUEST_SUCCESS, transferFT: transferFT });
        yield put(
            notificationActions.showNotification(
                `${i18n.get("forms.support.transfer.foreign.received.send.success")} `,
                "success",
                ["ListSupportTransferReceived"],
                false,
            ),
        );
    }else{
        yield put({ type: transferSupportTypes.SEND_UPLOAD_SUPPORT_TRANSFER_REQUEST_FAILURE });
        yield put(
            notificationActions.showNotification(
                `${i18n.get("forms.support.transfer.foreign.received.send.error")} `,
                "error",
                ["ListSupportTransferReceived"],
                false,
            ),
        );
    }
    
}

export default sagas;
