import { createReducer, makeActionCreator } from "util/redux";

export const types = {
    PREAPPROVED_CREDIT_SEND_REQUEST: "preApprovedProduct/PREAPPROVED_CREDIT_SEND_REQUEST",
    PREAPPROVED_CREDIT_SEND_REQUEST_SUCCESS: "preApprovedProduct/PREAPPROVED_CREDIT_SEND_REQUEST_SUCCESS",
    PREAPPROVED_CREDIT_SEND_REQUEST_FAILED: "preApprovedProduct/PREAPPROVED_CREDIT_SEND_REQUEST_FAILED",
    PREAPPROVED_CREDIT_DECLINED: "preApprovedProduct/PREAPPROVED_CREDIT_DECLINED",
    PREAPPROVED_CREDIT_DECLINED_SUCCESS: "preApprovedProduct/PREAPPROVED_CREDIT_DECLINED_SUCCESS",
    PREAPPROVED_CREDIT_DECLINED_FAILED: "preApprovedProduct/PREAPPROVED_CREDIT_DECLINED_FAILED",

    MODAL_SHOW_CREDIT_POST: "preApprovedProduct/MODAL_SHOW_CREDIT_POST",
    MODAL_CLOSE_CREDIT_POST: "preApprovedProduct/MODAL_CLOSE_CREDIT_POST",
    GET_PREAPPROVED_CREDIT_POS_REQUEST: "preApprovedProduct/GET_PREAPPROVED_CREDIT_POST_REQUEST",
    GET_PREAPPROVED_CREDIT_POS_REQUEST_SUCCESS: "preApprovedProduct/GET_PREAPPROVED_CREDIT_POS_REQUEST_SUCCESS",
    GET_PREAPPROVED_CREDIT_POS_REQUEST_FAILED: "preApprovedProduct/GET_PREAPPROVED_CREDIT_POS_REQUEST_FAILED",
};

export const INITIAL_STATE = {
    preApprovedProductsList: null,
    salaryRangeList: null,
    creditCardIssuerList: null,
    isFetching: false,
    showModalCreditPOS: false,
    preApprovedCreditBanner: null,
    preApprovedCreditUser: null,
};

export default createReducer(INITIAL_STATE, {
    [types.PREAPPROVED_CREDIT_SEND_REQUEST]: (state) => ({
        ...state,
        isFetching: true,
    }),
    [types.PREAPPROVED_CREDIT_SEND_REQUEST_SUCCESS]: (state) => ({
        ...state,
        isFetching: false,
        showModalCreditPOS: false,
    }),
    [types.PREAPPROVED_CREDIT_SEND_REQUEST_FAILED]: (state) => ({
        ...state,
        isFetching: false,
        showModalCreditPOS: false,
    }),
    [types.MODAL_SHOW]: (state) => ({
        ...state,
        showModalCreditPOS: true,
    }),
    [types.MODAL_CLOSE]: (state) => ({
        ...state,
        showModalCreditPOS: false,
        isFetching: false,
    }),
    [types.MODAL_SHOW_CREDIT_POST]: (state) => ({
        ...state,
        showModalCreditPOS: true,
    }),
    [types.MODAL_CLOSE_CREDIT_POST]: (state) => ({
        ...state,
        showModalCreditPOS: false,
        isFetching: false,
    }),
    [types.PREAPPROVED_CREDIT_DECLINED]: (state) => ({
        ...state,
        isFetching: true,
    }),
    [types.PREAPPROVED_CREDIT_DECLINED_SUCCESS]: (state) => ({
        ...state,
        showModalCreditPOS: false,
        isFetching: false,
    }),
    [types.PREAPPROVED_CREDIT_DECLINED_FAILED]: (state) => ({
        ...state,
        showModalCreditPOS: false,
        isFetching: false,
    }),
    [types.GET_PREAPPROVED_CREDIT_POS_REQUEST]: (state) => ({
        ...state,
        isFetching: true,
        preApprovedCreditBanner: null,
        preApprovedCreditUser: null,
    }),
    [types.GET_PREAPPROVED_CREDIT_POS_REQUEST_SUCCESS]: (state, action) => ({
        ...state,
        isFetching: false,
        preApprovedCreditBanner: action.preApprovedCreditBanner,
        preApprovedCreditUser: action.preApprovedCreditUser,
    }),
    [types.GET_PREAPPROVED_CREDIT_POS_REQUEST_FAILED]: (state) => ({
        ...state,
        isFetching: false,
        preApprovedCreditBanner: null,
        preApprovedCreditUser: null,
    }),
});

export const actions = {
    preApprovedProductSendRequest: makeActionCreator(
        types.PREAPPROVED_CREDIT_SEND_REQUEST,
        "preApprovedCreditUsersId",
        "preApprovedCreditUserStatus",
    ),
    modalShowCreditPOS: makeActionCreator(types.MODAL_SHOW_CREDIT_POST),
    modalHideCreditPOS: makeActionCreator(types.MODAL_CLOSE_CREDIT_POST),
    getApprovedCreditPosRequest: makeActionCreator(types.GET_PREAPPROVED_CREDIT_POS_REQUEST, "preApprovedCreditUserId"),
    getApprovedCreditPosRequestSuccess: makeActionCreator(
        types.GET_PREAPPROVED_CREDIT_POS_REQUEST_SUCCESS,
        "preApprovedCreditBanner",
        "preApprovedCreditUser",
    ),
    getApprovedCreditPosRequestFailed: makeActionCreator(types.GET_PREAPPROVED_CREDIT_POS_REQUEST_FAILED),
};

export const selectors = {
    isFetching: ({ preApprovedCredit }) => preApprovedCredit.isFetching,
    showModalCreditPOS: ({ preApprovedCredit }) => preApprovedCredit.showModalCreditPOS,
    getPreApprovedCreditBanner: ({ preApprovedCredit }) => preApprovedCredit.preApprovedCreditBanner,
    getPreApprovedCreditUser: ({ preApprovedCredit }) => preApprovedCredit.preApprovedCreditUser,
};
