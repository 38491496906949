import { call, put, takeLatest } from "redux-saga/effects";
import { types, actions as balancePurchaseActions } from "reducers/balancePurchase";
import * as balancePurchase from "middleware/creditCard";
import { formatBalancePurchasePlans } from "util/creditCards";
import { adjustIdFieldErrors } from "util/form";

const sagas = [takeLatest(types.GET_AVAILABLE_PLANS_REQUEST, getAvailablePlans)];

export default sagas;

function* getAvailablePlans({ productId, amount, formikBag }) {
    const response = yield call(balancePurchase.getBalancePurchaseAvailablePlans, productId, amount);

    if (response.type === "W") {
        formikBag.setErrors(adjustIdFieldErrors(response.data.data));
        yield put(balancePurchaseActions.getAvailablePlansFailure());
        formikBag.setSubmitting(false);
        return;
    }

    const _amount = { ...amount, quantity: Number(amount.quantity) };
    const planList = formatBalancePurchasePlans(response.data.data, _amount);

    yield put(balancePurchaseActions.getAvailablePlansSuccess(planList));
}
