import * as API from "middleware/api";

export const preApprovedProductsSendRequest = (preApprovedCreditUserId, preApprovedCreditUserStatus) =>
    API.executeWithAccessToken("/pre.approved.credit.pos.send", {
        preApprovedCreditUserId,
        preApprovedCreditUserStatus,
    });

export const getPreApprovedCreditPOS = (preApprovedCreditUserId, preApprovedCreditUserStatus) =>
    API.executeWithAccessToken("/pre.approved.credit.pos.pre", {
        preApprovedCreditUserId,
        preApprovedCreditUserStatus,
    });
