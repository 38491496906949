import * as i18n from "util/i18n";

const creditCardBrandsValidations = {
    amex: /^3[47]/,
    visa: /^4/,
    mastercard: /^5[1-7]/,
};

export const detectBrand = (number) =>
    Object.keys(creditCardBrandsValidations).find((key) => creditCardBrandsValidations[key].test(number));

export const format = (number) => number.match(/.{4}/g).join(" ");

export const maskCardNumber = (number) => {
    const brand = detectBrand(number);
    const lastFourDigits = number.slice(-4);
    return `${i18n.get(`${brand}.label`)} **** ${lastFourDigits}`;
};

export const CREDIT_CARD_RELATION_TYPE_MAIN = "Y";
export const CREDIT_CARD_RELATION_TYPE_ADDITIONAL = "N";

export const CREDIT_CARD_STATUS = {
    ACTIVA: "00",
};

export const CREDIT_CARD_TYPE_BAND = "band";
export const CREDIT_CARD_TYPE_BAND_TITLE = "creditcard.type.band.label";
export const CREDIT_CARD_TYPE_CARD = "card";
export const CREDIT_CARD_TYPE_CARD_TITLE = "creditcard.type.card.label";
export const CREDIT_CARD_TYPE_STICKER = "sticker";
export const CREDIT_CARD_TYPE_STICKER_TITLE = "creditcard.type.sticker.label";

export const MAP_CREDIT_CARD_TYPE = new Map([
    [CREDIT_CARD_TYPE_BAND, { icon: CREDIT_CARD_TYPE_BAND, title: CREDIT_CARD_TYPE_BAND_TITLE }],
    [CREDIT_CARD_TYPE_CARD, { icon: CREDIT_CARD_TYPE_CARD, title: CREDIT_CARD_TYPE_CARD_TITLE }],
    [CREDIT_CARD_TYPE_STICKER, { icon: CREDIT_CARD_TYPE_STICKER, title: CREDIT_CARD_TYPE_STICKER_TITLE }],
]);

export const generatePinAssigmentData = ({
    idProduct,
    numberMask,
    shortLabel,
    franchise,
    expirationDate,
    submitAction,
}) => {
    const cardBrand = franchise ? `${franchise.charAt(0).toUpperCase()}${franchise.slice(1).toLowerCase()}` : "";
    return {
        productType: "creditCards",
        idActivity: "creditCards.assignPin.send",
        productData: {
            idProduct,
            numberMask,
            shortLabel,
            franchise,
            parametersSubmit: { idCreditCard: idProduct, expirationDate },
        },
        title: `creditCards.drawer.pinAssignment.title`,
        isPinAssigment: true,
        paramsNotification: {
            success: {
                CARD_NUMBER: shortLabel,
                CARD_BRAND: cardBrand,
            },
            error: { CARD_NUMBER: shortLabel },
        },
        submitAction,
    };
};

export const getNonPrepaidCreditCardsWithAdditionals = (creditCards) => {
    // fitro las prepagas
    let allCreditCards = [];
    if (creditCards) {
        const nonPrepaid = creditCards.filter((creditCard) => !creditCard.isPrepaid);

        // aplano listado de tarjetas a tarjetas y sus adicionales
        allCreditCards = nonPrepaid.reduce((acc, creditCard) => {
            acc.push(creditCard);
            if (creditCard.additionalCreditCardList != null) {
                // eslint-disable-next-line no-param-reassign
                acc = [...acc, ...creditCard.additionalCreditCardList];
            }
            return acc;
        }, []);
    }

    return allCreditCards;
};

export const getCreditCardsWithAdditionals = (creditCards) => {
    // fitro las prepagas
    let allCreditCards = [];
    if (creditCards) {
        // aplano listado de tarjetas a tarjetas y sus adicionales
        allCreditCards = creditCards.reduce((acc, creditCard) => {
            acc.push(creditCard);
            if (creditCard.additionalCreditCardList != null) {
                // eslint-disable-next-line no-param-reassign
                acc = [...acc, ...creditCard.additionalCreditCardList];
            }
            return acc;
        }, []);
    }

    return allCreditCards;
};

export const getPrepaidCreditCards = (creditCards) => {
    // fitro las prepagas
    let allCreditCards = [];
    if (creditCards) {
        allCreditCards = creditCards.filter((creditCard) => creditCard.isPrepaid);
    }

    return allCreditCards;
};

export const formatCreditCardNumber = (creditCardNumber) => {
    if (!creditCardNumber) {
        return creditCardNumber;
    }

    const trimCreditCardNumber = creditCardNumber.replace(/\s/g, "");
    let newCreditCardNumber = "";

    if (trimCreditCardNumber.charAt(0) === "3") {
        if (trimCreditCardNumber.length > 10) {
            newCreditCardNumber = `${trimCreditCardNumber.substring(0, 4)} ${trimCreditCardNumber.substring(
                4,
                10,
            )} ${trimCreditCardNumber.substring(10)}`;
        } else if (trimCreditCardNumber.length > 4) {
            newCreditCardNumber = `${trimCreditCardNumber.substring(0, 4)} ${trimCreditCardNumber.substring(4)}`;
        } else {
            newCreditCardNumber = trimCreditCardNumber;
        }
    } else if (trimCreditCardNumber.length > 12) {
        newCreditCardNumber = `${trimCreditCardNumber.substring(0, 4)} ${trimCreditCardNumber.substring(
            4,
            8,
        )} ${trimCreditCardNumber.substring(8, 12)} ${trimCreditCardNumber.substring(12)}`;
    } else if (trimCreditCardNumber.length > 8) {
        newCreditCardNumber = `${trimCreditCardNumber.substring(0, 4)} ${trimCreditCardNumber.substring(
            4,
            8,
        )} ${trimCreditCardNumber.substring(8)}`;
    } else if (trimCreditCardNumber.length > 4) {
        newCreditCardNumber = `${trimCreditCardNumber.substring(0, 4)} ${trimCreditCardNumber.substring(4)}`;
    } else {
        newCreditCardNumber = trimCreditCardNumber;
    }

    return newCreditCardNumber;
};

const calculateMonthlyPayment = (startingValue, interest, totalPayments) => {
    if (interest === 0) {
        return Math.round((startingValue / totalPayments) * 100) / 100;
    }

    const rate = interest / 12;
    const total = (startingValue * rate) / (1 - (1 + rate) ** -totalPayments);
    return Math.round(total * 100) / 100;
};

export const formatBalancePurchasePlans = (data, amount) =>
    data.financialPlans.map((plan, index) => {
        const interest = plan.percentage / 100;

        return {
            ...plan,
            totalCommission: { quantity: plan?.commission.quantity + plan?.itbms.quantity, currency: amount.currency },
            id: `#${index + 1}`,
            title: `${i18n.get("creditCard.financing.plans.balance.purchase.plan")}`,
            description: i18n.get(
                `creditCard.financing.plans.balance.purchase.promotion.${plan.promotion.toLowerCase()}`,
            ),
            rate: `${interest}% ${i18n.get("creditCard.financing.plans.balance.purchase.plan.montly")}`,
            montlyPayment: {
                currency: amount.currency,
                amount: calculateMonthlyPayment(amount.quantity, interest, plan.term),
            },
            amount,
            ratePercentage: interest,
        };
    });
