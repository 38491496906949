import classNames from "classnames";
import { SALARY_PAYMENT_ID_FORM } from "constants.js";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Container from "pages/_components/Container";
import GridTable from "pages/_components/GridTable/GridTable";
import Text from "pages/_components/Text";
import Row from "pages/_components/Row";
import BanescoCheckbox from "pages/_components/fields/BanescoCheckbox";
import TransactionItem from "pages/transactions/_components/TransactionItem";
import { push } from "react-router-redux";
import { arrayOf, bool, func, instanceOf, number, shape, string } from "prop-types";
import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { actions as notificationActions } from "reducers/notification";
import { actions as transactionsActions, selectors as transactionsSelectors } from "reducers/transactions";
import * as i18nUtils from "util/i18n";
import { selectors as sessionSelectors } from "reducers/session";

class List extends Component {
    static propTypes = {
        defaultFilters: shape({
            dateFrom: instanceOf(Date),
            dateTo: instanceOf(Date),
            pageNumber: number,
            filter: string,
        }),
        dispatch: func.isRequired,
        pageNumber: number,
        onlyPendings: bool,
        onlyProcessing: bool,
        pendingDispatch: bool,
        filters: shape({
            idFilter: string,
            dateFrom: instanceOf(Date),
            dateTo: instanceOf(Date),
            pageNumber: number,
            minAmount: number,
            maxAmount: number,
        }),
        transactions: arrayOf(shape({})),
        isDesktop: bool.isRequired,
        isTablet: bool.isRequired,
        hasMoreData: bool.isRequired,
        fetching: bool.isRequired,
        showEnvironment: bool,
        itemsAreClickeable: bool,
        ascending: bool.isRequired,
        handleOrder: func.isRequired,
        history: shape({
            push: func.isRequired,
        }).isRequired,
        isPending: bool,
        transactionsToProcess: arrayOf(shape({})),
        isAdministrator: bool,
    };

    static defaultProps = {
        transactions: null,
        defaultFilters: null,
        pageNumber: 1,
        onlyPendings: false,
        onlyProcessing: false,
        pendingDispatch: false,
        filters: null,
        showEnvironment: false,
        itemsAreClickeable: true,
        isPending: false,
        transactionsToProcess: [],
        isAdministrator: false,
    };

    state = {
        allDeleteCheckBox: false,
    };

    handleAllCheckboxChange = (isChecked) => {
        this.setState({ allDeleteCheckBox: isChecked });
        let updatedTransactions;
        const { transactions, dispatch } = this.props;

        if (isChecked) {
            updatedTransactions = transactions.map((transaction) => ({
                ...transaction.transaction,
            }));
        } else {
            updatedTransactions = [];
        }
        dispatch(transactionsActions.saveTransactionsToProcess(updatedTransactions));
    };

    fetchMoreTransactions = () => {
        const {
            defaultFilters,
            dispatch,
            pageNumber,
            onlyPendings,
            onlyProcessing,
            pendingDispatch,
            ascending,
        } = this.props;
        let { filters } = this.props;

        filters = filters
            ? { ...filters, pageNumber, isAscending: ascending, isSorting: false }
            : { ...defaultFilters, pageNumber };

        dispatch(
            transactionsActions.loadMoreTransactionsRequest(filters, onlyPendings, onlyProcessing, pendingDispatch),
        );
    };

    onMobileItemCLick = (event, transaction) => {
        const { dispatch, isDesktop, history } = this.props;
        history.push(`/transaction/${transaction.transaction.idTransaction}`);

        if (
            transaction.transaction.idForm === SALARY_PAYMENT_ID_FORM &&
            !isDesktop &&
            transaction.transaction.idTransactionStatus === "DRAFT"
        ) {
            event.preventDefault();
            dispatch(
                notificationActions.showNotification(i18nUtils.get("massive.payments.mobile.disable"), "error", [
                    "transactionList",
                ]),
            );
        }
    };

    handleOrderList = () => {
        const {
            defaultFilters,
            dispatch,
            pageNumber,
            onlyPendings,
            onlyProcessing,
            pendingDispatch,
            ascending,
            handleOrder,
        } = this.props;
        let { filters } = this.props;

        filters = filters
            ? { ...filters, pageNumber, isAscending: !ascending, isSorting: true }
            : { ...defaultFilters, pageNumber };

        dispatch(transactionsActions.loadListRequest(filters, onlyPendings, onlyProcessing, pendingDispatch));

        handleOrder();
    };

    handleSignature = () => {
        const { dispatch } = this.props;
        dispatch(transactionsActions.setIsDeleteMode(false));
        return dispatch(push("/pendingTransaction/list/multipleSignatureConfirmation"));
    };

    handleDelete = () => {
        const { dispatch } = this.props;
        dispatch(transactionsActions.setIsDeleteMode(true));
        return dispatch(push("/pendingTransaction/list/multipleSignatureDelete"));
    };

    render() {
        const {
            transactions,
            hasMoreData,
            isDesktop,
            isTablet,
            fetching,
            showEnvironment,
            itemsAreClickeable,
            ascending,
            onlyPendings,
            onlyProcessing,
            isPending,
            transactionsToProcess,
            isAdministrator,
        } = this.props;

        const isPendingAndNotFetching = isPending && !fetching;

        const { allDeleteCheckBox } = this.state;

        // eslint-disable-next-line no-nested-ternary
        const columnsTemplate = isDesktop
            ? `5rem ${isTablet ? "repeat(5, 1fr) 4rem" : "1fr 1fr 1fr 1fr 10.5rem minmax(1.5rem, 2rem)"}`
            : isPendingAndNotFetching
            ? "1.5rem 1fr auto 1.5rem"
            : "1fr auto 1.5rem";

        const rowsTemplate = isDesktop ? "1fr" : "auto 1fr auto";
        if (!transactions) {
            return null;
        }

        const onClickHandler = (transaction) => {
            if (isDesktop) {
                return () => this.props.history.push(`/transaction/${transaction.transaction.idTransaction}`);
            }
            return (e) => this.onMobileItemCLick(e, transaction);
        };

        const list = transactions.map((transaction, idx) => (
            <GridTable.Container
                key={transaction.transaction.idTransaction}
                className="product-data"
                padding="py-2 pl-5 pl-md-3 pr-5"
                columnsTemplate={columnsTemplate}
                rowsTemplate={rowsTemplate}
                {...(itemsAreClickeable && { onClick: onClickHandler(transaction) })}>
                <TransactionItem
                    key={`transaction-${transaction.transaction.idTransaction}`}
                    transaction={transaction}
                    isDesktop={isDesktop}
                    showEnvironment={showEnvironment}
                    idx={idx}
                    allDeleteCheckBox={allDeleteCheckBox}
                    isPending={isPendingAndNotFetching}
                />
            </GridTable.Container>
        ));

        return (
            <Container className="container--layout flex-grow-1 no-scrollable">
                <Col className="col-12 ">
                    <div
                        ref={(tableRef) => {
                            this.child = tableRef;
                        }}>
                        {transactions.length ? (
                            <GridTable>
                                <GridTable.Header>
                                    <GridTable.Container
                                        columnsTemplate={columnsTemplate}
                                        padding="py-2 pl-5 pl-md-3 pr-5">
                                        {!isDesktop && isPendingAndNotFetching && (
                                            <GridTable.Data
                                                columnStart={1}
                                                columnWidth={1}
                                                alignX="center"
                                                alignY="center"
                                                overflow="hidden"
                                                inHeader>
                                                <BanescoCheckbox
                                                    name="AllCheckbox"
                                                    defaultChecked={allDeleteCheckBox}
                                                    onChange={(e) => {
                                                        this.handleAllCheckboxChange(e.target.checked);
                                                    }}
                                                    hideLabel
                                                    className="m-0"
                                                    disabled={!isAdministrator}
                                                />
                                            </GridTable.Data>
                                        )}
                                        {isDesktop && (
                                            <GridTable.Data
                                                columnStart={1}
                                                columnWidth={1}
                                                alignX={isPendingAndNotFetching ? "flex-start" : "center"}
                                                alignY="center"
                                                overflow="hidden"
                                                inHeader>
                                                {isPendingAndNotFetching && (
                                                    <BanescoCheckbox
                                                        name="AllCheckbox"
                                                        defaultChecked={allDeleteCheckBox}
                                                        onChange={(e) => {
                                                            this.handleAllCheckboxChange(e.target.checked);
                                                        }}
                                                        hideLabel
                                                        className="m-0"
                                                        disabled={!isAdministrator}
                                                    />
                                                )}

                                                <Text
                                                    labelKey="transactions.list.header.channel"
                                                    {...(isPendingAndNotFetching && { className: "pl-2" })}
                                                />
                                            </GridTable.Data>
                                        )}
                                        <GridTable.Data
                                            // eslint-disable-next-line no-nested-ternary
                                            columnStart={isDesktop ? 2 : isPendingAndNotFetching ? 2 : 1}
                                            columnWidth={1}
                                            alignX="flex-start"
                                            alignY="center"
                                            inHeader>
                                            <Text labelKey="transactions.list.header.operationType" />
                                        </GridTable.Data>
                                        {showEnvironment && (
                                            <GridTable.Data
                                                columnStart={isPendingAndNotFetching ? 4 : 3}
                                                columnWidth={1}
                                                alignX="flex-end"
                                                inHeader>
                                                <Text labelKey="transactions.list.header.client" />
                                            </GridTable.Data>
                                        )}
                                        {isDesktop && (
                                            <>
                                                <GridTable.Data
                                                    columnStart={showEnvironment ? 4 : 3}
                                                    columnWidth={1}
                                                    alignX="center"
                                                    inHeader>
                                                    <Text labelKey="transactions.list.header.creationDate" />
                                                    <Button
                                                        imageXs
                                                        black
                                                        className={classNames("px-3", {
                                                            "rotate-full": ascending,
                                                        })}
                                                        onClick={this.handleOrderList}
                                                        image="images/arrow-down.svg"
                                                    />
                                                </GridTable.Data>
                                                <GridTable.Data
                                                    columnStart={showEnvironment ? 5 : 4}
                                                    columnWidth={1}
                                                    alignX="center"
                                                    inHeader>
                                                    <Text labelKey="transactions.list.header.receiver" />
                                                </GridTable.Data>

                                                <GridTable.Data
                                                    columnStart={showEnvironment ? 6 : 5}
                                                    columnWidth={1}
                                                    alignX="flex-end"
                                                    inHeader>
                                                    <Text labelKey="transactions.list.header.amount" />
                                                </GridTable.Data>
                                                <GridTable.Data
                                                    columnStart={showEnvironment ? 7 : 6}
                                                    columnWidth={1}
                                                    alignX="center"
                                                    inHeader>
                                                    <Text labelKey="transactions.list.header.status" />
                                                </GridTable.Data>
                                            </>
                                        )}
                                        {!isDesktop && (
                                            <GridTable.Data
                                                columnStart={isPendingAndNotFetching ? 3 : 2}
                                                columnWidth={1}
                                                alignX="center"
                                                inHeader>
                                                <Text labelKey="transactions.list.header.amountStatus" />
                                            </GridTable.Data>
                                        )}
                                    </GridTable.Container>
                                </GridTable.Header>

                                <GridTable.Body>
                                    {list}
                                    {hasMoreData && (
                                        <Box display="flex" fullWidth alignX="center" className="no-more-data">
                                            <Button
                                                bsStyle="link"
                                                onClick={this.fetchMoreTransactions}
                                                image="images/arrow-down.svg"
                                                label="transactions.list.filters.list.moreData"
                                                imageRight
                                                loading={fetching}
                                                className="btn-small"
                                            />
                                        </Box>
                                    )}
                                    {isPendingAndNotFetching && (
                                        <Box
                                            display="flex"
                                            background="component-background"
                                            borderRadius="bottom-default"
                                            className="pt-8 pb-5 px-5 px-md-11">
                                            <Row gapY="3" gapX="5">
                                                <Col xs={12} md={3} mdOffset={6}>
                                                    <Button
                                                        label="global.delete"
                                                        bsStyle="outline"
                                                        onClick={this.handleDelete}
                                                        block
                                                        disabled={transactionsToProcess.length === 0}
                                                    />
                                                </Col>
                                                <Col
                                                    xs={12}
                                                    md={3}
                                                    className={classNames({
                                                        "grid-reversed": !isDesktop,
                                                    })}>
                                                    <Button
                                                        label="global.signature"
                                                        bsStyle="primary"
                                                        onClick={this.handleSignature}
                                                        block
                                                        disabled={transactionsToProcess.length === 0}
                                                    />
                                                </Col>
                                            </Row>
                                        </Box>
                                    )}
                                </GridTable.Body>
                            </GridTable>
                        ) : (
                            <Box display="flex" fullWidth alignX="center" className="no-more-data min-height-8rem">
                                <Text
                                    labelKey={
                                        onlyPendings || onlyProcessing
                                            ? "pending.transactions.list.none"
                                            : "transactions.list.none"
                                    }
                                    component="p"
                                    align="center"
                                    bold
                                    size="6"
                                    color="text"
                                />
                            </Box>
                        )}
                    </div>
                </Col>
            </Container>
        );
    }
}

const mapStateToProps = (state) => ({
    transactions: transactionsSelectors.getTransactions(state),
    hasMoreData: transactionsSelectors.getHasMoreData(state),
    fetching: transactionsSelectors.getFetching(state),
    pageNumber: transactionsSelectors.getPageNumber(state),
    filters: transactionsSelectors.getFilters(state),
    transactionsToProcess: transactionsSelectors.getTransactionsToProcess(state),
    isAdministrator: sessionSelectors.isAdministrator(state),
});

export default withRouter(connect(mapStateToProps)(List));
