import filesize from "filesize";
import Box from "pages/_components/Box";
import Image from "pages/_components/Image";
import Text from "pages/_components/Text";
import { bool, shape, string } from "prop-types";
import React from "react";

const AttachedFile = ({ file, isDesktop, withEllipsis, wrapperWidth, wrapperHeight, ellipsis, breakWord }) => (
    <Box display="flex" alignY="center" fullWidth withEllipsis={withEllipsis}>
        <Image
            src="images/icons/attachment.svg"
            color="text-disabled-color"
            wrapperWidth={wrapperWidth}
            wrapperHeight={wrapperHeight}
            width="7"
            height="7"
            {...(!isDesktop && { width: "5", height: "5" })}
        />
        <Text
            color="text"
            size="6"
            ellipsis={ellipsis}
            {...(ellipsis && { className: "max-width-5rem" })}
            breakWord={breakWord}>
            {file?.name}
        </Text>

        <Text color="text" size="6" className="ml-2">
            ({filesize(file?.size)})
        </Text>
    </Box>
);

AttachedFile.propTypes = {
    file: shape({}),
    isDesktop: bool.isRequired,
    withEllipsis: bool,
    ellipsis: bool,
    wrapperWidth: string,
    wrapperHeight: string,
    breakWord: bool,
};

AttachedFile.defaultProps = {
    file: null,
    withEllipsis: true,
    ellipsis: true,
    wrapperWidth: "7",
    wrapperHeight: "7",
    breakWord: false,
};

export default AttachedFile;
